import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { composizioniStruct } from 'src/app/strutture/composizioni.strutture';
import { TranslateService } from '@ngx-translate/core';
import { ComposizioniService } from 'src/app/servizi/composizioni.service';

@Component({
    selector: 'app-nuova-testata-composizioni',
    templateUrl: './nuova-testata-composizioni.component.html',
    styleUrls: ['./nuova-testata-composizioni.component.scss'],
})
export class NuovaTestataComposizioniComponent implements OnInit {
    fg: FormGroup;
    modalDataResponse: any;

    testata: composizioniStruct;

    title: string = '';

    constructor(
        public translate: TranslateService,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private router: Router,
        private composizioniService: ComposizioniService
    ) {}

    ngOnInit() {
        console.log('testata', this.testata ? this.testata : 'nuova');
        if (this.testata) {
            this.title = 'Modifica Testata';
            this.initFormEdit();
        } else {
            this.title = 'Nuova Testata';
            this.initForm();
        }
    }

    initForm() {
        this.fg = this.formBuilder.group({
            cod_compo: ['', Validators.required],
            des: ['', Validators.required],
            diml: ['', Validators.required],
            dima: ['', Validators.required],
            dimp: ['', Validators.required],
            d1: [''],
            d2: [''],
            d3: [''],
            d4: [''],
            d5: [''],
        });
    }

    initFormEdit() {
        this.fg = this.formBuilder.group({
            cod_compo: [this.testata.COD_COMPO, Validators.required],
            des: [this.testata.DES, Validators.required],
            diml: [this.testata.DIML, Validators.required],
            dima: [this.testata.DIMA, Validators.required],
            dimp: [this.testata.DIMP, Validators.required],
            d1: [this.testata.DATA1],
            d2: [this.testata.DATA2],
            d3: [this.testata.DATA3],
            d4: [this.testata.DATA4],
            d5: [this.testata.DATA5],
        });
    }

    async close(message = 'Modal Closed') {
        await this.modalCtrl.dismiss(message);
    }

    async save() {
        if (this.testata) {
            this.editT();
        } else {
            this.doNuovaT();
        }
    }

    async editT() {
        const testata = new composizioniStruct(
            this.fg.get('cod_compo').value,
            this.fg.get('des').value,
            this.fg.get('diml').value,
            this.fg.get('dima').value,
            this.fg.get('dimp').value,
            1,
            this.fg.get('d1').value,
            this.fg.get('d2').value,
            this.fg.get('d3').value,
            this.fg.get('d4').value,
            this.fg.get('d5').value
        );
        console.log('testata da passare', testata);
        await this.composizioniService.nuovaT(testata).then(() => {
            this.modalCtrl.dismiss();
        });
    }

    async doNuovaT() {
        const testata = new composizioniStruct(
            this.fg.get('cod_compo').value,
            this.fg.get('des').value,
            this.fg.get('diml').value,
            this.fg.get('dima').value,
            this.fg.get('dimp').value,
            1,
            this.fg.get('d1').value,
            this.fg.get('d2').value,
            this.fg.get('d3').value,
            this.fg.get('d4').value,
            this.fg.get('d5').value
        );

        // Salvo la testata e invio alle righe
        console.log('testata da passare', testata);
        this.router.navigate(['composizioni-righe'], {
            state: {
                codice_testata: this.fg.get('cod_compo')?.value,
                testata: testata,
            },
        });
        this.modalCtrl.dismiss();
    }
}
