import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';

@Component({
    selector: 'app-select-opz-scope',
    templateUrl: './select-opz-scope.component.html',
    styleUrls: ['./select-opz-scope.component.scss'],
})
export class SelectOpzScopeComponent implements OnInit, ViewWillEnter {
    fg: FormGroup;

    activeField: string = '';

    colonne: string[] = [];

    opzscope: string;
    opzcontext0: string;
    opzcontext1: string;

    constructor(
        public modalCtrl: ModalController,
        private fb: FormBuilder,
        private ps: PreferenzeService
    ) {}

    async ionViewWillEnter() {
        if (this.opzscope != '' && this.opzscope != undefined) {
            await this.getActiveField(this.opzscope);
        } else {
            console.log('valore non arrivato');
        }

        console.log('*** - opzscope', this.opzscope);
        console.log('*** - opzcontext0', this.opzcontext0);
        console.log('*** - opzcontext1', this.opzcontext1);
    }

    ngOnInit() {
        this.fg = this.fb.group({
            opzscope: [this.opzscope ?? ''],
            opzcontext0: [this.opzcontext0 ?? ''],
            opzcontext1: [this.opzcontext1 ?? ''],
        });
    }

    async getActiveField(selection: string) {
        switch (selection) {
            case 'N':
                this.activeField = 'N';
                break;
            case 'D':
                this.activeField = 'D';
                break;
            case 'M':
                this.activeField = 'M';
                break;
            case 'C':
                await this.getColonne().then(() => {
                    this.fg.patchValue({
                        opzcontext0: 'SELF',
                    });
                    this.activeField = 'C';
                });
                break;
            case 'L':
                this.activeField = 'L';
                break;
        }
    }

    async getSelect(e: any) {
        console.log('event', e.detail.value);
        let selection = e.detail.value;
        await this.getActiveField(selection);
    }

    async getColonne() {
        let response = await this.ps.getPreferenzaCatalogo('COLONNE_PRICELIST');
        this.colonne = response.VALORE_PREFERENZA.split(',');
    }

    salva() {
        const opzscope = this.fg.get('opzscope').value;
        const opzcontext0 = this.fg.get('opzcontext0').value;
        const opzcontext1 = this.fg.get('opzcontext1').value;

        //? Switch sui campi

        switch (this.activeField) {
            case 'N':
                // Assegna il valore a opzcontext
                this.fg.patchValue({
                    opzcontext: '',
                });
                this.modalCtrl.dismiss({ opzscope, opzcontext: '' });
                break;
            case 'D':
                break;
            case 'M':
                // Assegna il valore a opzcontext
                this.fg.patchValue({
                    opzcontext: `SELF|${opzcontext1}`,
                });
                this.modalCtrl.dismiss({
                    opzscope,
                    opzcontext: `SELF|${opzcontext1}`,
                });
                break;
            case 'C':
                // Assegna il valore combinato a opzcontext
                this.fg.patchValue({
                    opzcontext: `${opzcontext0}|${opzcontext1}`,
                });

                console.log(this.fg.value);
                let opzcontext = opzcontext0 + '|' + opzcontext1;

                this.modalCtrl.dismiss({ opzscope, opzcontext });
                break;
            case 'L':
                break;
        }
    }
}
