import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';

@Component({
    selector: 'app-nuovo-artneutriplus',
    templateUrl: './nuovo-artneutriplus.component.html',
    styleUrls: ['./nuovo-artneutriplus.component.scss'],
})
export class NuovoArtneutriplusComponent implements ViewWillEnter, OnInit {
    fg: FormGroup;
    values: any;

    codart: string;
    field: string;
    value: string;

    constructor(
        private fb: FormBuilder,
        private ps: PreferenzeService,
        public modalCtrl: ModalController
    ) {}

    ionViewWillEnter() {
        this.getPreferenzaPlus();
        console.log(
            'mi arrivano',
            this.codart + ' - ' + this.field + ' - ' + this.value
        );
    }

    ngOnInit(): void {
        this.fg = this.fb.group({
            field: [this.field, Validators.required],
            value: [this.value, Validators.required],
        });
    }

    async getPreferenzaPlus() {
        await this.ps.getPreferenzeCatalogo().then((preferenze) => {
            preferenze.forEach((preferenza) => {
                if (preferenza.COD_PREFERENZA === 'ARTNEUTRI_PLUS_FIELDS') {
                    let valori = preferenza.VALORE_PREFERENZA;
                    this.values = valori.split(',');
                }
            });
        });
        console.log('preferenze', this.values);
    }

    async setPlus() {
        let field = this.fg.get('field').value;
        let value = this.fg.get('value').value;

        console.log('field' + field && 'value' + value);
    }
}
