<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>{{this.translate.instant('251') + ' ' + preferenza.COD_PREFERENZA}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="this.modalCtr.dismiss()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<!-- TODO: DA RIVEDERE -->
<ion-content *ngIf="this.list1 === false">
    <ion-grid>
        <ion-row>
            <ion-col size="12" text-center>
                <form (keydown.enter)="$event.preventDefault()" [formGroup]="formEditPreferenza">
                    <ion-item mode="ios">
                        <ion-label>{{this.translate.instant('246')}}</ion-label>
                        <ion-input labelPlacement="stacked" type="text" *ngIf="this.text === true"
                            formControlName="valore"></ion-input>
                        <ion-textarea *ngIf="this.textarea === true;" rows="15" formControlName="valore"></ion-textarea>
                        <ion-select interface="popover" mode="ios" *ngIf="this.timezone === true"
                            formControlName="valore">
                            <ion-select-option value="-12">GMT - 12</ion-select-option>
                            <ion-select-option value="-11">GMT - 11</ion-select-option>
                            <ion-select-option value="-10">GMT - 10</ion-select-option>
                            <ion-select-option value="-9">GMT - 9</ion-select-option>
                            <ion-select-option value="-8">GMT - 8</ion-select-option>
                            <ion-select-option value="-7">GMT - 7</ion-select-option>
                            <ion-select-option value="-6">GMT - 6</ion-select-option>
                            <ion-select-option value="-5">GMT - 5</ion-select-option>
                            <ion-select-option value="-4">GMT - 4</ion-select-option>
                            <ion-select-option value="-3">GMT - 3</ion-select-option>
                            <ion-select-option value="-2">GMT - 2</ion-select-option>
                            <ion-select-option value="-1">GMT - 1</ion-select-option>
                            <ion-select-option value="+0">GMT + 0</ion-select-option>
                            <ion-select-option value="+1">GMT + 1</ion-select-option>
                            <ion-select-option value="+2">GMT + 2</ion-select-option>
                            <ion-select-option value="+3">GMT + 3</ion-select-option>
                            <ion-select-option value="+4">GMT + 4</ion-select-option>
                            <ion-select-option value="+5">GMT + 5</ion-select-option>
                            <ion-select-option value="+6">GMT + 6</ion-select-option>
                            <ion-select-option value="+7">GMT + 7</ion-select-option>
                            <ion-select-option value="+8">GMT + 8</ion-select-option>
                            <ion-select-option value="+9">GMT + 9</ion-select-option>
                            <ion-select-option value="+10">GMT + 10</ion-select-option>
                            <ion-select-option value="+11">GMT + 11</ion-select-option>
                            <ion-select-option value="+12">GMT + 12</ion-select-option>
                        </ion-select>
                    </ion-item>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>


<ion-content *ngIf="this.list1 === true">
    <ion-grid>
        <ion-row class="colored" *ngFor="let item of this.arrayFromString.sort(); let i = index">
            <ion-col size="9">{{item}}</ion-col>
            <ion-col class="ion-text-right">
                <ion-icon name="close-outline" *ngIf="item !== '999'" (click)="eliminaCampo(i)"></ion-icon>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>


<ion-footer class="ion-no-border" *ngIf="this.list1 === true">
    <ion-toolbar>
        <ion-item mode="ios">
            <ion-input labelPlacement="stacked" type="text" [(ngModel)]="valoreToAdd"
                (keydown.enter)="aggiungiValore()"></ion-input>
            <ion-button mode="ios" (click)="aggiungiValore()">Aggiungi</ion-button>
        </ion-item>
    </ion-toolbar>
</ion-footer>

<ion-footer class="ion-no-border" *ngIf="this.list1 === false">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' [disabled]="!formEditPreferenza.valid" color="primary"
            (click)="editPreferenza()">{{this.translate.instant('031')}}</ion-button>
    </ion-toolbar>
</ion-footer>