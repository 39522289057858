<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Aggiungi opzscope e context</ion-title>
        <ion-icon color="primary" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
    </ion-toolbar>
</ion-header>

<ion-content>

    <form [formGroup]="fg">
        <ion-item>
            <ion-select label="opzscope" labelPlacement="stacked" formControlName="opzscope" mode="ios"
                interface="popover" (ionChange)="getSelect($event)">
                <ion-select-option value="N">Niente</ion-select-option>
                <ion-select-option value="D">Deriva</ion-select-option>
                <ion-select-option value="M">Maggiorazione percentuale</ion-select-option>
                <ion-select-option value="C">Codice</ion-select-option>
                <ion-select-option value="L">Logica</ion-select-option>
            </ion-select>
        </ion-item>

        <!-- _ Gestione derive -->
        <ion-item *ngIf="this.activeField === 'D'">
            <ion-input label="opzcontext" labelPlacement="stacked" formControlName="opzcontext1"></ion-input>
        </ion-item>

        <!-- _ Gestione maggiorazione percentuale -->
        <ion-item *ngIf="this.activeField === 'M'">
            <ion-input label="Maggiorazione percentuale" labelPlacement="stacked" formControlName="opzcontext1"
                helperText="Inserire solo il valore percentuale, non è necessario il simbolo '%'"></ion-input>
        </ion-item>

        <!-- _ Gestione codice -->
        <ion-item *ngIf="activeField === 'C'">
            <ion-input label="Codice" labelPlacement="stacked" formControlName="opzcontext0"></ion-input>
        </ion-item>

        <ion-item *ngIf="activeField === 'C'">
            <ion-select label="Colonne" labelPlacement="stacked" formControlName="opzcontext1" mode="ios"
                interface="popover">
                <ion-select-option *ngFor="let c of colonne" [value]="c">{{ c }}</ion-select-option>
            </ion-select>
        </ion-item>

        <!-- _ Gestione logica -->
        <ion-item *ngIf="this.activeField === 'L'">

        </ion-item>
    </form>

</ion-content>

<ion-footer class=" ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button color="primary" mode="ios" (click)="salva()">Salva</ion-button>
    </ion-toolbar>
</ion-footer>