<ion-header class="ion-no-border" mode='ios'>
    <ion-toolbar>
        <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()"></ion-icon>
        <ion-title>{{this.translate.instant('182')}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <form *ngIf="this.showForm === true">
                    <ion-item mode="ios">
                        <ion-input [label]="this.translate.instant('187')" labelPlacement="stacked" type="file"
                            accept=".txt, .xls, .xlsx" (change)="importaFile($event)"></ion-input>
                    </ion-item>
                </form>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="this.sceltaListino === true">
            <ion-col size="12">
                <ion-select interface="popover" mode="ios" [label]="this.translate.instant('277')"
                    labelPlacement="stacked" (ionChange)="selezionaListino($event)">
                    <ion-select-option [value]="listino"
                        *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
                </ion-select>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="this.haslink === true">
            <ion-col size="12" text-center>
                <a href="javascript:void(0)"
                    (click)="downloadFileEsempio(this.url)">{{this.translate.instant('195')}}?</a>
            </ion-col>
        </ion-row>
    </ion-grid>


    <ion-footer class="ion-no-border" *ngIf="showBtn === true">
        <ion-row>
            <ion-col size="12" text-center>
                <ion-button mode='ios' color="primary"
                    (click)="prosegui()">{{this.translate.instant('160')}}</ion-button>
            </ion-col>
        </ion-row>
    </ion-footer>
</ion-content>