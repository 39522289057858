import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizzazioniSantaluciaService {
    constructor(
        private commons: CommonFunctionService,
        private globalVar: GlobalVariableService
    ) {}

    async getSetListini() {
        const listini = await this.commons.getSetListiniSantalucia();
        return listini;
    }

    async setSetListini(cod_set: string, des_set: string) {
        const response = await this.commons.setSetListiniSantalucia(
            cod_set,
            des_set
        );
        return response;
    }

    async doImportaPreflightSANTALUCIA(file: File, cod_listino: string) {
        const responseImporta = await this.commons.doImportaPreflightSANTALUCIA(
            file,
            cod_listino
        );
        // console.log('response importa preflight LTFORM', responseImporta);
        console.log(responseImporta['MESSAGGI'].length);
        if (responseImporta['MESSAGGI'].length === 0) {
            // console.log('non ci sono errori, proseguo con l importazione');
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            console.log('FILENAME set', responseImporta['FILE_IMPORT']);
        } else {
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            // console.log('FILENAME set', responseImporta['FILENAME']);
            this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
            responseImporta['MESSAGGI'].forEach((element) => {
                console.log(element['FLAG_IMPORT']);
                console.log(element['MESSAGGIO']);
                if (element['FLAG_IMPORT'] !== 'C') {
                    // console.log('non ci sono errori critici, posso proseguire');
                } else {
                    // console.log('ci sono errori critici, blocco tutto');
                }
            });
        }
    }

    async getAssociazioni() {
        const associazioniArrivateDaApi =
            await this.commons.getAssociazioniSANTALUCIA();
        // console.log('load associazioni ltform', associazioniArrivateDaApi);

        return associazioniArrivateDaApi;
    }

    async doAssociazione(catLT: string, colPL: string) {
        const result = await this.commons.doAssociazioneSANTALUCIA(
            catLT,
            colPL
        );
        // console.log('result do associazione', result);
    }

    async delAssociazione(catLT: string) {
        const result = await this.commons.delAssociazioneSANTALUCIA(catLT);
        // console.log('result del associazione', result);
    }

    async preflightImportaComposizioniSANTALUCIA(file: File) {
        const responseImporta =
            await this.commons.preflightImportaComposizioniSANTALUCIA(file);
        // console.log('response importa preflight LTFORM', responseImporta);
        console.log(responseImporta['MESSAGGI'].length);
        if (responseImporta['MESSAGGI'].length === 0) {
            // console.log('non ci sono errori, proseguo con l importazione');
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            console.log('FILENAME set', responseImporta['FILE_IMPORT']);
        } else {
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            // console.log('FILENAME set', responseImporta['FILENAME']);
            this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
            responseImporta['MESSAGGI'].forEach((element) => {
                console.log(element['FLAG_IMPORT']);
                console.log(element['MESSAGGIO']);
                if (element['FLAG_IMPORT'] !== 'C') {
                    // console.log('non ci sono errori critici, posso proseguire');
                } else {
                    // console.log('ci sono errori critici, blocco tutto');
                }
            });
        }
    }
}
