/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ArticoliService } from 'src/app/servizi/articoli.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-articoli-lingua-insert',
    templateUrl: './articoli-lingua-insert.component.html',
    styleUrls: ['./articoli-lingua-insert.component.scss'],
})
export class ArticoliLinguaInsertComponent implements OnInit {
    data;
    index;
    linguaEdit;
    linguePerViewer;
    codiceArticolo;
    codiceCatalogoStandalone = null;
    uuidStandalone = null;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private articoliService: ArticoliService
    ) {}

    ngOnInit() {}

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async salvaDescrizione(
        codiceDescrizione: string,
        valoreDescrizione: string
    ) {
        // console.log('!!!!!!' + this.linguePerViewer[this.linguaEdit][this.getNomeDescrizione(this.index, this.linguaEdit)]);
        const response = await this.articoliService.setLinguaArticolo(
            this.codiceArticolo,
            codiceDescrizione,
            valoreDescrizione,
            this.codiceCatalogoStandalone,
            this.uuidStandalone
        ); //200

        if (response.RESULT === '200') {
            await this.close(response.RESULT);
        }
    }

    getNomeDescrizione(index: any, lingua: string) {
        return lingua + '_DES' + (index === 0 ? '' : index);
    }
}
