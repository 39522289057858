/* eslint-disable @typescript-eslint/naming-convention */
export class righeComposizioni {
    public CODICE_COMPO: string;
    public RIGA_COMPO: number;
    public CODICE_ARTICOLO: string;
    public QUANTITA: number;
    public COLONNA1: string;
    public COLONNA2: string;
    public COLONNA3: string;
    public COLONNA4: string;
    public COLONNA5: string;
    public DIML: number;
    public DIMA: number;
    public DIMP: number;
    public UM: string;
    public RDATA1: any;
    public RDATA2: any;
    public RDATA3: any;
    public RDATA4: any;
    public RDATA5: any;
    public DES: Array<any[]>;

    constructor(
        CODICE_COMPO: string,
        RIGA_COMPO: number,
        CODICE_ARTICOLO: string,
        QUANTITA: number,
        COLONNA1: string,
        COLONNA2: string,
        COLONNA3: string,
        COLONNA4: string,
        COLONNA5: string,
        DIML: number,
        DIMA: number,
        DIMP: number,
        UM: string,
        RDATA1: any,
        RDATA2: any,
        RDATA3: any,
        RDATA4: any,
        RDATA5: any,
        DES: Array<any[]>
    ) {
        this.CODICE_COMPO = CODICE_COMPO;
        this.RIGA_COMPO = RIGA_COMPO;
        this.CODICE_ARTICOLO = CODICE_ARTICOLO;
        this.QUANTITA = QUANTITA;
        this.COLONNA1 = COLONNA1;
        this.COLONNA2 = COLONNA2;
        this.COLONNA3 = COLONNA3;
        this.COLONNA4 = COLONNA4;
        this.COLONNA5 = COLONNA5;
        this.DIML = DIML;
        this.DIMA = DIMA;
        this.DIMP = DIMP;
        this.UM = UM;
        this.RDATA1 = RDATA1;
        this.RDATA2 = RDATA2;
        this.RDATA3 = RDATA3;
        this.RDATA4 = RDATA4;
        this.RDATA5 = RDATA5;
        this.DES = DES;
    }
}
