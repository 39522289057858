import { Component, OnInit } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { VariantiService } from 'src/app/servizi/varianti.service';
import { varModOpzStruct } from 'src/app/strutture/deepspace/varModOpz.strutture';
import { varOrdStruct } from 'src/app/strutture/deepspace/varModOrd.strutture';
import { variantiStruct } from 'src/app/strutture/varianti.strutture';

@Component({
    selector: 'app-add-variante',
    templateUrl: './add-variante.component.html',
    styleUrls: ['./add-variante.component.scss'],
})
export class AddVarianteComponent implements OnInit, ViewWillEnter {
    varianti: any;
    variantiAssoc: varOrdStruct[] = [];
    varSearch: string = '';

    constructor(
        public modalCtrl: ModalController,
        private vs: VariantiService
    ) {}

    async ionViewWillEnter() {
        await this.getVarianti();
    }

    ngOnInit() {}

    async getVarianti() {
        let variantiTot = await this.vs.getVarianti();
        console.log('vartot', variantiTot);

        this.varianti = variantiTot.filter(
            (variante) =>
                !this.variantiAssoc.some(
                    (assoc) => assoc.codvar === variante.CODVAR
                )
        );
    }

    selezionaVariante(v: variantiStruct) {
        let codice = v.CODVAR;
        let descrizione = v.DESVAR;
        this.modalCtrl.dismiss({ codice, descrizione });
    }
}
