<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Aggiungi un articolo</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar>
            <input class="searchbar" type="text" name="search" placeholder="cerca..." [(ngModel)]="artSearch"
                autocomplete="off" (keydown.enter)="getArtNeutri()">
        </ion-toolbar>
    </ion-header>
    <ion-item mode="ios" *ngFor="let a of articoli" (click)="selectArticolo(a)">
        <ion-text><b>{{a.COD}}</b>
            {{a.DES}}</ion-text>
    </ion-item>
</ion-content>