import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { NuovoListinoCalcolatoComponent } from '../nuovo-listino-calcolato/nuovo-listino-calcolato.component';

import { TranslateService } from '@ngx-translate/core';
import { ListiniService } from 'src/app/servizi/listini.service';
import { NuovaTestataDistintaComponent } from '../listiniCalcolatiPerDistinta/nuova-testata-distinta/nuova-testata-distinta.component';
import { NuovoListinoCalcolatoDistintaComponent } from '../listiniCalcolatiPerDistinta/nuovo-listino-calcolato-distinta/nuovo-listino-calcolato-distinta.component';
@Component({
    selector: 'app-scelta-tipo-nuovo-listino',
    templateUrl: './scelta-tipo-nuovo-listino.component.html',
    styleUrls: ['./scelta-tipo-nuovo-listino.component.scss'],
})
export class SceltaTipoNuovoListinoComponent implements OnInit {
    modalDataResponse: any;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private listiniService: ListiniService
    ) {}

    ngOnInit() {}

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async sceltaTipoListino(tipo: string) {
        // console.log('tipo listino : ', tipo);
        if (tipo === 'calcolato') {
            const modal = await this.modalCtr.create({
                component: NuovoListinoCalcolatoComponent,
                cssClass: 'big',
            });
            await modal.present();
            modal.onDidDismiss();
        } else {
            const modal = await this.modalCtr.create({
                component: NuovoListinoCalcolatoDistintaComponent,
                cssClass: 'big',
            });
            await modal.present();
            modal.onDidDismiss();
        }
    }
}
