<ion-header class="ion-no-border" mode='ios'>
    <ion-toolbar>
        <ion-title>Nuovo modello</ion-title>
        <ion-icon slot="icon-only" name="close-outline" color="primary" style="float: right;"
            (click)="close()"></ion-icon>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form [formGroup]="fg">

        <ion-item mode="ios">
            <ion-input label="Codice" labelPlacement="stacked" formControlName="CODMOD"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DES"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Data 1" labelPlacement="stacked" formControlName="DATA1"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Data 2" labelPlacement="stacked" formControlName="DATA2"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Data 3" labelPlacement="stacked" formControlName="DATA3"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Data 4" labelPlacement="stacked" formControlName="DATA4"></ion-input>
        </ion-item>
        <ion-item mode="ios">
            <ion-input label="Data 5" labelPlacement="stacked" formControlName="DATA5"></ion-input>
        </ion-item>
    </form>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" color="primary" (click)="doNuovoModello()">Prosegui</ion-button>
    </ion-toolbar>
</ion-footer>