import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { righeComposizioni } from 'src/app/strutture/righeComposizioni.strutture';
import { TranslateService } from '@ngx-translate/core';
import { RicercaService } from 'src/app/servizi/ricerca.service';
import { RicercaArticoliComponent } from '../../ricerca-articoli/ricerca-articoli.component';
@Component({
    selector: 'app-nuova-riga-composizioni',
    templateUrl: './nuova-riga-composizioni.component.html',
    styleUrls: ['./nuova-riga-composizioni.component.scss'],
})
export class NuovaRigaComposizioniComponent implements OnInit {
    fg: FormGroup;
    numero_colonne: number[] = [];

    colonneCatalogo: any;

    codice_arrivato: any;
    codice: boolean = false;

    cod_testata: string = '';

    constructor(
        public translate: TranslateService,
        public modalCtrl: ModalController,
        private preferenzeService: PreferenzeService,
        private fb: FormBuilder,
        private globalVar: GlobalVariableService,
        private ricercaService: RicercaService,
        private alertCtrl: AlertController
    ) {}

    ngOnInit() {
        this.fg = this.fb.group(
            {
                qta: ['', Validators.required],
                c1: [''],
                c2: [''],
                c3: [''],
                c4: [''],
                c5: [''],
                diml: [''],
                dima: [''],
                dimp: [''],
                um: ['pz'],
                d1: [''],
                d2: [''],
                d3: [''],
                d4: [''],
                d5: [''],
            },
            { validators: this.atLeastOneFieldFilled(['c1', 'c2', 'c3', 'c4']) }
        );
    }

    // Validatore personalizzato
    atLeastOneFieldFilled(fields: string[]) {
        return (formGroup: AbstractControl) => {
            const isAnyFieldFilled = fields.some(
                (field) => formGroup.get(field)?.value
            );
            return isAnyFieldFilled ? null : { atLeastOneRequired: true };
        };
    }

    async ricercaArticoli() {
        const modal = await this.modalCtrl.create({
            component: RicercaArticoliComponent,
        });
        modal.onDidDismiss().then((modalDataResponse) => {
            if (modalDataResponse !== null) {
                this.codice_arrivato = modalDataResponse.data;
                this.codice = true;
                console.log('Modal Sent Data : ' + modalDataResponse.data);
            }
        });
        return await modal.present();
    }

    async prepareNuovaRiga() {
        if (this.fg.valid) {
            console.log('Dati arrivati:', this.fg.value);
        } else {
            console.log('Form non valida');
        }
    }

    searchCode() {
        if (this.fg.get('codice').value !== '') {
            this.ricercaService
                .ricerca(this.fg.get('codice').value)
                .then(async (response) => {
                    console.log(
                        'response ricerca articoli',
                        response.CODART[0]
                    );
                    if (
                        this.fg.get('codice').value === response.CODART[0].cod
                    ) {
                        this.codice_arrivato = response.CODART[0].cod;
                    } else {
                        console.log('non ho trovato codici corrispondenti');

                        const alert = await this.alertCtrl.create({
                            mode: 'ios',
                            header: this.translate.instant('A004'),
                            message: this.translate.instant('A009'),
                            buttons: [
                                {
                                    text: this.translate.instant('001'),
                                },
                            ],
                        });

                        (await alert).present();
                        await (await alert).onDidDismiss().then(() => {
                            this.fg.reset();
                        });
                    }
                })
                .catch((error) => {
                    // printLogerror);
                });
        }
    }

    gestioneUM(e: any) {
        let um = e.detail.value;
        document.getElementById('diml').style.display = 'block';
        document.getElementById('dima').style.display = 'block';
        document.getElementById('dimp').style.display = 'block';
        switch (um) {
            case 'pz':
                document.getElementById('diml').style.display = 'none';
                document.getElementById('dima').style.display = 'none';
                document.getElementById('dimp').style.display = 'none';
                break;
            case 'mq':
                document.getElementById('dimp').style.display = 'none';
                break;
            case 'ml':
                document.getElementById('dima').style.display = 'none';
                document.getElementById('dimp').style.display = 'none';
                break;
        }
    }

    async doNuovaRiga() {
        if (this.fg.invalid) {
            if (this.fg.hasError('atLeastOneRequired')) {
                const alert = await this.alertCtrl.create({
                    header: 'Attenzione',
                    mode: 'ios',
                    message:
                        'Devi compilare almeno uno dei campi Colonna prezzo',
                    buttons: ['OK'],
                });

                await alert.present();
            }
        } else {
            const nuovaR = new righeComposizioni(
                this.cod_testata,
                0,
                this.codice_arrivato,
                this.fg.get('qta').value,
                this.fg.get('c1').value,
                this.fg.get('c2').value,
                this.fg.get('c3').value,
                this.fg.get('c4').value,
                this.fg.get('c5').value,
                this.fg.get('diml').value ? this.fg.get('diml').value : 0,
                this.fg.get('dima').value ? this.fg.get('dima').value : 0,
                this.fg.get('dimp').value ? this.fg.get('dimp').value : 0,
                this.fg.get('um').value,
                this.fg.get('d1').value,
                this.fg.get('d2').value,
                this.fg.get('d3').value,
                this.fg.get('d4').value,
                this.fg.get('d5').value,
                []
            );
            console.log('payload riga', nuovaR);
            this.modalCtrl.dismiss(nuovaR);
        }
    }
}
