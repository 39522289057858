/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';

import { DataManagerService } from './data-manager.service';
import * as defaults from '../default-values';
import { GlobalVariableService } from './global-variable.service';
import { utenteInPrint } from '../strutture/utenteInPrint.strutture';
import { PreferenzaCatalogo, PreferenzaInPrint } from './preferenze.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { articoliStruct } from '../strutture/articoli.strutture';
import { ricercaStruct } from '../strutture/ricerca.strutture';
import { listiniStruct } from '../strutture/listini.strutture';
import { macroTestateStruct } from '../strutture/macroTestate.strutture';
import { righeMacroStruct } from '../strutture/righeMacro.strutture';
import { PrezzoArticolo, RispostaGetLingueArticolo } from './articoli.service';
import { simulatoreStruct } from '../strutture/simulatore.strutture';
import { importaStruct } from '../strutture/importa.strutture';
import { storiaArticoloStrutture } from '../strutture/storiaArticolo.strutture';
import { traduzioniStruct } from '../strutture/traduzioni.strutture';
import { invitoDaInviareStruct } from '../strutture/invitoDaInviare.strutture';
import { calcoliStruct } from '../strutture/calcoli.strutture';
import { reportStruct } from '../strutture/reports.strutture';
import { utenteErrataStruct } from '../strutture/utenteErrataStruct.strutture';
import { composizioniStruct } from '../strutture/composizioni.strutture';
import { righeComposizioni } from '../strutture/righeComposizioni.strutture';
import { modelliStruct } from '../strutture/deepspace/modelli.strutture';
import { modelliVariantiStruct } from '../strutture/modelliVarianti.strutture';
import { varopzStruct } from '../strutture/varopz.strutture';
import { ordinamentoStruct } from '../strutture/ordinamento.strutture';
import { variantiStruct } from '../strutture/varianti.strutture';
import { articoliAssociatiStruct } from '../strutture/articoliAssociati.strutture';
import { articoliModelliStruct } from '../strutture/articoliModelli.strutture';
import { menuStruct } from '../strutture/menu.strutture';
import { articoliDeepStruct } from '../strutture/articoliDeep.strutture';
import { nazioniStruct } from '../strutture/nazioni.strutture';
import { provinceStruct } from '../strutture/province.strutture';
import { testateTipologie } from '../strutture/testateTipologie.strutture';
import { changeLogStruct } from '../strutture/changeLog.strutture';
import { tipologiaStruct } from '../strutture/tipologia.strutture';
import { rigaTipologieStruct } from '../strutture/riga-tipologia.strutture';
import { codificatoreStruct } from '../strutture/codificatore.strutture';
import { utenteLogin } from '../strutture/utenteLogin.strutture';
import { Observable, Subject } from 'rxjs';
import { logStruct } from '../strutture/log.strutture';
import { codiciLogStruct } from '../strutture/codiciLog.strutture';
import { listinoStruct } from '../strutture/listino.strutture';
import { listinoPerPagina } from '../strutture/listinoPerPagina.strutture';
import { vaultStruct } from '../strutture/vault.strutture';
import { vaultConfig } from '../strutture/vaultConfig.strutture';
import { setListini } from '../dialogs/santalucia/santalucia-importazione/santalucia-importazione.component';
import { millaUsers } from '../strutture/millaUsers.strutture';
import { scontiUtenteMilla } from '../strutture/scontiUtenteMilla.strutture';
import { colvarStruct } from '../strutture/colvar.strutture';
import { righeListiniStruct } from '../strutture/listini calcolati distinta/righeListini.strutture';
import { valColOpz } from '../strutture/valColOpz.strutture';
import { deriveStruct } from '../strutture/derive.strutture';
import { artNeutriStruct } from '../strutture/deepspace/articoli.strutture';
import { artAssocStruct } from '../strutture/deepspace/articoliAssociati.strutture';
import { varModOpzStruct } from '../strutture/deepspace/varModOpz.strutture';
import { opzioniStruct } from '../strutture/opzioni.strutture';
import { varOrdStruct } from '../strutture/deepspace/varModOrd.strutture';
import {
    assocColVarOpzStruct,
    assocColVarStruct,
} from '../strutture/assocColVar.strutture';
import { desCompoStruct } from '../strutture/descompo.strutture';
import { varCodOpzStruct } from '../strutture/deepspace/varCodOpz.strutture';
import { varArtOrdStruct } from '../strutture/deepspace/varArtOrd.strutture';
import { artNeutriPlusStruct } from '../strutture/artNeutriPlus.strutture';

export interface ResultResponse {
    RESULT: string;
}

@Injectable({
    providedIn: 'root',
})
export class CommonFunctionService implements OnDestroy {
    private destroy$ = new Subject();

    constructor(
        private dataManager: DataManagerService,
        private globalvar: GlobalVariableService,
        private cookieService: CookieService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    //* CHECK PARAMS
    check(par: any) {
        par.forEach((value, key) => {
            console.log(key + ':', value);
        });
    }

    //* ROUTING
    goTo(route: string) {
        this.router.navigate([route]);
    }

    //* LOGIN
    async login(mail: string, password: string) {
        const url = defaults.INPRINT_API + 'login3.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['EMAIL', mail],
            ['PASSWORD', password],
            ['LINGUA', defaults.LINGUA],
            ['MODO_LOGIN', 'CLASS'], //todo: hardcoded, va modificato in base ai metodi di login
        ]);

        return this.dataManager
            .dataRequestRitornoCodice<utenteLogin>(url, parametri, 'login')
            .pipe(
                tap(async (response) => {
                    const codiceRitorno = JSON.parse(JSON.stringify(response));
                    return codiceRitorno;
                })
            )
            .toPromise();
    }

    async cambiaEula(modo: string, uuid: string) {
        const url = defaults.INPRINT_API + 'cambiaEULA.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['UUID', uuid],
            ['MODO', modo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'cambiaEULA')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async checkUserExist(mailToCheck: string) {
        const url = defaults.INPRINT_API + 'checkUserExsist.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['MAIL_TO_CHECK', mailToCheck],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'checkUserExist')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async inviaNumRecoveryPassword(mailToReset: string) {
        const url = defaults.INPRINT_API + 'sendRecoverNumber.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['MAIL_TO_RESET', mailToReset],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNewUser')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async checkNumRecovery(mailToCheck: string, numeroInput: number) {
        const url = defaults.INPRINT_API + 'checkRecoverNumber.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['MAIL_TO_CHECK', mailToCheck],
            ['LINGUA', defaults.LINGUA],
            ['NUMERO_INPUT', numeroInput],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNewUser')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async ritornaNazioni() {
        const url = defaults.INPRINT_API + 'getNazioni.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['NAZIONE', 'IT'],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<nazioniStruct[]>(
                url,
                parametri,
                'getNazioni'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async ritornaStati(nazione: string) {
        const url = defaults.INPRINT_API + 'getState.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['NAZIONE', nazione],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<provinceStruct[]>(
                url,
                parametri,
                'getNazioni'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doChangePassword(password: string, mail: string) {
        const url = defaults.INPRINT_API + 'changePassword.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['PASS', password],
            ['MAIL', mail],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<provinceStruct[]>(
                url,
                parametri,
                'changePassword'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* CATALOGHI
    getCataloghi(): Promise<any> {
        const url =
            defaults.INPRINT_API +
            'ritornaUtenteAttivoConConfigurazioneCat.php';
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['UUID', uuid],
            ['ID_CATALOGO', ''],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getCataloghi')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* RICERCA
    async ricerca(searchTxt: string) {
        const url = defaults.INPRINT_API + 'ricerca2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['TOSEARCH', searchTxt],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ricercaStruct>(url, parametri, 'ricerca')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    ricerca2(searchTxt: string) {
        const url = 'ricerca2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['TOSEARCH', searchTxt],
        ]);
        return new Observable((observer) => {
            this.dataManager
                .dataRequestAPI(defaults.INPRINT_API, url, parametri, 0, '')
                .subscribe((completamento) => {
                    if (completamento) {
                        let rispostaRicerca =
                            this.dataManager.getERRATACORRIGE_JSON_DATA();
                        this.globalvar.setRICERCA_RESULT(rispostaRicerca);
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                });
        });
    }

    //* PREFERENZE
    async getPreferenzeCatalogo() {
        const url = defaults.INPRINT_API + 'getPreferenzeCatalogo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CMB_PROFILO', profilo],
        ]);

        console.log('profilo', profilo);

        return this.dataManager
            .dataRequestRitornoCodice<PreferenzaCatalogo[]>(
                url,
                parametri,
                'getPreferenzeCatalogo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getPreferenzaCatalogo(preferenza: string) {
        const url = defaults.INPRINT_API + 'getPreferenzaCatalogo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        //const profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_PREFERENZA', preferenza],
        ]);

        return this.dataManager
            .dataRequestRitornoCodice<any>(
                url,
                parametri,
                'getPreferenzaCatalogo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setPreferenzaCatalogo(
        codPreferenza: string,
        valorePreferenza: string,
        modulo: string,
        priorita: string,
        setTipoGest: string
    ) {
        console.log('API arrivata', setTipoGest);
        const url = defaults.INPRINT_API + setTipoGest.toString();
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['COD_PREFERENZA', codPreferenza],
            ['VALORE_PREFERENZA', valorePreferenza],
            ['MODULO', modulo],
            ['PRIORITA', priorita],
            ['CMB_PROFILO', profilo],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parametri.forEach((value, key) => {
            console.log(key + ': ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<{ RESULT: string }>(
                url,
                parametri,
                'setPreferenzeCatalogo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getPreferenzeInPrint() {
        const url = defaults.INPRINT_API + 'getPreferenzeInPrint.php';
        const profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', profilo],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice<PreferenzaInPrint[]>(
                url,
                parametri,
                'getPreferenzeInPrint'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* ARTICOLI
    async nuovoArticolo(articolo: string) {
        const url = defaults.INPRINT_API + 'doNuovoArticolo2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', this.globalvar.getUUID()],
            ['CODICE', articolo],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' = ' + value);
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice<articoliStruct[]>(
                url,
                parametri,
                'doNuovoArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getDimensioniArticolo(codiceArticolo: string) {
        const url = defaults.INPRINT_API + 'getDimensioniArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['CODART', codiceArticolo],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getDimensioniArticolo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    // async getLingueArticolo(
    //   codiceArticolo: string,
    //   codiceCatalogoStandalone: string = null
    // ) {
    //   const url = defaults.INPRINT_API + 'getLingueArticolo.php';
    //   const parametri = new Map<string, any>([
    //     ['APPID', defaults.APPID],
    //     [
    //       'ID_CATALOGO',
    //       codiceCatalogoStandalone === null
    //         ? this.globalvar.getIdCatalogoAttivo()
    //         : codiceCatalogoStandalone,
    //     ],
    //     ['CODART', codiceArticolo],
    //   ]);
    //   // eslint-disable-next-line @typescript-eslint/naming-convention
    //   return this.dataManager
    //     .dataRequestRitornoCodice<RispostaGetLingueArticolo>(
    //       url,
    //       parametri,
    //       'getLingueArticolo'
    //     )
    //     .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
    //     .toPromise();
    // }

    async getLingueArticolo(
        codiceArticolo: string,
        codiceCatalogoStandalone: string = null
    ) {
        const url = 'getLingueArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            [
                'ID_CATALOGO',
                codiceCatalogoStandalone === null
                    ? this.globalvar.getIdCatalogoAttivo()
                    : codiceCatalogoStandalone,
            ],
            ['CODART', codiceArticolo],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return new Observable((observer) => {
            this.dataManager
                .dataRequestAPI(defaults.INPRINT_API, url, parametri, 0, '')
                .subscribe((completamento) => {
                    if (completamento) {
                        let risultatoLingueArticolo =
                            this.dataManager.getERRATACORRIGE_JSON_DATA();
                        this.globalvar.setLINGUE_ARTICOLO(
                            risultatoLingueArticolo
                        );
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                });
        });
    }

    async getLingueArtPerCompo(codiceArticolo: string) {
        const url = defaults.INPRINT_API + 'getLingueArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['CODART', codiceArticolo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getLingueArticolo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getEreditarietaArticolo(codiceArticolo: string) {
        const url = defaults.INPRINT_API + 'getEreditarietaArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['CODART', codiceArticolo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<Record<string, string>[]>(
                url,
                parametri,
                'getEreditarietaArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getPrezziArticolo(codiceArticolo: string, codiceListino: string) {
        const url = defaults.INPRINT_API + 'getPrezziArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['CODART', codiceArticolo],
            ['LISTINO', codiceListino],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice<PrezzoArticolo[]>(
                url,
                parametri,
                'getPrezziArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getStoriaArticolo(codiceArticolo: string) {
        const url = defaults.INPRINT_API + 'getStoriaArticolo.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['CODART', codiceArticolo],
        ]);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice<storiaArticoloStrutture[]>(
                url,
                parametri,
                'getStoriaArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setDimensioneArticolo(
        codiceArticolo: string,
        campo: string,
        value: string
    ) {
        const url = defaults.INPRINT_API + 'setDimensioniArticolo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', this.globalvar.getUUID()],
            ['CODART', codiceArticolo],
            ['CAMPO', campo],
            ['VALORE', value],
            ['CUSTOM_FIELD_2', ''],
            ['CUSTOM_FIELD_3', ''],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'setDimensioniArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setEreditarietaArticolo(
        codiceArticolo: string,
        campo: string,
        value: string
    ) {
        const url = defaults.INPRINT_API + 'setEreditarietaArticolo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', this.globalvar.getUUID()],
            ['CODART', codiceArticolo],
            ['CAMPO', campo],
            ['VALORE', value],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'setEreditarietaArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setLinguaArticolo(
        codiceArticolo: string,
        codiceDescrizione: string,
        valoreDescrizione: string,
        codiceCatalogoStandalone: string = null,
        uuidStandalone: string = null
    ) {
        const url = defaults.INPRINT_API + 'setDescrizioneArticolo.php';
        const catalogoAttivo =
            codiceCatalogoStandalone === null
                ? this.globalvar.getIdCatalogoAttivo()
                : codiceCatalogoStandalone;
        const uuid =
            uuidStandalone === null ? this.globalvar.getUUID() : uuidStandalone;
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODART', codiceArticolo],
            ['DES_FIELD', codiceDescrizione],
            ['DESCRIZIONE_ARTICOLO', valoreDescrizione],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'setDescrizioneArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async copiaArticolo(codiceArticolo: string, codEnd: string) {
        const url = defaults.INPRINT_API + 'doCopiaArticolo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', this.globalvar.getUUID()],
            ['COD_START', codiceArticolo],
            ['COD_END', codEnd],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'doCopiaArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setPrezzoArticolo(
        codiceArticolo: string,
        codiceListino: string,
        colonna: string,
        prezzo: any,
        listino: string,
        distinta: string
    ) {
        const url = defaults.INPRINT_API + 'setPrezzoArticolo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CODART', codiceArticolo],
            ['ID_CATALOGO', catalogoAttivo],
            ['LISTINO', codiceListino],
            ['UUID', this.globalvar.getUUID()],
            ['COMB', colonna],
            ['PREZZO', prezzo],
            ['DISTINTA_PREZZO', listino],
            ['DISTINTA_BASE', distinta],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'setPrezzoArticolo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* custom fields

    async getCustomFields(codart: string) {
        const url = defaults.INPRINT_API + 'getCustomFields.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any[]>(url, parametri, 'getCustomFields')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setCustomField(codart: string, customfield: string, valore: string) {
        const url = defaults.INPRINT_API + 'setCustomField.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CODART', codart],
            ['ID_CATALOGO', catalogoAttivo],
            ['CUSTOM_FIELD', customfield],
            ['UUID', this.globalvar.getUUID()],
            ['VALORE', valore],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setCustomField')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* artneutri plus
    async getArtNeutriPlus(codart: string) {
        const url = defaults.INPRINT_API + 'getArtneutriPlus.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<artNeutriPlusStruct[]>(
                url,
                parametri,
                'getArtneutriPlus'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setArtNeutriPlus(codart: string, field: string, value: any) {
        const url = defaults.INPRINT_API + 'setArtNeutriPlus.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CODART', codart],
            ['ID_CATALOGO', catalogoAttivo],
            ['FIELD', field],
            ['UUID', this.globalvar.getUUID()],
            ['VALUE', value],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setArtNeutriPlus')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* INTESTAZIONI
    async doNuovaIntestazione(
        cod_int: string,
        des_field: string,
        des_int: string
    ) {
        const url = defaults.INPRINT_API + 'setDescrizioneIntestazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CODINT', cod_int],
            ['ID_CATALOGO', catalogoAttivo],
            ['DES_FIELD', des_field],
            ['DESCRIZIONE_INTESTAZIONE', des_int],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'setDescrizioneIntestazione'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getLingueIntestazione(
        codiceIntestazione: any,
        codiceCatalogoStandalone: string = null
    ) {
        const url = defaults.INPRINT_API + 'getLingueIntestazione.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            [
                'ID_CATALOGO',
                codiceCatalogoStandalone === null
                    ? this.globalvar.getIdCatalogoAttivo()
                    : codiceCatalogoStandalone,
            ],
            ['CODINT', codiceIntestazione],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.dataManager
            .dataRequestRitornoCodice<RispostaGetLingueArticolo>(
                url,
                parametri,
                'getLingueIntestazione'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doCopiaIntestazione(cod_start: string, cod_end: string) {
        const url = defaults.INPRINT_API + 'doCopiaIntestazioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['COD_START', cod_start],
            ['COD_END', cod_end],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCopiaIntestazioni')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setLinguaIntestazione(
        codiceIntestazione: string,
        codiceDescrizione: string,
        valoreDescrizione: string,
        codiceCatalogoStandalone: string = null,
        uuidStandalone: string = null
    ) {
        const url = defaults.INPRINT_API + 'setDescrizioneIntestazione.php';
        const catalogoAttivo =
            codiceCatalogoStandalone === null
                ? this.globalvar.getIdCatalogoAttivo()
                : codiceCatalogoStandalone;
        const uuid =
            uuidStandalone === null ? this.globalvar.getUUID() : uuidStandalone;
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODINT', codiceIntestazione],
            ['DES_FIELD', codiceDescrizione],
            ['DESCRIZIONE_INTESTAZIONE', valoreDescrizione],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'setDescrizioneIntestazione'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async creaIntestazione(codint: string) {
        const url = defaults.INPRINT_API + 'doNuovaIntestazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODINT', codint],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<ResultResponse>(
                url,
                parametri,
                'doNuovaIntestazione'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* LISTINI
    async getListini() {
        const url = defaults.INPRINT_API + 'getListini.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<listiniStruct[]>(
                url,
                parametri,
                'getListini'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getListino(cod_listino: string) {
        const url = defaults.INPRINT_API + 'getListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<listinoStruct[]>(
                url,
                parametri,
                'getListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getListinoPerPagina(
        cod_listino: string,
        pagina: number,
        step_pagine: number,
        codart: string
    ) {
        const url = defaults.INPRINT_API + 'getListinoPerPagina.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino],
            ['PAGINA', pagina],
            ['STEP_PAGINE', step_pagine],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<listinoPerPagina[]>(
                url,
                parametri,
                'getListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async nuovoListino(
        cod_listino: string,
        des_listino: string,
        valuta_listino: string
    ) {
        const url = defaults.INPRINT_API + 'doNuovoListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino], // todo : no spazi, no caratteri speciali, no trattini
            ['DES_NEW_LISTINO', des_listino],
            ['BACKUP_FLAG', 1],
            ['CUSTOM_FIELD_1', valuta_listino],
            ['CUSTOM_FIELD_2', ''],
            ['CUSTOM_FIELD_3', ''],
            ['DATA_VALIDITA', 0],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<listiniStruct[]>(
                url,
                parametri,
                'doNuovoListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setValiditaListino(cod_listino: string, data_validita: number) {
        const url = defaults.INPRINT_API + 'setValiditaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino], // todo : no spazi, no caratteri speciali, no trattini
            ['DATA_VALIDITA', data_validita],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setValiditaListino')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async svuotaPrezziListino(cod_listino: string) {
        const url = defaults.INPRINT_API + 'doSvuotaPrezzi.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doSvuotaPezzi')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async eliminaListino(cod_listino: string) {
        const url = defaults.INPRINT_API + 'doCancellaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaListino')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async duplicaListino(
        cod_listino_start: string,
        cod_listino_end: string,
        des_listino_end: string
    ) {
        const url = defaults.INPRINT_API + 'doCopiaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO_START', cod_listino_start],
            ['COD_LISTINO_END', cod_listino_end],
            ['DES_LISTINO_END', des_listino_end],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCopiaListino')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    getValuteListino() {
        const url = defaults.INPRINT_API + 'getValute.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getValute')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    setValuteListino(cod_listino: string, cod_valuta: string) {
        const url = defaults.INPRINT_API + 'setValutaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_LISTINO', cod_listino],
            ['COD_VALUTA', cod_valuta],
        ]);

        parametri.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setValutaListino')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* listini per distinta

    async doListinoCalcolatoDistinta(
        cod_distinta: string,
        listino_start: string,
        listino_end: string
    ) {
        const url = defaults.INPRINT_API + 'doListinoCalcolatoDistinta.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['LISTINO_START', listino_start],
            ['LISTINO_END', listino_end],
            ['DEBUG_PROGRESS', 0],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doListinoCalcolatoDistinta'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //- TESTATE
    async getTListinoDistinta() {
        const url = defaults.INPRINT_API + 'getDistinteListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getDistinteListino')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doTListinoDistinta(cod_distinta: string, des_distinta: string) {
        const url = defaults.INPRINT_API + 'doNuovaDistintaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['DES_DISTINTA', des_distinta],
        ]);

        parametri.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<any>(
                url,
                parametri,
                'doNuovaDistintaListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delTListinoDistinta(cod_distinta: string) {
        const url = defaults.INPRINT_API + 'doCancellaTestataDistinta.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doCancellaTestataDistinta'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editTListinoDistinta(
        cod_distinta: string,
        field: string,
        value: string
    ) {
        const url = defaults.INPRINT_API + 'doModificaTestataDistinta.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['FIELD', field],
            ['VALUE', value],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doModificaTestataDistinta'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //- RIGHE
    async getRListinoDistinta(cod_distinta: string) {
        const url = defaults.INPRINT_API + 'getRigheDistintaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA_LISTINO', cod_distinta],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<righeListiniStruct[]>(
                url,
                parametri,
                'getRigheDistintaListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doRListinoDistinta(
        cod_distinta: string,
        col_start: string,
        segno: string,
        perc: string,
        col_dest: string,
        arrot: string
    ) {
        const url = defaults.INPRINT_API + 'doNuovaRigaDistintaListino.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['COL_START', col_start],
            ['SEGNO', segno],
            ['PERC', perc],
            ['COL_DEST', col_dest],
            ['ARROT', arrot],
        ]);
        parametri.forEach((value, key) => {
            console.log('key: ' + key + ' value: ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doNuovaRigaDistintaListino'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delRListinoDistinta(cod_distinta: string, col_dest: string) {
        const url = defaults.INPRINT_API + 'doCancellaRigaDistinta.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['COL_DEST', col_dest],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaRigaDistinta')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editRListinoDistinta(
        cod_distinta: string,
        field: string,
        value: string,
        col_dest: string
    ) {
        const url = defaults.INPRINT_API + 'doModificaRigaDistinta.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_DISTINTA', cod_distinta],
            ['FIELD', field],
            ['VALUE', value],
            ['COL_DEST', col_dest],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doModificaRigaDistinta')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* IMPORTAZIONI
    async doImportaPreview(file: File) {
        const url = defaults.INPRINT_API + 'doImportaPreview.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['file', file],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doImportaPreview')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importArticoliPreflight(file: File) {
        const url = defaults.INPRINT_API + 'importDesArticoliPreflight.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<importaStruct[]>(
                url,
                parametri,
                'doImportaPreview'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importaPrezziPreflight(file: File, cod_listino: string) {
        const url = defaults.INPRINT_API + 'importPrezziPreflight.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
            ['COD_LISTINO', cod_listino],
        ]);

        console.log('par', parametri);
        return this.dataManager
            .dataRequestRitornoCodice<importaStruct[]>(
                url,
                parametri,
                'doImportaPreview'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importaIntestazioniPreflight(file: File) {
        const url = defaults.INPRINT_API + 'importIntestazioniPreflight.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<importaStruct[]>(
                url,
                parametri,
                'doImportaPreview'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importaPadreFiglioPreflight(file: File) {
        const url = defaults.INPRINT_API + 'importPadreFiglioPreflight.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
        ]);
        console.log(catalogoAttivo);
        return this.dataManager
            .dataRequestRitornoCodice<importaStruct[]>(
                url,
                parametri,
                'doImportaPreview'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importaComposizioniPreflight(file: File) {
        const url = defaults.INPRINT_API + 'importComposizioniDesPreflight.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
        ]);

        this.check(parametri);

        return this.dataManager
            .dataRequestRitornoCodice<importaStruct[]>(
                url,
                parametri,
                'doImportaPreview'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* ESPORTAZIONE
    async esportaART(listino: string, modo: string) {
        const url = defaults.INPRINT_API + 'doExportBackground.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['COD_LISTINO', listino],
            ['MODO', modo],
            ['LINGUA', defaults.LINGUA],
        ]);
        //console.log(url+parametri);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doEsportaART')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async esportaINT() {
        const url = defaults.INPRINT_API + 'doExportBackgroundIntestazioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['MODO', 'ALL'],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doEsportaINT')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async esportaCOMPO() {
        const url = defaults.INPRINT_API + 'doExportComposizioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doExportComposizioni')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async esportaWINNER(cod_listino: string) {
        const url = defaults.INPRINT_API + 'doExportWinnerBackground.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doExportWinnerBackground'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async resetCampoPage(modo: string) {
        const url = defaults.INPRINT_API + 'resetCampoPage.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['MODO', modo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doResetCampoPage')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* TRADUZIONI
    async traduzioni(
        payloadTrad: traduzioniStruct,
        mailTo: string,
        tipoTrad: string
    ) {
        const url = defaults.INPRINT_API + 'doTradRequest2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['TRAD_REQUEST', JSON.stringify(payloadTrad)],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['TO', mailTo],
            ['TIPO_TRAD', tipoTrad],
            ['LINGUA', defaults.LINGUA],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ': ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'doTradRequest2')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* TEAM
    async ricercaUtentiCatalogo(ricercaString: string) {
        const url = defaults.INPRINT_API + 'ricercaUtenti.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO_ERRATA', catalogoAttivo],
            ['RICERCA_UTENTI_STRING', ricercaString],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<utenteErrataStruct[]>(
                url,
                parametri,
                'ricercaUtenti'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async ritornaUtentiCatalogo() {
        const url = defaults.INPRINT_API + 'ritornaUtentiCatalogoConInviti.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<utenteInPrint[]>(
                url,
                parametri,
                'ritornaUtentiCatalogo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doNewUser(userdata: utenteErrataStruct) {
        const url = defaults.INPRINT_API + 'createInPrintUser.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['USERDATA', JSON.stringify(userdata)],
            ['LINGUA', defaults.LINGUA],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID_IN', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<utenteErrataStruct[]>(
                url,
                parametri,
                'doNewUser'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async deleteUtente(uuid_da_rimuovere: string) {
        const url = defaults.INPRINT_API + 'doRimuoviUserTeam.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID_UTENTE_DA_RIMUOVERE', uuid_da_rimuovere],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'ritornaUtentiCatalogo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doCreaInvito(payload_invito: invitoDaInviareStruct) {
        const url = defaults.INPRINT_API + 'doCreaInvito.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INVITO_PAYLOAD', JSON.stringify(payload_invito)],
            ['LINGUA', defaults.LINGUA],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<invitoDaInviareStruct[]>(
                url,
                parametri,
                'doNuovaMacroTestata'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async eliminaInvito(invite_id: string) {
        const url = defaults.INPRINT_API + 'cancellaInvito.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['INVITE_ID', invite_id],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'ritornaUtentiCatalogo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //*LISTINI CALCOLATI
    async doListinoCalcolato(calcoli_payload: calcoliStruct) {
        const url = defaults.INPRINT_API + 'doListinoCalcolato.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CALC_PAYLOAD', JSON.stringify(calcoli_payload)],
            ['LINGUA', defaults.LINGUA],
            ['ID_CATALOGO', catalogoAttivo],
            ['DEBUG_PROGRESS', 'off'],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doListinoCalcolato')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doListinoCalcolatoBg(calcoli_payload: calcoliStruct) {
        const url = defaults.INPRINT_API + 'doListinoCalcolatoBackground.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CALC_PAYLOAD', JSON.stringify(calcoli_payload)],
            ['LINGUA', defaults.LINGUA],
            ['ID_CATALOGO', catalogoAttivo],
            ['DEBUG_PROGRESS', 0],
            ['UUID', uuid],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doListinoCalcolatoBackground'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* REPORTS
    async getReports() {
        const url = defaults.INPRINT_API + 'getReports.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        console.log('id_cat', catalogoAttivo);
        return this.dataManager
            .dataRequestRitornoCodice<reportStruct[]>(
                url,
                parametri,
                'getReports'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI MARTEDESIGN
    async doImportaPreflightMD(file: File, cod_listino: string) {
        const url =
            defaults.INPRINT_API +
            'pers/martedesign/' +
            'importPreflightMartedesign.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['COD_LISTINO', cod_listino],
            ['file', file],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doImportaPreflightMarteDesign'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI DVO
    async esportazioneDVO(da_modello: string, a_modello: string) {
        const url = defaults.INPRINT_API + '/pers/dvo/esportaPcon.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['DA_MODELLO', da_modello],
            ['A_MODELLO', a_modello],
            ['MODO_ESTRAI', 'M'],
            ['FLAG_PAGE', 1],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'esportaPcon')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI ALEA
    async doImportaPreflightALEA(file: File, cod_listino: string) {
        const url =
            defaults.INPRINT_API + '/pers/alea/' + 'importPreflightAlea.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['COD_LISTINO', cod_listino],
            ['file', file],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doimportPreflightAlea')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI LTFORM
    async getAssociazioniLTFORM() {
        const url =
            defaults.INPRINT_API + 'pers/ltform/' + 'getAssociazioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<PreferenzaCatalogo[]>(
                url,
                parametri,
                'getAssociazioni'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doAssociazioneLTFORM(catLT: string, colPL: string) {
        const url =
            defaults.INPRINT_API + 'pers/ltform/' + 'doAssociazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
            ['CAT_LT', catLT],
            ['COL_PLIST', colPL],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAssociazione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociazione(catLT: string) {
        const url =
            defaults.INPRINT_API + 'pers/ltform/' + 'delAssociazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
            ['CAT_LT', catLT],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delAssociazione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doImportaPreflightLTFORM(
        file: File,
        cod_listino: string,
        sovrascrivi_des: number
    ) {
        const url =
            defaults.INPRINT_API + 'pers/ltform/' + 'importPreflightLtForm.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
            ['COD_LISTINO', cod_listino],
            ['SOVRASCRIVI_DES', sovrascrivi_des],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delAssociazione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI ARBI
    async getAssociaVariantiColonna(col_plist: string) {
        const url =
            defaults.INPRINT_API +
            'pers/arbi/' +
            'getAssociazioniVariantiColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COLONNA_PLIST', col_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<assocColVarStruct[]>(
                url,
                parametri,
                'getAssociazioniVariantiColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssociaVarianteOpzioniColonna(col_plist: string, codvar: string) {
        const url =
            defaults.INPRINT_API +
            'pers/arbi/' +
            'getAssociazioniVarianteOpzioniColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COLONNA_PLIST', col_plist],
            ['CODVAR', codvar],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<assocColVarOpzStruct[]>(
                url,
                parametri,
                'getAssociazioniVarianteOpzioniColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setAssociaVariantiColonna(
        codvar: string,
        codopz: string,
        col_plist: string
    ) {
        const url =
            defaults.INPRINT_API +
            'pers/arbi/' +
            'setAssociazioniVariantiColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
            ['COLONNA_PLIST', col_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'setAssociazioniVariantiColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociaVarCol(codvar: string, col_plist: string) {
        const url =
            defaults.INPRINT_API +
            'pers/arbi/' +
            'delAssociazioniVarianteColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', codvar],
            ['COLONNA_PLIST', col_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'delAssociazioniVarianteColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociaVarOpzCol(
        codvar: string,
        codopz: string,
        col_plist: string
    ) {
        const url =
            defaults.INPRINT_API +
            'pers/arbi/' +
            'delAssociazioniVarianteOpzioneColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
            ['COLONNA_PLIST', col_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'delAssociazioniVarianteOpzioneColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doExportRivenditori(
        cod_listino: string,
        modelli: string,
        lang: any,
        model_only: number,
        error_flag: number
    ) {
        const url =
            defaults.INPRINT_API + 'pers/arbi/' + 'doExportRivenditori.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUE', lang],
            ['COD_LISTINO', cod_listino],
            ['UUID', this.globalvar.getUUID()],
            ['DEBUG', 0],
            ['CODART_TO_TEST', ''],
            ['TOP', 15],
            ['MODELLI_TO_EXPORT', modelli],
            ['MODEL_ONLY', model_only],
            ['ERROR_FLAG', error_flag],
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doExportRivenditori')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI ITALIAN SEDIOLITI

    async doImportaPreflightSEDIOLITI(file: File, cod_listino: string) {
        const url =
            defaults.INPRINT_API +
            'pers/sedioliti/' +
            'importPreflightSedioliti.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'importSedioliti')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PERSONALIZZAZIONI SANTALUCIA
    async setSetListiniSantalucia(cod_set: string, des_listino: string) {
        const url =
            defaults.INPRINT_API +
            'pers/santalucia/' +
            'setSetListiniSantaLucia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['COD_SET', cod_set],
            ['DES', des_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'creaSetSantalucia')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getSetListiniSantalucia() {
        const url =
            defaults.INPRINT_API +
            'pers/santalucia/' +
            'getSetListiniSantaLucia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<setListini[]>(
                url,
                parametri,
                'getSetSantalucia'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doImportaPreflightSANTALUCIA(file: File, cod_set: string) {
        const url =
            defaults.INPRINT_API +
            'pers/santalucia/' +
            'importPreflightSantalucia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
            ['LISTINO_SET', cod_set],
            ['UUID', this.globalvar.getUUID()],
            ['DEBUG_PROGRESS', 1],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'preflightSantalucia')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async preflightImportaComposizioniSANTALUCIA(file: File) {
        const url =
            defaults.INPRINT_API +
            'pers/santalucia/' +
            'importPreflightCompoSantalucia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['LINGUA', defaults.LINGUA],
            ['file', file],
            ['UUID', this.globalvar.getUUID()],
            ['DEBUG_PROGRESS', 1],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'importPreflightCompoSantalucia'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssociazioniSANTALUCIA() {
        const url =
            defaults.INPRINT_API + 'pers/santalucia/' + 'getAssociazioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<PreferenzaCatalogo[]>(
                url,
                parametri,
                'getAssociazioni'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doAssociazioneSANTALUCIA(catLT: string, colPL: string) {
        const url =
            defaults.INPRINT_API + 'pers/santalucia/' + 'doAssociazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
            ['CAT_LT', catLT],
            ['COL_PLIST', colPL],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAssociazione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociazioneSANTALUCIA(catLT: string) {
        const url =
            defaults.INPRINT_API + 'pers/santalucia/' + 'delAssociazione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const cmb_profilo = this.globalvar.getCMB_PROFILO();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['CMB_PROFILO', cmb_profilo],
            ['ID_CATALOGO', catalogoAttivo],
            ['CAT_LT', catLT],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delAssociazione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* MACRO
    async getTestateMacro() {
        const url = defaults.INPRINT_API + 'getMacroTestate.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<macroTestateStruct[]>(
                url,
                parametri,
                'getMacroTestate'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getTestata(codice_testata: string) {
        const url = defaults.INPRINT_API + 'getMacroTestata.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MACRO', codice_testata],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getMacroTestata')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async nuovaMacroTestata(macro_payload: macroTestateStruct) {
        const url = defaults.INPRINT_API + 'doNuovaMacroTestata.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['TMACRO_PAYLOAD', JSON.stringify(macro_payload)],
            ['UUID', uuid],
        ]);
        console.log(JSON.stringify(macro_payload));
        return this.dataManager
            .dataRequestRitornoCodice<any>(
                url,
                parametri,
                'doNuovaMacroTestata'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    editTestataMacro(
        codice_macro: string,
        des: string,
        diml: number,
        dima: number,
        dimp: number,
        colonne: number,
        codalt: string
    ) {
        const url = defaults.INPRINT_API + 'doModificaTestataMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODICE_MACRO', codice_macro],
            ['DESCRIZIONE_MACRO', des],
            ['DIML', diml],
            ['DIMA', dima],
            ['DIMP', dimp],
            ['COLONNE_LISTINO', colonne],
            ['CODALT', codalt],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<any>(
                url,
                parametri,
                'doModificaTestataMacro'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    copiaTestataMacro(
        cod_start: string,
        cod_end: string,
        des_end: string,
        codalt_end: string,
        diml_end: number,
        dima_end: number,
        dimp_end: number
    ) {
        const url = 'doCopiaMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['COD_MACRO_START', cod_start],
            ['COD_MACRO_END', cod_end],
            ['DES_MACRO_END', des_end],
            ['CODALT_MACRO_END', codalt_end],
            ['DIML_MACRO_END', diml_end],
            ['DIMA_MACRO_END', dima_end],
            ['DIMP_MACRO_END', dimp_end],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return new Observable((observer) => {
            this.dataManager
                .dataRequestAPI(defaults.INPRINT_API, url, parametri, 1, '')
                .subscribe((completamento) => {
                    if (completamento) {
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                });
        });
    }

    async getMacroRighe(cod_macro: string) {
        const url = defaults.INPRINT_API + 'getMacroRighe.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MACRO', cod_macro],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<righeMacroStruct[]>(
                url,
                parametri,
                'getMacroRighe'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async nuovaMacroRiga(
        macroRiga_payload: righeMacroStruct[],
        cod_macroT: string
    ) {
        const url = defaults.INPRINT_API + 'doNuovaMacroJSON.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['RMACRO_PAYLOAD', JSON.stringify(macroRiga_payload)],
            ['UUID', uuid],
            ['COD_MACRO', cod_macroT],
        ]);
        parametri.forEach((value, key) => {
            console.log(value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getMacroRighe')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async eliminaMacroTestata(cod_macro: string) {
        const url = defaults.INPRINT_API + 'doCancellaMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_MACRO', cod_macro],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async simulaMacro(cod_macro: string, cod_listino: string) {
        const url = defaults.INPRINT_API + 'simulatoreMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_MACRO', cod_macro],
            ['COD_LISTINO', cod_listino],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<simulatoreStruct[]>(
                url,
                parametri,
                'doSimulaMacro'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async deleteRigaMacro(cod_macro: string, riga_macro: string) {
        const url = defaults.INPRINT_API + 'doCancellaRigaMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_MACRO', cod_macro],
            ['RIGA_MACRO', riga_macro],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delRigaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doAbilitaMacro(codice_macro: string) {
        const url = defaults.INPRINT_API + 'doAbilitaMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_MACRO', codice_macro],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAbilitaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doDisabilitaMacro(codice_macro) {
        const url = defaults.INPRINT_API + 'doDisabilitaMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_MACRO', codice_macro],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAbilitaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    doDisabilitaTutteMacro() {
        const url = defaults.INPRINT_API + 'doDisabilitaTutteLeMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doDisabilitaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    doAbilitaTutteMacro() {
        const url = defaults.INPRINT_API + 'doAbilitaTutteLeMacro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAbilitaMacro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* COMPOSIZIONI
    async getComposizioniT() {
        const url = defaults.INPRINT_API + 'getCompoTestate.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<composizioniStruct[]>(
                url,
                parametri,
                'getCompoTestate'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doNuovaComposizioneT(TC_payload: composizioniStruct) {
        const url = defaults.INPRINT_API + 'doNuovaCompTestata.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['TCOMPO_PAYLOAD', JSON.stringify(TC_payload)],
            ['UUID', uuid],
        ]);
        parametri.forEach((value, key) => {
            console.log('key: ' + key + ' value: ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNuovaCompTestata')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doCopiaComposizione(
        cod_start: string,
        cod_end: string,
        des_end: string,
        diml_end: number,
        dima_end: number,
        dimp_end: number
    ) {
        const url = defaults.INPRINT_API + 'doCopiaComposizione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['COD_COMPO_START', cod_start],
            ['COD_COMPO_END', cod_end],
            ['DES_COMPO_END', des_end],
            ['DIML_COMPO_END', diml_end],
            ['DIMA_COMPO_END', dima_end],
            ['DIMP_COMPO_END', dimp_end],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        parametri.forEach((value, key) => {
            console.log('key: ' + key + ' value: ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCopiaComposizione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getComposizioneT(num_ordine: string) {
        const url = defaults.INPRINT_API + 'getCompoTestata.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_COMPO', num_ordine],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<composizioniStruct[]>(
                url,
                parametri,
                'getCompoTestata'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delComposizioneT(codice_compo: string) {
        const url = defaults.INPRINT_API + 'doCancellaTestataCompo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', this.globalvar.getUUID()],
            ['CODICE_COMPO', codice_compo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<composizioniStruct[]>(
                url,
                parametri,
                'doCancellaTestataCompo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getComposizioniR(codice_compo: string) {
        const url = defaults.INPRINT_API + 'getCompoRighe2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_COMPO', codice_compo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<righeComposizioni[]>(
                url,
                parametri,
                'getCompoRighe'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setComposizioniR(
        codice_compo: string,
        R_payload: righeComposizioni[]
    ) {
        const url = defaults.INPRINT_API + 'doNuovaCompoJSON2.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['RCOMPO_PAYLOAD', JSON.stringify(R_payload)],
            ['UUID', uuid],
            ['CODICE_COMPO', codice_compo],
        ]);

        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'doNuovaCompoJSON')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getDesCompo(valori: desCompoStruct) {
        const url = defaults.INPRINT_API + 'getDescrizioneArticoloCompo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODART', valori.CODART],
            ['DES_FIELD', valori.DES_FIELD],
            ['COD_COMPO', valori.COD_COMPO],
            ['RIGA_COMPO', valori.RIGA_COMPO],
        ]);
        // // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice<any[]>(
                url,
                parametri,
                'getDescrizioneArticoloCompo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setDesCompo(valori: desCompoStruct) {
        const url = defaults.INPRINT_API + 'setDescrizioneArticoloCompo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODART', valori.CODART],
            ['DES_FIELD', valori.DES_FIELD],
            ['DESCRIZIONE_ARTICOLO', valori.DESCRIZIONE_ARTICOLO],
            ['COD_COMPO', valori.COD_COMPO],
            ['RIGA_COMPO', valori.RIGA_COMPO],
        ]);
        // // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice<any>(
                url,
                parametri,
                'setDescrizioneArticoloCompo'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* LOG

    async getNotifiche(
        limite: number,
        codice_log: string,
        ordinamento: string
    ) {
        const url = defaults.INPRINT_API + 'getNotifiche.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['LIMITE', limite],
            ['CODICE_LOG', codice_log],
            ['ORDINAMENTO', ordinamento],
            ['MODO', 1],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<logStruct[]>(
                url,
                parametri,
                'getNotifiche'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getCodiciLog() {
        const url = defaults.INPRINT_API + 'getCodiciNotifiche.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<codiciLogStruct[]>(
                url,
                parametri,
                'getCodiciNotifiche'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* notifiche
    async notifyEngage() {
        const url = defaults.INPRINT_API + 'notifyEngage.php';
        const uuid = this.globalvar.getUUID();
        const idNotifiche = 'AIzaSyDCZmXw2hty4YHab7eAy0EKh22Z3QZuSlo';
        const parametri = new Map<string, any>([
            ['ID_NOTIFICHE', idNotifiche],
            ['UUID', uuid],
            ['APPID', defaults.APPID],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'notifyEngage')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* DEEPSPACE
    //* modelli

    async getModelli() {
        const url = defaults.DEEPSPACE_API + 'getModelli.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<modelliStruct[]>(url, par, 'getModelli')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setModello(modello: modelliStruct) {
        const url = defaults.DEEPSPACE_API + 'setModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', modello.CODMOD],
            ['DES', modello.DES],
            ['DATA1', modello.DATA1],
            ['DATA2', modello.DATA2],
            ['DATA3', modello.DATA3],
            ['DATA4', modello.DATA4],
            ['DATA5', modello.DATA5],
            ['INITVAR', modello.INITVAR],
            ['ABILITA', modello.ABILITA],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async modModello(codmod: string, field: string, value: string) {
        const url = defaults.DEEPSPACE_API + 'modModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
            ['FIELD', field],
            ['VALUE', value],
        ]);
        par.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'modModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    // async getArtNeutri() {
    //     // ! ATTENZIONE!! MANDA IN CRASH IL WEB se troppi dati
    //     const url = defaults.DEEPSPACE_API + 'getArtNeutri.php';
    //     const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
    //     const par = new Map<string, any>([
    //         ['APPID', defaults.APPID],
    //         ['ID_CATALOGO', catalogoAttivo],
    //     ]);
    //     return this.dataManager
    //         .dataRequestRitornoCodice<artNeutriStruct[]>(
    //             url,
    //             par,
    //             'getArtNeutri'
    //         )
    //         .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
    //         .toPromise();
    // }

    async getArtNeutriSearch(searchText: string) {
        const url = defaults.DEEPSPACE_API + 'getArtNeutriSearch.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['SEARCHART', searchText],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<artNeutriStruct[]>(
                url,
                par,
                'getArtNeutriSearch'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setModelliArticolo(codmod: string, codart: string) {
        const url = defaults.DEEPSPACE_API + 'setModArt.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setModArt')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delModelliArticolo(codmod: string, codart: string) {
        const url = defaults.DEEPSPACE_API + 'delModArt.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'delModArt')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssocModArt(codmod: string) {
        const url = defaults.DEEPSPACE_API + 'getModArt.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<artAssocStruct[]>(url, par, 'getModArt')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setAssocModVarOpz(
        codmod: string,
        codvar: string,
        codopz: string,
        opzscope: string,
        opzcontext: string
    ) {
        const url = defaults.DEEPSPACE_API + 'setValModOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
            ['OPZSCOPE', opzscope],
            ['OPZCONTEXT', opzcontext],
        ]);
        par.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'getValModOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssocModVarOpz(codmod: string) {
        const url = defaults.DEEPSPACE_API + 'getValModOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<varModOpzStruct[]>(
                url,
                par,
                'getValModOpz'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssocModVarOpz(codmod: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'delValModOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'delValModOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getModello(cod_mod: string) {
        const url = defaults.DEEPSPACE_API + 'getModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_mod],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<modelliVariantiStruct[]>(
                url,
                par,
                'getModello'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    getAttivazioniVarOpzModello(cod_mod: string, codvar: string) {
        const url = defaults.DEEPSPACE_API + 'getAttivazioniVarOpzModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_mod],
            ['CODVAR', codvar],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<varopzStruct[]>(
                url,
                par,
                'getAttivazioniVarOpzModello'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    setAttivazioniVarOpz(cod_mod: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'setAttivazioniVarOpzModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_mod],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setAttivazioniVarOpzModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    delAttivazioniVarOpzModello(
        cod_mod: string,
        codvar: string,
        codopz: string
    ) {
        const url = defaults.DEEPSPACE_API + 'delAttivazioniVarOpzModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_mod],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'delAttivazioniVarOpzModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    setInitVarModello(cod_mod: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'setInitVarModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_mod],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setInitVarModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setArticoloNelModello(cod_modello: string, codart: string) {
        const url = defaults.DEEPSPACE_API + 'doAttivaNeutroModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_modello],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'doAttivaNeutroModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delArticoloNelModello(cod_modello: string, codart: string) {
        const url = defaults.DEEPSPACE_API + 'doDisattivaNeutroModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', cod_modello],
            ['CODART', codart],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'doDisattivaNeutroModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* varianti
    async getVarianti() {
        const url = defaults.DEEPSPACE_API + 'getVarianti.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<variantiStruct[]>(url, par, 'getVarianti')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getVariante(codvar: string) {
        const url = defaults.DEEPSPACE_API + 'getVarianteOpzioni.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', codvar],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<opzioniStruct[]>(
                url,
                parametri,
                'getVarianteOpzioni'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async nuovaVariante(v_payload: variantiStruct) {
        const url = defaults.DEEPSPACE_API + 'doNuovaVariante.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['VARIANTE_PAYLOAD', JSON.stringify(v_payload)],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNuovaVariante')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editVariante(codvar: string, campo_var: string, valore: string) {
        const url = defaults.DEEPSPACE_API + 'doModVariante.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODVAR', codvar],
            ['CAMPO_VAR', campo_var],
            ['VALORE', valore],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doModVariante')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async ordinaVarianti(ord_payload: any) {
        const url = defaults.DEEPSPACE_API + 'insPosVarCol.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['ORD_PAYLOAD', JSON.stringify(ord_payload)],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'insPosVarCol')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getVarArtOrd(codart: string) {
        const url = defaults.DEEPSPACE_API + 'getValArtOrd.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice<varArtOrdStruct[]>(
                url,
                parametri,
                'getValArtOrd'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setVarArtOrd(payload: any) {
        const url = defaults.DEEPSPACE_API + 'setValArtOrd.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['ORD_PAYLOAD', JSON.stringify(payload)],
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setValArtOrd')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getVariantiAssociateArticolo(codart: string) {
        const url = defaults.DEEPSPACE_API + 'getValCodOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice<varCodOpzStruct[]>(
                url,
                parametri,
                'getValCodOpz'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setVariantiAssociateArticolo(dati: varCodOpzStruct) {
        const url = defaults.DEEPSPACE_API + 'setValCodOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', dati.CODART],
            ['CODVAR', dati.CODVAR],
            ['CODOPZ', dati.CODOPZ],
            ['OPZSCOPE', dati.OPZSCOPE],
            ['OPZCONTEXT', dati.OPZCONTEXT],
            ['ORD', dati.ORD] /* Funzione futura, per ora passa 1 */,
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setValCodOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delVariantiAssociateArticolo(
        codart: string,
        codvar: string,
        codopz: string
    ) {
        const url = defaults.DEEPSPACE_API + 'delValCodOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        // this.check(parametri);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delValCodOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* boh
    async updateOrdinamento(ord_payload: ordinamentoStruct[]) {
        const url = defaults.DEEPSPACE_API + 'updateVarOrdModello.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['ORD_PAYLOAD', JSON.stringify(ord_payload)],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'updateVarOrdModello')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editCV(cod_v: string, col_o: string) {
        const url = defaults.DEEPSPACE_API + 'doModColVariante.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', cod_v],
            ['COLOPZ', col_o],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doModColVariante')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async insCV(cod_v: string, cod_o: string, col: number, val: string) {
        const url = defaults.DEEPSPACE_API + 'doInsModOpzVal.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
            ['CODVAR', cod_v],
            ['CODOPZ', cod_o],
            ['COL', col],
            ['VALORE', val],
        ]);
        // parametri.forEach((value, key) => {
        //   console.log(key + ' = ' + value);
        // });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doInsModOpzVal')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAttivazioniVariantiArticoli(codart: string, codvar: string) {
        const url = defaults.DEEPSPACE_API + 'getAttivazioniVarOpzNeutro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
            ['CODVAR', codvar],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<varopzStruct[]>(
                url,
                par,
                'getAttivazioniVarOpzNeutro'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setAttivazioniNeutro(codart: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'setAttivazioniVarOpzNeutro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setAttivazioniVarOpzNeutro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* opzioni

    async nuovaOpzione(
        codopz: string,
        codvar: string,
        desopz: string,
        material: string,
        data_material: string
    ) {
        const url = defaults.DEEPSPACE_API + 'doNuovaOpzione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['UUID', uuid],
            ['ID_CATALOGO', catalogoAttivo],
            ['TRADGEST', ''],
            ['CODOPZ', codopz],
            ['CODVAR', codvar],
            ['DESOPZ', desopz],
            ['MATERIAL', material],
            ['DATA_MATERIAL', data_material],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNuovaOpzione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editOpzione(
        cod_o: string,
        cod_v: string,
        campo_o: string,
        valore_o: string
    ) {
        const url = defaults.DEEPSPACE_API + 'doModificaOpzione.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['UUID', uuid],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODOPZ', cod_o],
            ['CODVAR', cod_v],
            ['CAMPO_OPZ', campo_o],
            ['VALORE_OPZ', valore_o],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doModificaOpzione')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editInsValoreOpzione(
        cod_o: string,
        cod_v: string,
        col: string,
        val: string
    ) {
        const url = defaults.INPRINT_API + 'doInsModOpzVal.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['UUID', uuid],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODOPZ', cod_o],
            ['CODVAR', cod_v],
            ['COL', col],
            ['VALORE', val],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doInsModOpzVal')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* associazioni

    async doAssociaVarColOpz(
        codopz: string,
        codvar: string,
        colonna_plist: string,
        opzscope: string,
        opzcontext: string
    ) {
        const url = defaults.DEEPSPACE_API + 'doAssociaVarColOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODOPZ', codopz],
            ['CODVAR', codvar],
            ['COLONNA_PLIST', colonna_plist],
            ['OPZSCOPE', opzscope],
            ['OPZCONTEXT', opzcontext],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doAssociaVarColOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociaVarColOpz(
        codopz: string,
        codvar: string,
        colonna_plist: string
    ) {
        const url = defaults.DEEPSPACE_API + 'doDelAssociaVarColOpz.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODOPZ', codopz],
            ['CODVAR', codvar],
            ['COLONNA_PLIST', colonna_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doDeAssociaVarColOpz')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssociaCol(colonna_plist: string) {
        const url = defaults.DEEPSPACE_API + 'getAssociaColonna.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COLONNA_PLIST', colonna_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<valColOpz[]>(
                url,
                parametri,
                'getAssociaColonna'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getAssociaColVar(codvar: string, colonna_plist: string) {
        const url = defaults.DEEPSPACE_API + 'getAssociaColVar.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR', codvar],
            ['COLONNA_PLIST', colonna_plist],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<colvarStruct[]>(
                url,
                parametri,
                'getAssociaColVar'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* derive

    async setColDeriva(
        col: string,
        codvar_orig: string,
        codopz_orig: string,
        codvar_deriva: string,
        codopz: string
    ) {
        const url = defaults.DEEPSPACE_API + 'setColDeriva.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COLONNA_PLIST', col],
            ['CODVAR_ORIG', codvar_orig],
            ['CODOPZ_ORIG', codopz_orig],
            ['CODVAR_DERIVA', codvar_deriva],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setColDeriva')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getColDerive(col: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'getColDeriva.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODVAR_ORIG', codvar],
            ['CODOPZ_ORIG', codopz],
            ['COLONNA_PLIST', col],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<deriveStruct[]>(
                url,
                parametri,
                'getColDeriva'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delColDeriva(
        col: string,
        codvar_orig: string,
        codopz_orig: string,
        codvar_deriva: string,
        codopz: string
    ) {
        const url = defaults.DEEPSPACE_API + 'delColDeriva.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COLONNA_PLIST', col],
            ['CODVAR_ORIG', codvar_orig],
            ['CODOPZ_ORIG', codopz_orig],
            ['CODVAR_DERIVA', codvar_deriva],
            ['CODOPZ', codopz],
        ]);
        parametri.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'delColDeriva')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* articoli

    async getArticoli() {
        const url = defaults.DEEPSPACE_API + 'getArtNeutriDeep.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<articoliDeepStruct[]>(
                url,
                par,
                'getArtNeutriDeep'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getArticoliAssociati(cod_menu: any) {
        const url = defaults.DEEPSPACE_API + 'getMenuArticoliAssociati.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MENU', cod_menu],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<articoliAssociatiStruct[]>(
                url,
                par,
                'getMenuArticoliAssociati'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getArticoliAssociatiModelli(cod_modello: string, modo: string) {
        const url = defaults.DEEPSPACE_API + 'getArticoliAssociatiModelli.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MODELLO', cod_modello],
            ['MODO', modo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<articoliModelliStruct[]>(
                url,
                par,
                'getArticoliAssociatiModelli'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getValModOrd(codmod: string) {
        const url = defaults.DEEPSPACE_API + 'getValModOrd.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODMOD', codmod],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<varOrdStruct[]>(url, par, 'getValModOrd')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setValModOrd(ord_payload: any) {
        const url = defaults.DEEPSPACE_API + 'setValModOrd.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['ORD_PAYLOAD', JSON.stringify(ord_payload)],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setValModOrd')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setInitArticolo(codart: string, codvar: string, codopz: string) {
        const url = defaults.DEEPSPACE_API + 'setInitValArticolo.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODART', codart],
            ['CODVAR', codvar],
            ['CODOPZ', codopz],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'setInitValArticolo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* menu

    async getMenu() {
        const url = defaults.DEEPSPACE_API + 'getMenu.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<menuStruct[]>(url, par, 'getMenu')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async addMenu(padre: number, des: string) {
        const url = defaults.DEEPSPACE_API + 'addMenu.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['PADRE', padre],
            ['DES', des],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'addMenu')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delMenu(cod_menu: number) {
        const url = defaults.DEEPSPACE_API + 'delMenu.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MENU', cod_menu],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'delMenu')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setAssociazioneMenu(cod_menu: number, cod_neutro: string) {
        const url = defaults.DEEPSPACE_API + 'addMenuAssociazioneNeutro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_MENU', cod_menu],
            ['COD_NEUTRO', cod_neutro],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'addMenuAssociazioneNeutro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async delAssociazioneMenu(cod_neutro: string) {
        const url = defaults.DEEPSPACE_API + 'delMenuAssociazioneNeutro.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const par = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_NEUTRO', cod_neutro],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, par, 'delMenuAssociazioneNeutro')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* CODIFICATORE
    //* tipologie
    async getTestateTipologie() {
        const url = defaults.INPRINT_API + 'getTipologieCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<testateTipologie[]>(
                url,
                parametri,
                'getTipologieCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doTestataTipologia(nome_tipologia: string) {
        const url = defaults.INPRINT_API + 'doNuovaTipologiaCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['NOME_TIPOLOGIA', nome_tipologia],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doNuovaTipologiaCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getTipologia(cod_tipologia: string) {
        const url = defaults.INPRINT_API + 'getTipologiaCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_TIPOLOGIA', cod_tipologia],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<tipologiaStruct[]>(
                url,
                parametri,
                'getTipologiaCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async copiaTipologia(cod_tipo_start: string, cod_tipo_end: string) {
        const url = defaults.INPRINT_API + 'doCopiaTipologieCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['COD_TIPOLOGIE_START', cod_tipo_start],
            ['COD_TIPOLOGIE_END', cod_tipo_end],
        ]);
        // parametri.forEach((value, key) =>{
        //   console.log(key + ' : ' + value)
        // })
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doCopiaTipologieCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doNuovaRigaTipologia(
        cod_tipologia: string,
        cod: string,
        des: string,
        valore_in_codice: string,
        valore_in_descrizione: string
    ) {
        const url = defaults.INPRINT_API + 'doNuovaRigaTipologia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_TIPOLOGIA', cod_tipologia],
            ['CODICE', cod],
            ['DESCRIZIONE', des],
            ['VALORE_IN_CODICE', valore_in_codice],
            ['VALORE_IN_DESCRIZIONE', valore_in_descrizione],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doNuovaRigaTipologia')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getRigaTipologia(cod_tipologia: string, cod_riga: string) {
        const url = defaults.INPRINT_API + 'getRigaTipologia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_TIPOLOGIA', cod_tipologia],
            ['CODICE_RIGA', cod_riga],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<rigaTipologieStruct[]>(
                url,
                parametri,
                'doNuovaRigaTipologia'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async cancellaRigaTipologia(cod_tipologia: string, cod_riga: string) {
        const url = defaults.INPRINT_API + 'doCancellaRigaTipologia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_TIPOLOGIA', cod_tipologia],
            ['CODICE', cod_riga],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaRigaTipologia')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async modificaRigaTipologia(
        cod_tipologia: string,
        codice: string,
        descrizione: string,
        valore_in_codice: string,
        valore_in_descrizione: string
    ) {
        const url = defaults.INPRINT_API + 'doModificaRigaTipologia.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_TIPOLOGIA', cod_tipologia],
            ['CODICE', codice],
            ['DESCRIZIONE', descrizione],
            ['VALORE_IN_CODICE', valore_in_codice],
            ['VALORE_IN_DESCRIZIONE', valore_in_descrizione],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doModificaRigaTipologia')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* regole
    async nuovaRegola(cod_regola: string, regola_string: string[]) {
        const url = defaults.INPRINT_API + 'doNuovaRegolaCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_REGOLA', cod_regola],
            ['REGOLA_STRING', JSON.stringify(regola_string)],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doNuovaRegolaCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async cancellaRegola(cod_regola: string) {
        const url = defaults.INPRINT_API + 'doCancellaRegolaCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_REGOLA', cod_regola],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doCancellaRegolaCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* codifica
    async getCodiciCodificatore(cod_regola: string) {
        const url = defaults.INPRINT_API + 'getCodiciCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_REGOLA', cod_regola],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<codificatoreStruct[]>(
                url,
                parametri,
                'getCodiciCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async creaCodiceCodificatore(codice_regola: string, row_data: string) {
        const url = defaults.INPRINT_API + 'doCreaCodiceCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_REGOLA', codice_regola],
            ['ROW_DATA', row_data],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doCreaCodiceCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async importaRegolaCodificatore(codice_regola: string) {
        const url = defaults.INPRINT_API + 'doImportaRegolaCodificatore.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['CODICE_REGOLA', codice_regola],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(
                url,
                parametri,
                'doImportaRegolaCodificatore'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* CHANGELOG
    async getChangelog() {
        const url = defaults.INPRINT_API + 'getChangeLog.php';

        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['NUMERO_VERSIONE', 2],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<changeLogStruct[]>(
                url,
                parametri,
                'getChangeLog'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* FILESYSTEM
    //* imports & exports
    async getImports() {
        const url = defaults.INPRINT_API + 'getImportDir.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
        ]);
        console.log(catalogoAttivo);
        return this.dataManager
            .dataRequestRitornoCodice<reportStruct[]>(
                url,
                parametri,
                'getImportDir'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getExports() {
        const url = defaults.INPRINT_API + 'getExportDir.php';
        const catalogoAttivo = this.globalvar.getIdCatalogoAttivo();
        const uuid = this.globalvar.getUUID();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', catalogoAttivo],
            ['UUID', uuid],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<reportStruct[]>(
                url,
                parametri,
                'getExportDir'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async purge() {
        const url = defaults.INPRINT_API + 'doPurgeDownloadFolder.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getExportDir')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* vault
    async checkVault() {
        const url = defaults.VAULT_API + 'statusVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['NOME_CATALOGO', nomeCatalogo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'getVaultDirContent')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getContentVault(directory: string) {
        const url = defaults.VAULT_API + 'getVaultDirContent.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['INI', nomeCatalogo],
            ['DIRECTORY', directory],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<vaultStruct[]>(
                url,
                parametri,
                'getVaultDirContent'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async uploadVault(file: File, path: string) {
        const url = defaults.VAULT_API + 'uploadVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['file', file],
            ['PATH', path],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'uploadVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async createFolderVault(path: string) {
        const url = defaults.VAULT_API + 'doCreaDirectoryVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['PATH', path],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCreaDirectoryVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async deleteFileVault(pathAndFileName: string) {
        const url = defaults.VAULT_API + 'doCancellaFileVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['PATH', pathAndFileName],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaFileVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async deleteFolderVault(path: string) {
        const url = defaults.VAULT_API + 'doCancellaDirVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['PATH', path],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCancellaDirVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async downloadVault(path: string) {
        const url = defaults.VAULT_API + 'doDownloadVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['PATH', path],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doDownloadVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async copyFileVault(from_path: string, to_path: string) {
        const url = defaults.VAULT_API + 'doCopiaFileVault.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
            ['FROM_PATH', from_path],
            ['TO_PATH', to_path],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doCopiaFileVault')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getConfigVault() {
        const url = defaults.VAULT_API + 'getVaultConfiguration.php';
        const nomeCatalogo = this.globalvar.getDBNomeCatalogo();
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['INI', nomeCatalogo],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<vaultConfig>(
                url,
                parametri,
                'getVaultConfiguration'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* AVATAR
    async uploadAvatar(filenameavatar: string, fileset: File) {
        const url = defaults.INPRINT_API + 'uploadAvatar.php';
        const parametri = new Map<string, any>([
            ['APPID', defaults.APPID],
            ['FILENAMEAVATAR', filenameavatar],
            ['fileset', fileset],
        ]);
        parametri.forEach((value, key) => {
            console.log(key + ' : ' + value);
        });
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'uploadAvatar')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    //* PROJECT MILLA
    async setFooterMail(footerTxt: string) {
        const url = defaults.MILLA_SERVER + 'setMailFooter.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['FOOTER', footerTxt],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'setMailFooter')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    getFooterMail() {
        const url = defaults.MILLA_SERVER + 'getMailFooter.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<string>(url, parametri, 'getFooter')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getUtentiAttivi() {
        const url = defaults.MILLA_SERVER + 'getUserAttivi.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<millaUsers[]>(
                url,
                parametri,
                'getUserAttivi'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async editListinoAssegnato(
        listino: string,
        code: string,
        data_validita: number
    ) {
        const url = defaults.MILLA_SERVER + 'doListinoChange.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['LISTINO_DA_CAMBIARE', listino],
            ['CODE', code],
            ['DATA_VALIDITA', data_validita],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doListinoChange')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async removeActivation(code: string) {
        const url = defaults.MILLA_SERVER + 'doRemoveUser.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['CODE', code],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doRemoveUser')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async millaActivation(
        mail: string,
        name: string,
        surname: string,
        listino: string,
        lingua: string,
        rest_key: string,
        data_validita: number,
        modo: string
    ) {
        const url = defaults.MILLA_SERVER + 'millaActivation.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['ID_UTENTE', 'site'],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['NAME', name],
            ['SURNAME', surname],
            ['MAIL', mail],
            ['LISTINO', listino],
            ['LINGUA', lingua],
            ['REST_KEY', rest_key],
            ['DATA_VALIDITA_LISTINO', data_validita],
            ['TIME_AGREE', 0],
            ['MODO', modo],
        ]);
        parametri.forEach((value, key) => {
            console.log(key, value);
        });
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'millaActivation')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doRestKey() {
        const url = defaults.REST_API + 'doRestKey.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['APPID', defaults.APPID],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice(url, parametri, 'doRestKey')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async checkRestExsist() {
        const url = defaults.REST_API + 'checkRestExsist.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['APPID', defaults.APPID],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<number>(url, parametri, 'checkRestExsist')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setWebsiteUrl(website_url: string) {
        const url = defaults.MILLA_SERVER + 'setUrlSite.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['SITE', website_url],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setUrlSite')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getWebsiteUrl() {
        const url = defaults.MILLA_SERVER + 'getUrlSite.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getUrlSite')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async doEditUser(
        nome: string,
        cognome: string,
        mail: string,
        code: string
    ) {
        const url = defaults.MILLA_SERVER + 'doModUser.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['NOME', nome],
            ['COGNOME', cognome],
            ['MAIL', mail],
            ['CODE', code],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'doModUser')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getUrlSite() {
        const url = defaults.MILLA_SERVER + 'getUrlSite.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getUrlSite')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getSalesPage() {
        const url = defaults.MILLA_SERVER + 'getSalesPage.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getSalesPage')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getSaldo() {
        const url = defaults.MILLA_SERVER + 'getSaldo.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getSaldo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getCpName() {
        const url = defaults.MILLA_SERVER + 'getCpName.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getCpName')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setCpName(cp_name: string) {
        const url = defaults.MILLA_SERVER + 'setCpName.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['CP_NAME', cp_name],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setCpName')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async checkMailExist(mail: string) {
        const url = defaults.MILLA_SERVER + 'checkMailExsist.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['MAIL', mail],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'checkMailExsist')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getMailAttivazione() {
        const url = defaults.MILLA_SERVER + 'getReplyTo.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getReplyTo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getLayoutMail() {
        const url = defaults.MILLA_SERVER + 'getCustomMail.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'getCustomMail')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setMailAttivazione(mail: string) {
        const url = defaults.MILLA_SERVER + 'setReplyTo.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['REPLY_TO', mail],
        ]);
        // parametri.forEach((value, key)=>{
        //   console.log(key + ' -> ' + value)
        // })
        console.log('mail da attivare', mail);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setReplyTo')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }
    async setLayoutMail(custom_mail: any) {
        const url = defaults.MILLA_SERVER + 'setCustomMail.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['CUSTOM_MAIL', JSON.stringify(custom_mail)],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setCustomMail')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async getScontiUtente(mail: string, code: string) {
        const url = defaults.MILLA_SERVER + 'getSconti.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['MAIL', mail],
            ['CODE', code],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<scontiUtenteMilla[]>(
                url,
                parametri,
                'getSconti'
            )
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }

    async setScontoUtente(
        mail: string,
        code: string,
        sconto_field: string,
        qta_sconto: number
    ) {
        const url = defaults.MILLA_SERVER + 'setScontoUser.php';
        const parametri = new Map<string, any>([
            ['ID_CATALOGO', this.globalvar.getIdCatalogoAttivo()],
            ['REST_KEY', this.globalvar.getRestKey()],
            ['MAIL', mail],
            ['CODE', code],
            ['SCONTO_FIELD', sconto_field],
            ['QTA_SCONTO', qta_sconto],
        ]);
        return this.dataManager
            .dataRequestRitornoCodice<any>(url, parametri, 'setScontoUser')
            .pipe(tap((data) => JSON.parse(JSON.stringify(data))))
            .toPromise();
    }
}
