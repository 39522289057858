<ng-container *ngIf="(isMobile$ | async) === false">
    <ion-split-pane [disabled]="showSideMenu === false" contentId="main-content">
        <ion-menu contentId="main-content">
            <ion-content>
                <ion-list id="inbox-list">
                    <ion-toolbar>
                        <ion-title class="ion-text-center">
                            <div *ngIf="catalogoAttivo === true" class="box">
                                <img class="print-img" src="../assets/png/prclst.png" [hidden]="!image.hidden">
                                <br><br>
                                <ion-text [hidden]="!image.hidden">{{catalogoName}}</ion-text>
                                <div class="double-img" [hidden]="image.hidden">
                                    <img class="print-img" src="../assets/png/prclst.png">
                                    <img class="catalogo-img" [src]="catalogoImg" #image (error)="image.hidden = true"
                                        [hidden]="image.hidden">
                                </div>
                            </div>

                            <br><br><br>

                            <div *ngIf="utenteArrivato === true" class="user-box">
                                <ion-row [hidden]="avatar.hidden">
                                    <ion-col>
                                        <img [src]="'https://api.price-list.it/images/avatar/' +utente.UUID+ '.jpg'"
                                            #avatar (error)="avatar.hidden = true" class="useravatar">
                                    </ion-col>
                                    <ion-col>
                                        <ion-text class="name-txt-left">{{ utente.NOME + ' ' + utente.COGNOME
                                            }}</ion-text>
                                        <br>
                                        <ion-text class="mail-txt-left">{{ utente.EMAIL }}</ion-text>
                                    </ion-col>
                                </ion-row>
                                <ion-row [hidden]="!avatar.hidden">
                                    <ion-col>
                                        <ion-text class="name-txt">{{ utente.NOME + ' ' + utente.COGNOME }}</ion-text>
                                        <br>
                                        <ion-text class="mail-txt">{{ utente.EMAIL }}</ion-text>
                                    </ion-col>
                                </ion-row>

                            </div>

                        </ion-title>
                    </ion-toolbar>
                    <div *ngIf="this.menuPers === false;">
                        <ion-menu-toggle auto-hide="false" *ngFor="let page of navigateNoPers; let i = index">
                            <ion-item mode="ios" routerDirection="root" [routerLink]="[page.url]" lines="none"
                                [routerLinkActive]="['sidemenu-active']">
                                <ion-icon slot="start" [name]="page.icon"></ion-icon>
                                <ion-label>{{ page.title }}</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </div>
                    <div *ngIf="this.menuPers === true;">
                        <ion-menu-toggle auto-hide="false" *ngFor="let page of navigate; let i = index">
                            <ion-item mode="ios" routerDirection="root" [routerLink]="[page.url]" lines="none"
                                (click)="this.common.goTo(page.url)" [routerLinkActive]="['sidemenu-active']">
                                <ion-icon slot="start" [name]="page.icon"></ion-icon>
                                <ion-label>{{ page.title }}</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </div>
                </ion-list>
            </ion-content>
            <ion-row *ngFor="let c of changelog; let i = index">
                <ion-col [hidden]="i > 0">
                    <ion-text class="build-number">{{c.VERSIONE}}</ion-text>
                </ion-col>
            </ion-row>

            <ion-footer class="ion-no-border">
                <ion-toolbar>
                    <ion-grid>
                        <ion-row *ngIf="this.utenteArrivato === true && this.utente.MULTICAT > 1">
                            <ion-col *ngFor="let page of iconsNavigate;">
                                <ion-item mode="ios" color="celle" class="bottomIcons"
                                    [routerLinkActive]="['bottommenu-active']" routerDirection="root"
                                    [routerLink]="[page.url]" lines="none">
                                    <ion-icon [name]="page.icon"></ion-icon>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="this.utenteArrivato === true && this.utente.MULTICAT === 1">
                            <ion-col *ngFor="let page of iconsNavigateNoMulticat;">
                                <ion-item mode="ios" color="celle" class="bottomIcons"
                                    [routerLinkActive]="['bottommenu-active']" routerDirection="root"
                                    [routerLink]="[page.url]" lines="none">
                                    <ion-icon [name]="page.icon"></ion-icon>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                    </ion-grid>
                </ion-toolbar>
            </ion-footer>
        </ion-menu>
        <ion-router-outlet id="main-content">
        </ion-router-outlet>
    </ion-split-pane>
</ng-container>
<ng-container *ngIf="isMobile$ | async">
    <ion-router-outlet id="mobile-content">
    </ion-router-outlet>
    <app-tabs-menu *ngIf="showTabsMenu"></app-tabs-menu>
</ng-container>