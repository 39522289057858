<ion-header class="ion-text-center ion-no-border">
    <ion-toolbar>
        <ion-title>
            Esporta
        </ion-title>
        <ion-icon name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
    </ion-toolbar>
</ion-header>

<ion-content>


    <!-- !! Colonne -->
    <!-- <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="colonneSel" label="Seleziona le colonne" labelPlacement="stacked" mode="ios"
            interface="popover" [multiple]="true">
            <ion-select-option *ngFor="let colonna of colonne">{{colonna}}</ion-select-option>
        </ion-select>
    </ion-item> -->

    <!-- !! Lingue -->
    <!-- <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="lingueSel" label="Seleziona le lingue" labelPlacement="stacked" mode="ios"
            interface="popover" [multiple]="true">
            <ion-select-option *ngFor="let lingua of lingue">{{lingua}}</ion-select-option>
        </ion-select>
    </ion-item> -->

    <!-- !! Modo-->
    <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="modoSel" label="Seleziona cosa vuoi esportare" labelPlacement="stacked" mode="ios"
            interface="popover">
            <ion-select-option value="0">Esporta tutto</ion-select-option>
            <ion-select-option value="1">Esporta per modelli</ion-select-option>
        </ion-select>
    </ion-item>

    <!-- !! Listino -->
    <ion-item mode="ios" lines="none">
        <ion-select [(ngModel)]="listinoSel" label="Seleziona il listino" labelPlacement="stacked" mode="ios"
            interface="popover">
            <ion-select-option *ngFor=" let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
        </ion-select>
    </ion-item>

    <!-- !! Modello/i -->
    <ion-item mode="ios" lines="none" *ngIf="modoSel === '1'">
        <ion-select [(ngModel)]="modelliSel" label="Seleziona modelli" labelPlacement="stacked" mode="ios"
            interface="alert" [multiple]="true">
            <ion-select-option *ngFor="let modello of modelli">{{modello.CODMOD}}</ion-select-option>
        </ion-select>
    </ion-item>

    <!-- !! Model only -->
    <ion-item mode="ios" lines="none" *ngIf="modoSel === '1'">
        <ion-checkbox mode="ios" (ionChange)="modelOnlyCheck($event)">Esporta anche accessori</ion-checkbox>
    </ion-item>

    <!-- !! Error flag -->
    <ion-item mode="ios" lines="none">
        <ion-select mode="ios" label="Errori da esportare" labelPlacement="stacked"
            (ionChange)="errorFlagCheck($event)">
            <ion-select-option value="0">Non esportare errori</ion-select-option>
            <ion-select-option value="1">Esporta CON errori</ion-select-option>
            <ion-select-option value="2">Esporta SOLO errori</ion-select-option>
        </ion-select>
    </ion-item>
</ion-content>

<ion-footer class="ion-text-center ion-no-border">
    <ion-toolbar>
        <ion-button mode="ios" (click)="getSelectedValues()" [disabled]="btnVisible()">Esporta</ion-button>
    </ion-toolbar>
</ion-footer>