/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalVariableService } from './global-variable.service';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { CodificatoreStruttureService } from './codificatore-strutture.service';
import { listiniStruct } from '../strutture/listini.strutture';
import { AlertController } from '@ionic/angular';
import { StringheService } from './stringhe.service';
import { LINGUA } from '../default-values';
import { TranslateService } from '@ngx-translate/core';
import { calcoliStruct } from '../strutture/calcoli.strutture';

@Injectable({
    providedIn: 'root',
})
export class ListiniService {
    catalogoAttivo = null;
    handlerMessage = '';

    private destroy = new Subject();

    constructor(
        private commons: CommonFunctionService,
        private globalVars: GlobalVariableService,
        private codificatore: CodificatoreStruttureService,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {}

    async getListini() {
        const listini = await this.commons.getListini();
        console.log('listini arrivati', listini);
        return listini;
    }

    async getListino(cod_listino: string) {
        const listino = await this.commons.getListino(cod_listino);
        console.log('listino arrivato', listino);
        return listino;
    }

    async getListinoPerPagina(
        cod_listino: string,
        pagina: number,
        step_pagine: number,
        codart: string
    ) {
        const listinoPerPagina = await this.commons.getListinoPerPagina(
            cod_listino,
            pagina,
            step_pagine,
            codart
        );
        console.log('listino per pagina', listinoPerPagina);
        return listinoPerPagina;
    }

    async nuovoListino(
        cod_listino: string,
        des_listino: string,
        valuta_listino: string
    ) {
        const responseNuovoListino = await this.commons.nuovoListino(
            cod_listino,
            des_listino,
            valuta_listino
        );
        console.log('responseNuovoListino', responseNuovoListino);
        const risp = JSON.stringify(responseNuovoListino)
            .split(':')
            .pop()
            .replace('}', '')
            .slice(1, -1);
        // console.log('risposta do nuovo listino', risp);
        if (risp === '200') {
            await this.getListini();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('A004'),
                message: this.translate.instant(risp),
                buttons: ['Ok'],
            });
            await alert.present();
        }
    }

    async setValiditaListino(cod_listino: string, data_validita: number) {
        const responseSetValidita = await this.commons.setValiditaListino(
            cod_listino,
            data_validita
        );
        console.log('ripsosta set validita listino', responseSetValidita);
    }

    async svuotaPrezzi(cod_listino: string) {
        const responseSvuotaPrezzi = await this.commons.svuotaPrezziListino(
            cod_listino
        );
        const risp = JSON.stringify(responseSvuotaPrezzi)
            .split(':')
            .pop()
            .replace('}', '')
            .slice(1, -1);
        // console.log('ripsosta svuota prezzi', risp);
        if (risp === '200') {
            await this.getListini();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('A004'),
                message: this.translate.instant(risp),
                buttons: ['Ok'],
            });
            await alert.present();
        }
    }

    async eliminaListino(cod_listino: string) {
        const responseEliminaListino = await this.commons.eliminaListino(
            cod_listino
        );
        const risp = JSON.stringify(responseEliminaListino)
            .split(':')
            .pop()
            .replace('}', '')
            .slice(1, -1);
        // console.log('risposta elimina listino', risp);
        if (risp === '200') {
            await this.getListini();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('A004'),
                message: this.translate.instant(risp),
                buttons: ['Ok'],
            });
            await alert.present();
        }
    }

    async duplicaListino(
        cod_listino_start: string,
        cod_listino_end: string,
        des_listino_end: string
    ) {
        const responseDuplicaListino = await this.commons.duplicaListino(
            cod_listino_start,
            cod_listino_end,
            des_listino_end
        );
        const risp = JSON.stringify(responseDuplicaListino)
            .split(':')
            .pop()
            .replace('}', '')
            .slice(1, -1);
        // console.log('risposta duplica listino', risp);
        if (risp === '200') {
            await this.getListini();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('A004'),
                message: this.translate.instant(risp),
                buttons: ['Ok'],
            });
            await alert.present();
        }
    }

    async doListinoCalcolatoBg(calcoli_payload: calcoliStruct) {
        const response = await this.commons.doListinoCalcolatoBg(
            calcoli_payload
        );
        console.log('response listino calcolato', response);
        const risp = JSON.stringify(response).split(':').pop().replace('}', '');
        console.log('risp in then', risp);
        if (risp === '"E413"') {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('E001'),
                message: this.translate.instant('E413'),
                buttons: [
                    {
                        text: 'ok',
                    },
                ],
            });
            await alert.present();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: this.translate.instant('A020'),
                message:
                    "Attendere la notifica via e-mail per visualizzarlo dall'area listini",
                buttons: [
                    {
                        text: 'ok',
                    },
                ],
            });
            await (await alert).present();
        }
    }

    async getValuteListino() {
        const valute = await this.commons.getValuteListino();
        return valute;
    }

    async setValuteListino(cod_listino: string, cod_valuta: string) {
        const responseSetValuta = await this.commons.setValuteListino(
            cod_listino,
            cod_valuta
        );
        console.log('response set valuta listino', responseSetValuta);
    }

    //* Listino calcolato per distinta
    async doListinoCalcolatoDistinta(
        cod_distinta: string,
        listino_start: string,
        listino_end: string
    ) {
        const doLCD = await this.commons.doListinoCalcolatoDistinta(
            cod_distinta,
            listino_start,
            listino_end
        );
        console.log('response do listino calcolato per distinta', doLCD);
    }

    //- TESTATE
    async getTListinoDistinta() {
        const getT = await this.commons.getTListinoDistinta();
        return getT;
    }

    async doTListinoDistinta(cod_distinta: string, des_distinta: string) {
        const doT = await this.commons.doTListinoDistinta(
            cod_distinta,
            des_distinta
        );
        console.log('response do testata listino distinta', doT);
    }

    async delTListinoDistinta(cod_distinta: string) {
        const delT = await this.commons.delTListinoDistinta(cod_distinta);
        console.log('reponse del riga distinta', delT);
    }

    async editTListinoDistinta(
        cod_distinta: string,
        field: string,
        value: string
    ) {
        const editT = await this.commons.editTListinoDistinta(
            cod_distinta,
            field,
            value
        );
        console.log('response edit testata distinta', editT);
    }

    //- RIGHE
    async getRListinoDistinta(codT: string) {
        const getR = await this.commons.getRListinoDistinta(codT);
        console.log('righe', getR);
        return getR;
    }

    async doRListinoDistinta(
        cod_distinta: string,
        col_start: string,
        segno: string,
        perc: string,
        col_dest: string,
        arrot: string
    ) {
        const doR = await this.commons.doRListinoDistinta(
            cod_distinta,
            col_start,
            segno,
            perc,
            col_dest,
            arrot
        );
        console.log('repsonse do riga listino distinta', doR);
    }

    async delRListinoDistinta(cod_distinta: string, col_dest: string) {
        const delR = await this.commons.delRListinoDistinta(
            cod_distinta,
            col_dest
        );
        console.log('reponse del riga distinta', delR);
    }

    async editRListinoDistinta(
        cod_distinta: string,
        field: string,
        value: string,
        col_dest: string
    ) {
        const editR = await this.commons.editRListinoDistinta(
            cod_distinta,
            field,
            value,
            col_dest
        );
        console.log('response edit riga distinta', editR);
    }
}
