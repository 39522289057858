<ion-header class="ion-no-border" mode='ios'>
    <ion-toolbar>
        <ion-title>{{this.translate.instant('182')}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-grid *ngIf="this.flag === false;">
        <ion-row class="ion-text-center">
            <ion-col>
                <ion-button mode='ios' color="primary" class="btn-45" text-wrap
                    (click)="importa()">{{this.translate.instant('182')}}</ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
            <ion-col size="12">
                <ion-button mode='ios' color="primary" class="btn-45" text-wrap
                    (click)="esporta()">{{this.translate.instant('183')}}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-grid *ngIf="this.flag === true;">
        <ion-row class="ion-text-center">
            <ion-col size="6">
                <ion-button mode='ios' color="primary" class="btn" text-wrap
                    (click)="upload('prezzi')">{{this.translate.instant('041')}}</ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button mode='ios' color="primary" class="btn" text-wrap
                    (click)="upload('articoli')">{{this.translate.instant('184')}}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
            <ion-col size="6">
                <ion-button mode='ios' color="primary" class="btn" text-wrap
                    (click)="upload('intestazioni')">{{this.translate.instant('185')}}
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button mode='ios' color="primary" class="btn" text-wrap
                    (click)="upload('padreFiglio')">{{this.translate.instant('186')}}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
            <ion-col size="6">
                <ion-button mode='ios' color="primary" class="btn ion-text-wrap" (click)="upload('composizioni')">
                    <p class="wrap-text">{{this.translate.instant('018')}} <br> <span
                            style="font-size: small;">{{this.translate.instant('278')}}</span></p>
                </ion-button>
            </ion-col>
            <ion-col size="6">
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>