<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Modifica riga {{riga.COL_DEST}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="modalCtrl.dismiss()" fill="clear">
            <ion-icon style="float: right;" name="close-outline"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-row>
        <ion-col size="12" text-center>
            <form [formGroup]="fg">
                <ion-item mode="ios">
                    <ion-input label="Colonna di partenza" labelPlacement="stacked"
                        formControlName="COL_START"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-select label="Segno" labelPlacement="stacked" formControlName="SEGNO">
                        <ion-select-option value="+">+</ion-select-option>
                        <ion-select-option value="-">-</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input label="Percentuale" labelPlacement="stacked" formControlName="PERC"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-select label="Arrotondamento" labelPlacement="stacked" formControlName="ARROT">
                        <ion-select-option value="0">Matematico</ion-select-option>
                        <ion-select-option value="1">Eccesso</ion-select-option>
                        <ion-select-option value="2">Difetto</ion-select-option>
                    </ion-select>
                </ion-item>
            </form>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="editR()" color="primary">Crea</ion-button>
    </ion-toolbar>
</ion-footer>