/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, ViewDidEnter, ViewWillEnter } from '@ionic/angular';
import { Subject } from 'rxjs';
// import { printLog } from 'src/app/lib';
import { ImportaService } from 'src/app/servizi/importa.service';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { ControlloFileImportComponent } from '../controllo-file-import/controllo-file-import.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-importa-file',
    templateUrl: './importa-file.component.html',
    styleUrls: ['./importa-file.component.scss'],
})
export class ImportaFileComponent implements OnInit, OnDestroy, ViewWillEnter {
    modalDataResponse: any;
    showBtn: boolean;
    fileToImport: File;

    errorToDisplay: string;

    tipoImportazione;

    sceltaListino;

    url;

    listini: listiniStruct[] = [];
    listinoSelezionato: string;

    showForm: boolean;

    haslink: boolean = false;

    private destroy$ = new Subject();

    constructor(
        public translate: TranslateService,
        private modalCtrl: ModalController,
        private importService: ImportaService,
        private listiniService: ListiniService
    ) {}

    async ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async ionViewWillEnter() {
        this.fileToImport = null;
        this.showForm = true;
        console.log(this.tipoImportazione);
        console.log('file to import = ', this.fileToImport);
        this.listini = await this.listiniService.getListini();
    }

    async ngOnInit() {
        //. Url per excel
        //. Attivazione riga per selezione listino o scaricare il file excel
        switch (this.tipoImportazione) {
            case 'articoli':
                this.url =
                    'https://price-list.tech/importazioni/#file-articoli';
                this.haslink = true;
                break;
            case 'prezzi':
                this.sceltaListino = true;
                // this.url = 'https://api.price-list.it/inPrintapi/importTemplate/prezzi.xlsx';
                break;
            case 'intestazioni':
                this.url =
                    'https://price-list.tech/importazioni/#file-intestazioni';
                this.haslink = true;
                break;
            case 'padreFiglio':
                //this.url = 'https://api.price-list.it/inPrintapi/importTemplate/padreFiglio.xlsx';
                break;
            case 'composizioni':
                // this.url =
                //     'https://price-list.tech/importazioni/#file-intestazioni';
                break;
        }
    }

    async downloadFileEsempio(url) {
        window.open(url, '_blank');
    }

    async close() {
        await this.modalCtrl.dismiss();
        this.ngOnDestroy();
    }

    async prosegui() {
        console.log(this.fileToImport);
        const modal = await this.modalCtrl.create({
            component: ControlloFileImportComponent,
            componentProps: {
                file: this.fileToImport,
                tipoImportazione: this.tipoImportazione,
                codListino: this.listinoSelezionato,
            },
            backdropDismiss: false,
        });
        await modal.present().then(() => {
            this.showForm = false;
        });
        modal.onDidDismiss().then(() => {
            this.ngOnDestroy();
            this.ngOnInit();
            this.fileToImport = null;
            console.log('file to import = ', this.fileToImport);
            this.modalCtrl.dismiss();
        });
    }

    async showButton() {
        if (this.fileToImport !== undefined) {
            if (this.tipoImportazione === 'prezzi') {
                console.log(this.tipoImportazione);
                console.log(this.listinoSelezionato);
                if (this.listinoSelezionato !== undefined) {
                    console.log(this.listinoSelezionato);
                    this.showBtn = true;
                }
            } else {
                this.showBtn = true;
            }
        }
    }

    async selezionaListino($event: any) {
        this.listinoSelezionato = $event.detail.value.COD_LISTINO;
        // console.log('listino selezionato ', this.listinoSelezionato);
        this.showButton();
    }

    async importaFile(e) {
        this.fileToImport = e.target.files[0];
        // console.log('file', this.fileToImport);
        this.showButton();
    }
}
