import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizzazioneSediolitiService {
    constructor(
        private commons: CommonFunctionService,
        private globalVar: GlobalVariableService
    ) {}

    async doImportaPreflightSedioliti(file: File, cod_listino: string) {
        const responseImporta = await this.commons.doImportaPreflightSEDIOLITI(
            file,
            cod_listino
        );
        // console.log('response importa preflight LTFORM', responseImporta);
        console.log(responseImporta['MESSAGGI'].length);
        if (responseImporta['MESSAGGI'].length === 0) {
            // console.log('non ci sono errori, proseguo con l importazione');
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            console.log('FILENAME set', responseImporta['FILE_IMPORT']);
        } else {
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImporta['FILE_IMPORT']
            );
            // console.log('FILENAME set', responseImporta['FILENAME']);
            this.globalVar.setERRORE_IMPORTAZIONE(responseImporta['MESSAGGI']);
            responseImporta['MESSAGGI'].forEach((element) => {
                console.log(element['FLAG_IMPORT']);
                console.log(element['MESSAGGIO']);
                if (element['FLAG_IMPORT'] !== 'C') {
                    // console.log('non ci sono errori critici, posso proseguire');
                } else {
                    // console.log('ci sono errori critici, blocco tutto');
                }
            });
        }
    }
}
