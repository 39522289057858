import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    AlertController,
    ModalController,
    ViewWillEnter,
} from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { ModelliService } from 'src/app/servizi/modelli.service';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { modelliStruct } from 'src/app/strutture/deepspace/modelli.strutture';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

@Component({
    selector: 'app-export-rivenditori',
    templateUrl: './export-rivenditori.component.html',
    styleUrls: ['./export-rivenditori.component.scss'],
})
export class ExportRivenditoriComponent implements OnInit, ViewWillEnter {
    listini: listiniStruct[] = [];
    colonne: string[] = [];
    lingue: string[] = [];
    modo: string[] = [];
    modelli: modelliStruct[] = [];

    listinoSel: string = '';
    colonneSel: string[] = [];
    lingueSel: string[] = ['IT'];
    modoSel: string = '';
    modelliSel: string[] = [];

    modelOnlyChecker: number = 0;
    errorFlagChecker: number = 0;

    hideBtn: boolean = true;

    constructor(
        private ls: ListiniService,
        private pas: PersonalizzazioniArbiService,
        private ps: PreferenzeService,
        private alertCtrl: AlertController,
        private ms: ModelliService,
        public modalCtrl: ModalController
    ) {}
    async ngOnInit() {}

    ionViewWillEnter() {
        this.getListini();
        this.getColonne();
        this.getLingue();
        this.getModelli();
    }

    async getListini() {
        this.listini = await this.ls.getListini();
    }

    async getColonne() {
        let preferenzeCat = await this.ps.getPreferenzeCatalogo();
        let colonne = preferenzeCat.filter(
            (el) => el.COD_PREFERENZA === 'COLONNE_PRICELIST'
        );
        this.colonne = colonne[0].VALORE_PREFERENZA.split(',').sort();
    }

    async getLingue() {
        let preferenzeCat = await this.ps.getPreferenzeCatalogo();
        // console.log('pref', preferenzeCat);
        let lingue = preferenzeCat.filter(
            (el) => el.COD_PREFERENZA === 'CAMPI_LINGUA'
        );
        this.lingue = lingue[0].VALORE_PREFERENZA.split(',').sort();
    }

    async getModelli() {
        this.modelli = await this.ms.getModelli();
        // console.log('modelli arrivati', this.modelli);
    }

    async modelOnlyCheck(e: any) {
        const isChecked = e.detail.checked;
        // console.log('check model only', isChecked);
        if (isChecked) {
            this.modelOnlyChecker = 0;
        } else {
            this.modelOnlyChecker = 1;
        }
    }

    async errorFlagCheck(e: any) {
        const isChecked = e.detail.value;
        console.log('check error flag', isChecked);
        this.errorFlagChecker = isChecked;
    }

    btnVisible(): boolean {
        if (this.modoSel !== '') {
            if (this.modoSel === '0') {
                //. Devo aspettarmi solo che il listino sia selezionato
                if (this.listinoSel !== '') {
                    return false;
                } else {
                    return true;
                }
            } else {
                //. Devo aspettarmi che sia il listino che almeno un modello siano selezionati
                if (this.listinoSel !== '' && this.modelliSel.length != 0) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    async getSelectedValues() {
        const selectedValues = {
            listino: this.listinoSel,
            lingue: this.lingueSel,
            modo: this.modoSel,
            modelli: this.modelliSel.toString(),
        };

        // console.log('Selezioni:', selectedValues);

        if (selectedValues.listino !== '' && selectedValues.modo !== '') {
            // console.log('ho tutti i valori, posso fare export');
            this.doExport();
        } else {
            // console.log('non ho i valori, non proseguo');
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: 'Attenzione!',
                message: 'Bisogna selezionare almeno un valore',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        }
    }

    async doExport() {
        const selectedValues = {
            listino: this.listinoSel,
            lingue: this.lingueSel.toString(),
            modelli: this.modelliSel.toString(),
            model_only: this.modelOnlyChecker,
            error_flag: this.errorFlagChecker,
        };
        // console.log('valori selezionati per api', selectedValues);
        await this.pas.doExportRivenditori(
            selectedValues.listino,
            selectedValues.modelli,
            selectedValues.lingue,
            selectedValues.model_only,
            selectedValues.error_flag
        );
    }
}
