/* eslint-disable */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalVariableService } from './global-variable.service';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';

export interface PreferenzaInPrint {
    CMB_PROFILO: string;
    COD_PREFERENZA: string;
    MODULO: string;
    PRIORITA: string;
    GET_TIPO_GEST: string;
    SET_TIPO_GEST: string;
}

export interface PreferenzaCatalogo {
    COD_PREFERENZA: string;
    VALORE_PREFERENZA: string;
    MODULO: string;
}

@Injectable({
    providedIn: 'root',
})
export class PreferenzeService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    //preferenzeInPrint: BehaviorSubject<PreferenzaInPrint[]> = new BehaviorSubject([]);
    //preferenzeCatalogo: BehaviorSubject<PreferenzaCatalogo[]> = new BehaviorSubject([]);

    catalogoAttivo = null;

    private destroy = new Subject();

    constructor(
        private commons: CommonFunctionService,
        private globalVars: GlobalVariableService
    ) {}

    async getPreferenzeCatalogo() {
        const preferenzeCatalogoArrivateDaApi =
            await this.commons.getPreferenzeCatalogo();
        return preferenzeCatalogoArrivateDaApi;
    }

    async getPreferenzaCatalogo(preferenza: string) {
        const prefArrivata = await this.commons.getPreferenzaCatalogo(
            preferenza
        );
        console.log('preferenza richiesta', preferenza);
        console.log('preferenza arrivata', prefArrivata);
        return prefArrivata;
    }

    async setPreferenzaCatalogo(
        codPreferenza: string,
        valorePreferenza: string,
        modulo: string,
        setTipoGest: string
    ) {
        const preferenzeCatalogoArrivateDaApi =
            await this.commons.setPreferenzaCatalogo(
                codPreferenza,
                valorePreferenza,
                modulo,
                '1',
                setTipoGest
            );
        // console.log('loadPreferenzeCatalogo', preferenzeCatalogoArrivateDaApi);
        return preferenzeCatalogoArrivateDaApi;
    }

    async getPreferenzeInPrint() {
        const preferenzeInPrintArrivateDaApi =
            await this.commons.getPreferenzeInPrint();
        return preferenzeInPrintArrivateDaApi;
    }
}
