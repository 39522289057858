/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ArticoliService } from 'src/app/servizi/articoli.service';
import { IntestazioniService } from 'src/app/servizi/intestazioni.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-copia-intestazione',
    templateUrl: './copia-intestazione.component.html',
    styleUrls: ['./copia-intestazione.component.scss'],
})
export class CopiaIntestazioneComponent implements OnInit {
    @Input() codiceIntestazione = null;

    formDoCopiaIntestazione: FormGroup;

    handlerMessage = '';
    roleMessage = '';

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private formBuilder: FormBuilder,
        private articoliService: ArticoliService,
        private alertCtrl: AlertController,
        private intestazioniService: IntestazioniService
    ) {}

    ngOnInit() {
        this.formDoCopiaIntestazione = this.formBuilder.group({
            codEnd: ['', Validators.required],
        });
    }

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async doCopiaIntestazione(cod_start: string) {
        const cod_end = this.formDoCopiaIntestazione.get('codEnd').value;
        const doCopia = await this.intestazioniService.doCopiaintestazione(
            cod_start,
            cod_end
        );
        // console.log('docopia result',doCopia);
        this.close();
    }
}
