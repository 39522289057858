<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Nuovo listino calcolato</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div style="margin: 0; padding: 0;" *ngIf="!showProgress && showForm">
        <form [formGroup]="fg">
            <ion-item mode="ios">
                <ion-select [label]="this.translate.instant('131')" labelPlacement="stacked"
                    formControlName="listino_start" interface="popover" [placeholder]="this.translate.instant('150')">
                    <ion-select-option *ngFor="let listino of listini"
                        [value]="listino.COD_LISTINO">{{listino.COD_LISTINO}}</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item mode="ios">
                <ion-input [label]="this.translate.instant('132')" labelPlacement="stacked" type="text"
                    formControlName="formula"></ion-input>
            </ion-item>
            <ion-item mode="ios">
                <ion-select [label]="this.translate.instant('133')" labelPlacement="stacked"
                    formControlName="arrotondamento" interface="popover" placeholder="">
                    <ion-select-option value="0">{{this.translate.instant('134')}}</ion-select-option>
                    <ion-select-option value="2">{{this.translate.instant('135')}}</ion-select-option>
                    <ion-select-option value="3">{{this.translate.instant('136')}}</ion-select-option>
                    <ion-select-option value="4">{{this.translate.instant('137')}}</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item mode="ios">
                <ion-input [label]="this.translate.instant('138')" labelPlacement="stacked" type="text" maxlength="15"
                    formControlName="listino_end"></ion-input>
            </ion-item>
            <ion-item mode="ios">
                <ion-input [label]="this.translate.instant('139')" labelPlacement="stacked" type="text"
                    formControlName="descrizione_list_end"></ion-input>
            </ion-item>
            <ion-item mode="ios">
                <ion-select interface="popover" mode="ios" [label]="this.translate.instant('275')"
                    labelPlacement="stacked" type="text" formControlName="valuta_listino">
                    <ion-select-option *ngFor="let valuta of this.valute | keyvalue"
                        [value]="valuta.key">{{valuta.value['name']
                        + ' ' + valuta.value['symbol']}}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item mode="ios">
                <ion-checkbox formControlName="modalita_prova" checked="false"
                    (change)="checkCheckBoxvalue($event)">{{this.translate.instant('140')}}</ion-checkbox>
            </ion-item>
        </form>
    </div>


    <ion-grid *ngIf="showProgress === true">
        <ion-row>
            <ion-col>
                <ion-progress-bar [value]="progressValue" color="primary" max="100" id="progressbar"></ion-progress-bar>
                <!-- i valori vanno da 0 a 1, l'api rimanda da 0 a 100, gestiscila -->
                <span id="perc">0</span>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>



<ion-footer class="ion-no-border">
    <ion-toolbar>
        <div class="ion-text-center">
            <ion-button [disabled]="!fg.valid" mode='ios' (click)="doNuovoListinoCalcolato()"
                color="primary">{{this.translate.instant('146')}}</ion-button>
        </div>

        <div>
            <ion-note>
                <b>{{this.translate.instant('141')}}</b>
            </ion-note>
            <ion-note>{{this.translate.instant('142')}}</ion-note>
            <br>
            <ion-note>
                <b>
                    {{this.translate.instant('143')}}
                </b>
            </ion-note>
            <ion-note>
                {{this.translate.instant('144')}}
            </ion-note>
            <br>
            <ion-note><b>{{this.translate.instant('140')}}:</b></ion-note>
            <ion-note>{{this.translate.instant('145')}}</ion-note>
        </div>

    </ion-toolbar>
</ion-footer>