<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>Validità per il listino {{ this.cod_listino }}</ion-title>
    <ion-button mode='ios' slot="end" (click)="this.modalCtrl.dismiss()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-datetime presentation="date" [firstDayOfWeek]="1" mode="ios" hourCycle="h24"
          (ionChange)="getDate($event)"></ion-datetime>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
  <ion-toolbar>
    <ion-button (click)="saveDate()" mode="ios">Seleziona</ion-button>
  </ion-toolbar>
</ion-footer>