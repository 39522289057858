/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizzazioniMartedesignService {
    catalogoAttivo = null;
    private destroy = new Subject();

    constructor(
        private common: CommonFunctionService,
        private globalVar: GlobalVariableService
    ) {
        globalVar.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(
            async (catalogoAttivo) => {
                if (catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
                    this.catalogoAttivo = catalogoAttivo;
                    // console.log('catalogoAttivo', catalogoAttivo);
                } else {
                    this.catalogoAttivo = null;
                }
            }
        );
    }

    async doImportaPreflightMARTED(file: File, cod_listino: string) {
        if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
            const responseImporta = await this.common.doImportaPreflightMD(
                file,
                cod_listino
            );
            // console.log('response importa preflight LTFORM', responseImporta);
            console.log(responseImporta['MESSAGGI'].length);
            if (responseImporta['MESSAGGI'].length === 0) {
                // console.log('non ci sono errori, proseguo con l importazione');
                this.globalVar.setFILE_NAME_IMPORTAZIONE(
                    responseImporta['FILE_IMPORT']
                );
                this.globalVar.setERRORE_IMPORTAZIONE(
                    responseImporta['MESSAGGI']
                );
                // console.log('FILENAME set', responseImporta['FILENAME']);
            } else {
                this.globalVar.setFILE_NAME_IMPORTAZIONE(
                    responseImporta['FILE_IMPORT']
                );
                // console.log('FILENAME set', responseImporta['FILENAME']);
                this.globalVar.setERRORE_IMPORTAZIONE(
                    responseImporta['MESSAGGI']
                );
                responseImporta['MESSAGGI'].forEach((element) => {
                    console.log(element['FLAG_IMPORT']);
                    console.log(element['MESSAGGIO']);
                    if (element['FLAG_IMPORT'] !== 'C') {
                        // console.log('non ci sono errori critici, posso proseguire');
                    } else {
                        // console.log('ci sono errori critici, blocco tutto');
                    }
                });
            }
        }
    }
}
