<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Modifica testata {{testata.COD_DISTINTA_CALCOLO}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="modalCtrl.dismiss()" fill="clear">
            <ion-icon style="float: right;" name="close-outline"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-row>
        <ion-col size="12" text-center>
            <form [formGroup]="fg">
                <ion-item mode="ios">
                    <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DES_DISTINTA"></ion-input>
                </ion-item>

            </form>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="editT()" color="primary">Crea</ion-button>
    </ion-toolbar>
</ion-footer>