<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title *ngIf="index !== 0">Inserisci in {{linguaEdit + '_DES' + index}}</ion-title>
    <ion-title *ngIf="index === 0"> Inserisci in {{linguaEdit+'_DES'}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-textarea name="lingua" id="lingua" rows="10" cols="60"
    [(ngModel)]="linguePerViewer[linguaEdit][getNomeDescrizione(index, linguaEdit)]"></ion-textarea>
  <ion-button mode='ios' color="primary"
    (click)="salvaDescrizione(getNomeDescrizione(index, linguaEdit), linguePerViewer[linguaEdit][getNomeDescrizione(index, linguaEdit)])">Salva</ion-button>
</ion-content>