<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>{{this.translate.instant('033')}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-row>
        <ion-col size="12" text-center>
            <form [formGroup]="formDoNuovoListino" (ngSubmit)="doNuovoListino()">
                <ion-item mode="ios">
                    <ion-input label="{{this.translate.instant('129')}}" labelPlacement="stacked" maxlength="10"
                        type="text" formControlName="cod_listino"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input label="{{this.translate.instant('130')}}" labelPlacement="stacked" type="text"
                        formControlName="des_listino"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-select interface="popover" mode="ios" label="{{this.translate.instant('275')}}"
                        labelPlacement="stacked" type="text" formControlName="valuta_listino">
                        <ion-select-option *ngFor="let valuta of this.valute | keyvalue"
                            [value]="valuta.key">{{valuta.value['name']
                            + ' ' + valuta.value['symbol']}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </form>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" [disabled]="!formDoNuovoListino.valid" (click)="doNuovoListino()"
            color="primary">{{this.translate.instant('123')}}</ion-button>
    </ion-toolbar>
</ion-footer>