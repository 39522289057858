<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()"></ion-icon>
    <ion-title>{{this.changelog[0].VERSIONE}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        inPrint <b>build {{this.changelog[0].NUMERO_VERSIONE}}</b> {{this.translate.instant('266')}}
        <b>{{this.changelog[0].DATA_RILASCIO.date.split(' ')[0]}}</b>
        <br><br>
        <ion-text>
          {{this.translate.instant('267')}} :
        </ion-text>
        <ion-text [innerHTML]="text"></ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>