/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ListiniService } from 'src/app/servizi/listini.service';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-copia-listino',
    templateUrl: './copia-listino.component.html',
    styleUrls: ['./copia-listino.component.scss'],
})
export class CopiaListinoComponent implements OnInit {
    formDoCopiaListino: FormGroup;
    cod_start;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private formBuilder: FormBuilder,
        private listiniService: ListiniService
    ) {}

    ngOnInit(): void {
        this.formDoCopiaListino = this.formBuilder.group({
            cod_listino: ['', Validators.required],
            des_listino: ['', Validators.required],
        });
    }

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async doCopiaListino() {
        const cod_listino_end =
            this.formDoCopiaListino.get('cod_listino').value;
        const des_listino_end =
            this.formDoCopiaListino.get('des_listino').value;
        // console.log('listino start', this.cod_start);
        // console.log('listino end', cod_listino_end + des_listino_end);
        await this.listiniService.duplicaListino(
            this.cod_start,
            cod_listino_end,
            des_listino_end
        );
    }
}
