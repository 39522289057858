/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { TeamService } from 'src/app/servizi/team.service';
import { invitoDaInviareStruct } from 'src/app/strutture/invitoDaInviare.strutture';
import { utenteErrataStruct } from 'src/app/strutture/utenteErrataStruct.strutture';
import * as uuid from 'uuid';
import { CreaUtenteComponent } from '../crea-utente/crea-utente.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-aggiungi-utente',
    templateUrl: './aggiungi-utente.component.html',
    styleUrls: ['./aggiungi-utente.component.scss'],
})
export class AggiungiUtenteComponent implements OnInit {
    formInvitaUtente: FormGroup;

    handlerMessage = '';
    modalDataResponse;

    searchQuery = '';
    results;

    showResults = false;

    constructor(
        public translate: TranslateService,
        private formBuilder: FormBuilder,
        private teamService: TeamService,
        private modalCtr: ModalController,
        private globalVar: GlobalVariableService,
        private common: CommonFunctionService,
        private alertCtrl: AlertController
    ) {}

    ngOnInit() {
        const catalogoAttivo = this.globalVar.getIdCatalogoAttivo();
        const uuidUtenteAttivo = this.globalVar.getUUID();
        const idInvitoSys = uuid.v4();
        const timestampIn = Math.floor(Date.now() / 1000);
        this.formInvitaUtente = this.formBuilder.group({
            ID_INVITO_SYS: [idInvitoSys, Validators.required],
            TIMESTAMP_OUT: [0, Validators.required],
            STATO: [0, Validators.required],
            INVITE_ID: ['', Validators.required],
            UUID_INVITO_IN: [uuidUtenteAttivo, Validators.required],
            ID_CATALOGO: [catalogoAttivo, Validators.required],
            TIMESTAMP_IN: [timestampIn, Validators.required],
        });
        //// console.log('data form invito', this.formInvitaUtente);
    }

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async searchUsers() {
        const result = await this.common.ricercaUtentiCatalogo(
            this.searchQuery
        );
        // console.log('result ricerca', result);
        this.results = result;
        this.showResults = true;
    }

    async userSelected(user: utenteErrataStruct) {
        // console.log('ho selezionato l\'utente', user);
        const inviteID = user.INVITE_ID;
        const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: 'Vuoi invitare questo utente?',
            buttons: [
                {
                    text: 'Annulla',
                    role: 'annulla',
                },
                {
                    text: 'Conferma',
                    role: 'confirm',
                    handler: () => {
                        this.handlerMessage = 'cancel';
                    },
                },
            ],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        if (role === 'confirm') {
            const catalogoAttivo = this.globalVar.getIdCatalogoAttivo();
            const uuidUtenteAttivo = this.globalVar.getUUID();
            const idInvitoSys = uuid.v4();
            const timestampIn = Math.floor(Date.now() / 1000);
            const invitaUtente = new invitoDaInviareStruct(
                idInvitoSys,
                inviteID,
                timestampIn,
                0,
                0,
                catalogoAttivo,
                uuidUtenteAttivo
            );
            // console.log('invito', JSON.stringify(invitaUtente));
            await this.teamService.aggiungiUtente(invitaUtente);
            this.close();
        } else {
            // console.log('annullo l operazione e torno ai listini');
        }
    }

    async creaUtente() {
        const modal = await this.modalCtr.create({
            component: CreaUtenteComponent,
            mode: 'ios',
            cssClass: 'long',
        });
        modal.onDidDismiss().then((modalDataResponse) => {
            if (modalDataResponse !== null) {
                this.modalDataResponse = modalDataResponse.data;
                console.log('Modal Sent Data : ' + modalDataResponse.data);
            }
        });
        return await modal.present();
    }
}
