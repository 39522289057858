<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      Richiedi traduzioni
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-margin">
    <ion-row>
      <ion-col>
        <ion-label>Inserisci la mail o selezionala dalla lista sotto</ion-label>
        <form [formGroup]="fg">
          <ion-item mode="ios">
            <ion-input labelPlacement="stacked" formControlName="mail_to"></ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <br>
    <ion-row *ngFor="let utente of utentiArrivati" class="colored" (click)="this.getMailDaLista(utente.EMAIL)">
      <ion-col>{{utente.NOME + ' ' + utente.COGNOME}}</ion-col>
      <ion-col><b>{{utente.EMAIL}}</b></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button [hidden]="!fg.valid" color="primary" (click)="sendTradRequest()">Invia</ion-button>
  </ion-toolbar>
</ion-footer>