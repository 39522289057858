/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    AlertController,
    ModalController,
    ViewWillEnter,
} from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ListiniService } from 'src/app/servizi/listini.service';
import { StringheService } from 'src/app/servizi/stringhe.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-nuovo-listino',
    templateUrl: './nuovo-listino.component.html',
    styleUrls: ['./nuovo-listino.component.scss'],
})
export class NuovoListinoComponent implements OnInit, ViewWillEnter {
    formDoNuovoListino: FormGroup;

    valute: any;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private formBuilder: FormBuilder,
        private listiniService: ListiniService,
        private alertCtr: AlertController,
        private str: StringheService
    ) {}

    ngOnInit(): void {
        this.formDoNuovoListino = this.formBuilder.group({
            cod_listino: ['', Validators.required],
            des_listino: ['', Validators.required],
            valuta_listino: ['', Validators.required],
        });
    }

    ionViewWillEnter(): void {
        this.getValute();
    }

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async getValute() {
        this.valute = await this.listiniService.getValuteListino();
        console.log('valute', this.valute);
    }

    containsSpecialChars(str) {
        const specialChars = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    async doNuovoListino() {
        const nome_listino = this.formDoNuovoListino.get('cod_listino').value;
        const des_listino = this.formDoNuovoListino.get('des_listino').value;
        const valuta_listino =
            this.formDoNuovoListino.get('valuta_listino').value;
        // console.log('nome_listino', nome_listino);
        // console.log('special', this.containsSpecialChars(nome_listino));
        if (this.containsSpecialChars(nome_listino)) {
            console.log('invalid name, gestisco l errore');
            const alert = await this.alertCtr.create({
                mode: 'ios',
                header: this.translate.instant('A004'),
                subHeader: this.translate.instant('A011'),
                message: this.translate.instant('A012'),
                buttons: [this.translate.instant('001')],
            });
            await alert.present();
        } else {
            console.log('nome listino', nome_listino.toUpperCase());
            await this.listiniService.nuovoListino(
                'ARTCOMB_' + nome_listino.toUpperCase(),
                des_listino,
                valuta_listino
            );
            this.modalCtr.dismiss();
        }
    }
}
