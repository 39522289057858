import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { GlobalVariableService } from '../servizi/global-variable.service';
// import { printLog } from '../lib';
import { CommonFunctionService } from '../servizi/common-function.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private globalvar: GlobalVariableService,
        private router: Router,
        private commons: CommonFunctionService,
        private cookieService: CookieService
    ) {}

    async canActivate(
        router: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        // console.log('AuthGuard', this.globalvar.isAuth());
        if (!this.globalvar.isAuth()) {
            if (this.cookieService.check('userData')) {
                const cookieContent = this.cookieService.get('userData');
                const cookieDiviso = cookieContent.split('|');

                await this.commons
                    .login(cookieDiviso[0], cookieDiviso[1])
                    .then(() => {
                        this.router.navigate(['login']);
                    })
                    .catch(() => this.router.navigate(['login']));
            } else {
                this.router.navigate(['login']);
            }
        }

        return this.globalvar.isAuth();
    }
}
