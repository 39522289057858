import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariableService } from 'src/app/servizi/global-variable.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { righeComposizioni } from 'src/app/strutture/righeComposizioni.strutture';
import { RicercaService } from 'src/app/servizi/ricerca.service';
import { RicercaArticoliComponent } from '../../ricerca-articoli/ricerca-articoli.component';

@Component({
    selector: 'app-modifica-riga-composizioni',
    templateUrl: './modifica-riga-composizioni.component.html',
    styleUrls: ['./modifica-riga-composizioni.component.scss'],
})
export class ModificaRigaComposizioniComponent implements OnInit {
    fg: FormGroup;
    numero_colonne: number[] = [];

    colonneCatalogo: any;

    codice_arrivato: any;
    codice: boolean = false;

    cod_testata: string = '';

    riga_compo: string;
    riga: righeComposizioni;

    constructor(
        public translate: TranslateService,
        public modalCtrl: ModalController,
        private fb: FormBuilder,
        private ricercaService: RicercaService,
        private alertCtrl: AlertController
    ) {}

    ngOnInit() {
        this.fg = this.fb.group({
            qta: [this.riga.QUANTITA],
            c1: [this.riga.COLONNA1],
            c2: [this.riga.COLONNA2],
            c3: [this.riga.COLONNA3],
            c4: [this.riga.COLONNA4],
            c5: [this.riga.COLONNA5],
            diml: [this.riga.DIML],
            dima: [this.riga.DIMA],
            dimp: [this.riga.DIMP],
            um: [this.riga.UM],
            d1: [this.riga.RDATA1],
            d2: [this.riga.RDATA2],
            d3: [this.riga.RDATA3],
            d4: [this.riga.RDATA4],
            d5: [this.riga.RDATA5],
        });
    }

    async ricercaArticoli() {
        const modal = await this.modalCtrl.create({
            component: RicercaArticoliComponent,
        });
        modal.onDidDismiss().then((modalDataResponse) => {
            if (modalDataResponse !== null) {
                this.codice_arrivato = modalDataResponse.data;
                this.codice = true;
                console.log('Modal Sent Data : ' + modalDataResponse.data);
            }
        });
        return await modal.present();
    }

    async prepareNuovaRiga() {
        if (this.fg.valid) {
            console.log('Dati arrivati:', this.fg.value);
        } else {
            console.log('Form non valida');
        }
    }

    searchCode() {
        if (this.fg.get('codice').value !== '') {
            this.ricercaService
                .ricerca(this.fg.get('codice').value)
                .then(async (response) => {
                    console.log(
                        'response ricerca articoli',
                        response.CODART[0]
                    );
                    if (
                        this.fg.get('codice').value === response.CODART[0].cod
                    ) {
                        this.codice_arrivato = response.CODART[0].cod;
                    } else {
                        console.log('non ho trovato codici corrispondenti');

                        const alert = await this.alertCtrl.create({
                            mode: 'ios',
                            header: this.translate.instant('A004'),
                            message: this.translate.instant('A009'),
                            buttons: [
                                {
                                    text: this.translate.instant('001'),
                                },
                            ],
                        });

                        (await alert).present();
                        await (await alert).onDidDismiss().then(() => {
                            this.fg.reset();
                        });
                    }
                })
                .catch(() => {
                    // printLogerror);
                });
        }
    }

    gestioneUM(e: any) {
        let um = e.detail.value;
        document.getElementById('diml').style.display = 'block';
        document.getElementById('dima').style.display = 'block';
        document.getElementById('dimp').style.display = 'block';
        switch (um) {
            case 'pz':
                document.getElementById('diml').style.display = 'none';
                document.getElementById('dima').style.display = 'none';
                document.getElementById('dimp').style.display = 'none';
                break;
            case 'mq':
                document.getElementById('dimp').style.display = 'none';
                break;
            case 'ml':
                document.getElementById('dima').style.display = 'none';
                document.getElementById('dimp').style.display = 'none';
                break;
        }
    }

    editRiga() {
        const editR = new righeComposizioni(
            this.cod_testata,
            this.riga.RIGA_COMPO,
            this.codice_arrivato,
            this.fg.get('qta').value,
            this.fg.get('c1').value,
            this.fg.get('c2').value,
            this.fg.get('c3').value,
            this.fg.get('c4').value,
            this.fg.get('c5').value,
            this.fg.get('diml').value ? this.fg.get('diml').value : 0,
            this.fg.get('dima').value ? this.fg.get('dima').value : 0,
            this.fg.get('dimp').value ? this.fg.get('dimp').value : 0,
            this.fg.get('um').value,
            this.fg.get('d1').value,
            this.fg.get('d2').value,
            this.fg.get('d3').value,
            this.fg.get('d4').value,
            this.fg.get('d5').value,
            this.riga.DES
        );
        console.log('payload riga', editR);
        this.modalCtrl.dismiss(editR);
    }
}
