<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>{{this.translate.instant('182')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="this.modalCtr.dismiss()" fill="clear" *ngIf="this.showCloseBtn === true">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="showProgress === false">
    <ion-row class="ion-text-center">
      <ion-col size="12">
        <span>{{ errorLabel }}</span>
      </ion-col>
    </ion-row>
  </div>


  <div *ngIf="showProgress === false">
    <ion-grid *ngFor="let errore of erroriArrivati">
      <ion-row>
        <ion-col>
          <ion-textarea class="warning" disabled *ngIf="this.w_error === true">
            {{errore.FLAG_IMPORT + ' ' + errore.MESSAGGIO}}
          </ion-textarea>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-textarea class="critical" disabled *ngIf="this.c_error === true">
            {{errore.FLAG_IMPORT + ' ' + errore.MESSAGGIO}}
          </ion-textarea>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>


  <ion-grid *ngIf="showProgress === true">
    <ion-row>
      <ion-col>
        <ion-text id="message">
          ...
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-progress-bar [value]="progressValue" color="primary" max="100" id="progressor"></ion-progress-bar>
        <!-- i valori vanno da 0 a 1, l'api rimanda da 0 a 100, gestiscila -->
        <span id="percentage">0</span>
      </ion-col>
    </ion-row>
  </ion-grid>


</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center" *ngIf="prosegui === true && showProgress === false">
    <ion-button mode='ios' color="primary" (click)="doImporta()">{{this.translate.instant('160')}}</ion-button>
  </ion-toolbar>
</ion-footer>