import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
    PreferenzaCatalogo,
    PreferenzaInPrint,
    PreferenzeService,
} from '../../servizi/preferenze.service';
// import { printLog } from '../../lib';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-edit-preferenza',
    templateUrl: './edit-preferenza.component.html',
    styleUrls: ['./edit-preferenza.component.scss'],
})
export class EditPreferenzaComponent implements OnInit {
    @Input() preferenza: PreferenzaCatalogo;
    @Input() preferenzaInPrint: PreferenzaInPrint;

    formEditPreferenza: FormGroup;

    textarea: boolean = false;
    text: boolean = false;
    timezone: boolean = false;
    list1: boolean = false;

    arrayFromString: any;
    valoreToAdd: string = '';

    constructor(
        public translate: TranslateService,
        public modalCtr: ModalController,
        private formBuilder: FormBuilder,
        private preferenzeService: PreferenzeService,
        private alertCtrl: AlertController
    ) {}

    ngOnInit() {
        console.log(this.preferenzaInPrint);
        switch (this.preferenzaInPrint.GET_TIPO_GEST) {
            case 'TEXTAREA':
                this.textarea = true;
                break;
            case 'TEXT':
                this.text = true;
                break;
            case 'TIMEZONE':
                this.timezone = true;
                break;
            case 'LIST1':
                this.list1 = true;
                this.getArrayFromString();
                break;
        }
        this.formEditPreferenza = this.formBuilder.group({
            valore: [
                this.preferenza.VALORE_PREFERENZA ?? '',
                Validators.required,
            ],
        });
    }

    async editPreferenza() {
        const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';

        let arrayToStringify = [this.formEditPreferenza.get('valore').value];
        console.log('arrtostring', arrayToStringify);

        await this.preferenzeService.setPreferenzaCatalogo(
            this.preferenza.COD_PREFERENZA,
            JSON.stringify(arrayToStringify),
            modulo,
            this.preferenzaInPrint.SET_TIPO_GEST
        );
    }

    getArrayFromString() {
        let string = this.preferenza.VALORE_PREFERENZA.replace('"', '').replace(
            '"',
            ''
        );
        this.arrayFromString = string.split(',');
    }

    async aggiungiValore() {
        if (this.valoreToAdd.trim() !== '') {
            if (this.arrayFromString.includes(this.valoreToAdd.trim())) {
                const alert = await this.alertCtrl.create({
                    mode: 'ios',
                    header: 'Attenzione! Il campo esiste già',
                    message: '',
                    buttons: [
                        {
                            text: 'Ok',
                            role: 'confirm',
                        },
                    ],
                });
                await alert.present();
                await alert.onDidDismiss();
                this.valoreToAdd = '';
            } else {
                console.log('SUCCESS! Posso pubblicare il campo');
                this.arrayFromString.push(this.valoreToAdd.trim());
                let stringToPass = this.arrayFromString.join(',');
                console.log('string to pass', stringToPass);
                this.valoreToAdd = '';
                let array = [stringToPass];
                console.log('json str of arr', JSON.stringify(array));

                const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';
                await this.preferenzeService.setPreferenzaCatalogo(
                    this.preferenza.COD_PREFERENZA,
                    JSON.stringify(array),
                    modulo,
                    this.preferenzaInPrint.SET_TIPO_GEST
                );
            }
        } else {
            this.valoreToAdd = '';
            console.log('ERRORE! Il campo è vuoto');
        }
    }

    async eliminaCampo(index: number) {
        this.arrayFromString.splice(index, 1);
        console.log(this.arrayFromString);
        let stringToPass = this.arrayFromString.join(',');
        console.log(stringToPass);
        let array = [stringToPass];
        console.log(array);

        const modulo = this.preferenzaInPrint.MODULO ?? 'HOME';
        await this.preferenzeService
            .setPreferenzaCatalogo(
                this.preferenza.COD_PREFERENZA,
                JSON.stringify(array),
                modulo,
                this.preferenzaInPrint.SET_TIPO_GEST
            )
            .then(() => {
                this.modalCtr.dismiss();
            });
    }
}
