import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { PersonalizzazioniLTFORMService } from 'src/app/servizi/personalizzazioni-ltform.service';
import { LTFORMNuovaAssociazioneComponent } from '../ltform-nuova-associazione/ltform-nuova-associazione.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-ltform-associa-colonne',
    templateUrl: './ltform-associa-colonne.component.html',
    styleUrls: ['./ltform-associa-colonne.component.scss'],
})
export class LTFORMAssociaColonneComponent implements OnInit {
    associazioni;

    modalDataResponse: any;

    constructor(
        public translate: TranslateService,
        private modalCtrl: ModalController,
        private personalizzazioniService: PersonalizzazioniLTFORMService
    ) {}

    async ngOnInit() {
        this.associazioni =
            await this.personalizzazioniService.getAssociazioni();
        // console.log('associazioni arrivate', associazioni);
    }

    async delAssociazione(catLT: string) {
        const del = await this.personalizzazioniService.delAssociazione(catLT);
    }

    async openAssociazione() {
        const modal = await this.modalCtrl.create({
            component: LTFORMNuovaAssociazioneComponent,
        });

        modal.onDidDismiss().then((modalDataResponse) => {
            if (modalDataResponse !== null) {
                this.modalDataResponse = modalDataResponse.data;
            }
        });

        return await modal.present();
    }

    async close() {
        const closeModal = 'Modal Closed';
        await this.modalCtrl.dismiss(closeModal);
    }
}
