<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{this.translate.instant('268') + ' ' + macro.COD_MACRO}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <form [formGroup]="fg">
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('090')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="des_macro"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('085')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="codalt"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('107')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="diml"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('108')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dima"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('109')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="dimp"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label>{{this.translate.instant('089')}}</ion-label>
      <ion-input labelPlacement="stacked" type="text" formControlName="colonne"></ion-input>
    </ion-item>
  </form>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid"
      (click)="editTestata()">{{this.translate.instant('160')}}</ion-button>
  </ion-toolbar>
</ion-footer>