<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>{{this.translate.instant('157')}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="this.modalCtrl.dismiss()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-row>
        <ion-col size="12" text-center>
            <form [formGroup]="fg">
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('030')" labelPlacement="stacked" type="text" autocomplete
                        readonly (focusout)="searchCode()" [value]="codice_arrivato"></ion-input>
                    <ion-button mode='ios' (click)="ricercaArticoli()"><ion-icon
                            name="search-outline"></ion-icon></ion-button>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('0100')" labelPlacement="stacked" type="number"
                        formControlName="qta" helperText="Per le misure con decimali utilizzare il punto"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('099') + ' ' + 1" labelPlacement="stacked" type="text"
                        formControlName="c1"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('099') + ' ' + 2" labelPlacement="stacked" type="text"
                        formControlName="c2"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('099') + ' ' + 3" labelPlacement="stacked" type="text"
                        formControlName="c3"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('099') + ' ' + 4" labelPlacement="stacked" type="text"
                        formControlName="c4"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('099') + ' ' + 5" labelPlacement="stacked" type="text"
                        formControlName="c5"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-select [label]="this.translate.instant('0101')" labelPlacement="stacked" interface="popover"
                        mode="ios" default="pz" formControlName="um" (ionChange)="gestioneUM($event)">
                        <ion-select-option value="pz">Pezzo</ion-select-option>
                        <ion-select-option value="mq">Metro quadro (m2)</ion-select-option>
                        <ion-select-option value="mc">Metro cubo (m3)</ion-select-option>
                        <ion-select-option value="ml">Metro lineare</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item mode="ios" id="diml">
                    <ion-input [label]="this.translate.instant('107')" labelPlacement="stacked" type="text"
                        formControlName="diml"></ion-input>
                </ion-item>
                <ion-item mode="ios" id="dima">
                    <ion-input [label]="this.translate.instant('108')" labelPlacement="stacked" type="text"
                        formControlName="dima"></ion-input>
                </ion-item>
                <ion-item mode="ios" id="dimp">
                    <ion-input [label]="this.translate.instant('109')" labelPlacement="stacked" type="text"
                        formControlName="dimp"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('119') + ' ' + 1" labelPlacement="stacked" type="text"
                        formControlName="d1"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('119') + ' ' + 2" labelPlacement="stacked" type="text"
                        formControlName="d2"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('119') + ' ' + 3" labelPlacement="stacked" type="text"
                        formControlName="d3"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('119') + ' ' + 4" labelPlacement="stacked" type="text"
                        formControlName="d4"></ion-input>
                </ion-item>
                <ion-item mode="ios">
                    <ion-input [label]="this.translate.instant('119') + ' ' + 5" labelPlacement="stacked" type="text"
                        formControlName="d5"></ion-input>
                </ion-item>
            </form>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' color="primary" (click)="doNuovaRiga()">{{this.translate.instant('123')}}</ion-button>
    </ion-toolbar>
</ion-footer>