<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>Recupera la password</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- todo: inserire la mail di registrazione -->
  <ion-grid *ngIf="mail === true">
    <ion-row class="ion-text-center">
      <ion-col>
        <div class="box">
          <ion-text class="ion-margin">Inserisci l'indirizzo email con cui ti sei registrato</ion-text>
          <br><br>
          <ion-input labelPlacement="stacked" class="email" placeholder="esempio@email.com"
            [(ngModel)]="MAIL_TO_CHECK"></ion-input>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- todo: inserire il codice arrivato via mail -->
  <ion-grid *ngIf="codice === true">
    <ion-row class="ion-text-center">
      <ion-col size="12">
        <ion-label>È stato inviato un codice via mail. Inseriscilo qui</ion-label>
        <ion-input labelPlacement="stacked" class="codice" type="text" placeholder="codice"
          [(ngModel)]="CODICE_RECOVERY"></ion-input>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- todo: settare la nuova password (doppio check)-->
  <ion-grid *ngIf="password === true">
    <ion-row class="ion-text-center">
      <ion-col size="12">
        <ion-label>Nuova password</ion-label>
        <ion-input labelPlacement="stacked" class="password" placeholder="password" type="password"
          [(ngModel)]="NUOVA_PASSWORD"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col size="12">
        <ion-label>Conferma password</ion-label>
        <ion-input labelPlacement="stacked" class="password" placeholder="password" type="password"
          [(ngModel)]="CONFERMA_PASSWORD"></ion-input>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button class="proseguibtn" mode='ios' type="submit" color="primary" *ngIf="mail === true"
      (click)="mailCheck()">Prosegui</ion-button>
    <ion-button class="proseguibtn" mode='ios' *ngIf="codice === true" (click)="codCheck()">Prosegui</ion-button>
    <ion-button class="proseguibtn" mode='ios' *ngIf="password === true" (click)="passwordCheck()">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>