import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AlertController,
    ModalController,
    ViewWillEnter,
} from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { TranslateService } from '@ngx-translate/core';
import { calcoliStruct } from 'src/app/strutture/calcoli.strutture';
@Component({
    selector: 'app-nuovo-listino-calcolato',
    templateUrl: './nuovo-listino-calcolato.component.html',
    styleUrls: ['./nuovo-listino-calcolato.component.scss'],
})
export class NuovoListinoCalcolatoComponent implements OnInit, ViewWillEnter {
    listini: listiniStruct[] = [];

    modo_prova: string;

    progressValue: number;
    showProgress = false;
    showForm = true;

    fg: FormGroup;

    valute: any;

    es;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private fb: FormBuilder,
        private ls: ListiniService,
        private alertCtrl: AlertController
    ) {}
    async ionViewWillEnter() {
        this.listini = await this.ls.getListini();
        await this.getValute();
    }

    async ngOnInit() {
        this.fg = this.fb.group({
            listino_start: ['', Validators.required],
            formula: ['', Validators.required],
            arrotondamento: ['', Validators.required],
            listino_end: ['', Validators.required],
            descrizione_list_end: ['', Validators.required],
            modalita_prova: [''],
            valuta_listino: ['', Validators.required],
        });
    }

    async getValute() {
        this.valute = await this.ls.getValuteListino();
        console.log('valute', this.valute);
    }

    async close() {
        await this.modalCtr.dismiss();
    }

    async checkCheckBoxvalue(event) {
        console.log(event.checked);
    }

    containsSpecialChars(str) {
        const specialChars = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    async doNuovoListinoCalcolato() {
        const formulaCheck = this.fg.get('formula').value;
        const formula = formulaCheck.replace(',', '.');
        const formulaControl = formula.substring(
            formula.indexOf('[') + 1,
            formula.lastIndexOf(']')
        );
        if (formulaControl == 'prezzo') {
            const checkModoProva = this.fg.get('modalita_prova').value;
            if (checkModoProva === false) {
                this.modo_prova = 'off';
            } else {
                this.modo_prova = 'on';
            }
            const nome_listino = this.fg.get('listino_end').value;
            if (this.containsSpecialChars(nome_listino)) {
                console.log('invalid name, gestisco l errore');
                const alert = await this.alertCtrl.create({
                    mode: 'ios',
                    header: this.translate.instant('A004'),
                    subHeader: this.translate.instant('A011'),
                    message: this.translate.instant('A012'),
                    buttons: [this.translate.instant('001')],
                });
                await alert.present();
            } else {
                const calc_payload = new calcoliStruct(
                    formula,
                    this.fg.get('listino_start').value,
                    this.fg.get('arrotondamento').value,
                    nome_listino.toUpperCase(),
                    1,
                    this.fg.get('descrizione_list_end').value,
                    this.modo_prova,
                    this.fg.get('valuta_listino').value,
                    '',
                    ''
                );
                console.log('calc_payload ', calc_payload);
                await this.ls.doListinoCalcolatoBg(calc_payload).then(() => {
                    this.modalCtr.dismiss();
                });
            }
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                message:
                    'Non è possibile utilizzare le parentesi quadre per indicare elementi che non siano il prezzo',
                buttons: [
                    {
                        text: 'ok',
                    },
                ],
            });
            await alert.present();
        }
    }
}

/* procedura vecchia con progress */
//   const chiamataApi =
//     'https://api.price-list.it/inprintapi/api1/doListinoCalcolatoBackground.php?APPID=' +
//     APPID +
//     '&CALC_PAYLOAD=' +
//     JSON.stringify(calc_payload) +
//     '&LINGUA=' +
//     LINGUA +
//     '&ID_CATALOGO=' +
//     this.globalVar.getIdCatalogoAttivo() +
//     '&DEBUG_PROGRESS=off&UUID=' +
//     this.globalVar.getUUID() +
//     '';
//   console.log('calc payload', JSON.stringify(calc_payload));
//   console.log('api check ', chiamataApi);
//   this.es = new EventSource(chiamataApi);
//   console.log('es', this.es);
//   console.log('url = ', this.es.url);
//   this.es.addEventListener('message', (e) => {
//     const result = JSON.parse(e.data);
//     console.log('result', result);
//   });
//   this.es.addEventListener('message', (e) => {
//     const result = JSON.parse(e.data);
//     this.progressValue = result.progress / 100;
//     console.log('result.message = ', result.message);
//     const report = result.message.split('|').pop();
//     if (e.lastEventId === 'CLOSE') {
//       console.log('Received CLOSE closing');
//       this.es.close();
//       const pBar = document.getElementById(
//         'progressbar'
//       ) as HTMLInputElement;
//       pBar.value = pBar.max;
//       window.open(
//         'https://api.price-list.it/reportPDF/' +
//           this.globalVar.getIdCatalogoAttivo() +
//           '/' +
//           report,
//         '_blank'
//       );
//       if (this.modo_prova === 'on') {
//         this.showProgress = false;
//         this.showForm = true;
//       } else {
//         this.modalCtr.dismiss();
//       }
//       this.ls.getListini();
//     } else {
//       const pBar = document.getElementById(
//         'progressbar'
//       ) as HTMLInputElement;
//       pBar.value = result.progress;
//       const perc = document.getElementById('perc');
//       perc.innerHTML = result.progress + '%';
//       perc.style.width =
//         Math.floor(pBar.clientWidth * (result.progress / 100)) + 15 + 'px';
//     }
//   });
//   this.es.addEventListener('error', (e) => {
//     console.log('Error occurred');
//     this.es.close();
//   });
