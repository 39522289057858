<ion-header mode="md" class="ion-no-border">
    <ion-toolbar>
        <ion-menu-toggle *ngIf="(isMobile$ | async) === true">
            <ion-item mode="ios" lines="none">
                <ion-icon name="ellipsis-vertical"></ion-icon>
                <ion-title class="ion-text-center ion-padding">{{catalogoArrivato}}</ion-title>
                <ion-icon style="font-size: 3.5vh; float: right;" name="help-circle-outline" routerDirection="root"
                    [routerLink]="['/knowledge-base']"></ion-icon>
                <ion-icon style="font-size: 3.5vh; float: right;" name="person-circle-outline"
                    (click)="openPopover($event)"></ion-icon>
            </ion-item>
        </ion-menu-toggle>

        <ion-grid *ngIf="(isMobile$ | async) === false">
            <ion-row>
                <ion-col size="4">
                    <app-search></app-search>
                </ion-col>
                <ion-col class="ion-text-center">
                    <ion-title *ngIf="(isMobile$ | async) === false" id="title">{{catalogoArrivato}}</ion-title>
                </ion-col>
                <ion-col size="4">
                    <ion-icon style="font-size: 3.5vh; float: right; color:white;" name="person-circle-outline"
                        (click)="openPopover($event)"></ion-icon>
                </ion-col>
            </ion-row>
        </ion-grid>


    </ion-toolbar>
</ion-header>