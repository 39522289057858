<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>{{this.translate.instant('252')}} {{ codiceArticolo }}</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-row>
        <ion-col size="12" text-center>
            <form [formGroup]="formDoCopiaArticolo" (ngSubmit)="doCopiaArticolo()">
                <ion-item mode="ios">
                    <ion-label>{{this.translate.instant('214')}}</ion-label>
                    <ion-input labelPlacement="stacked" type="text" formControlName="codEnd"></ion-input>
                </ion-item>

            </form>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button mode='ios' type="submit" [disabled]="!formDoCopiaArticolo.valid" (click)="doCopiaArticolo()"
            color="primary">{{this.translate.instant('088')}}</ion-button>
    </ion-toolbar>
</ion-footer>