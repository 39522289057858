<ion-header class="ion-no-border" mode='ios'>
    <ion-toolbar>
        <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" (click)="close()"></ion-icon>
        <ion-title>{{this.translate.instant('033')}}</ion-title>
        <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row class="ion-text-center">
            <ion-col size="12">
                <ion-button mode='ios' color="primary" class="btn"
                    (click)="sceltaTipoListino('calcolato')">{{this.translate.instant('128')}}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
            <ion-col size="12">
                <ion-button mode='ios' color="primary" class="btn" (click)="sceltaTipoListino('distinta')">Listino
                    calcolato per distinta
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>


</ion-content>