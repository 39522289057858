import { Injectable } from '@angular/core';
import { utenteInPrint } from '../strutture/utenteInPrint.strutture';
import { utenteErrataConComponenti } from '../strutture/utenteErrataConComponenti.strutture';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable, printLog } from '../lib';
import { CookieService } from 'ngx-cookie-service';
import { Messaggi } from '../strutture/importa.strutture';
import { righeMacroStruct } from '../strutture/righeMacro.strutture';
import { macroTestateStruct } from '../strutture/macroTestate.strutture';
import { composizioniStruct } from '../strutture/composizioni.strutture';
import { cataloghi, utenteLogin } from '../strutture/utenteLogin.strutture';
import { ricercaStruct } from '../strutture/ricerca.strutture';
import { RispostaGetLingueArticolo } from './articoli.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalVariableService {
    private IS_MOBILE: boolean = false;
    private UTENTE_LOGIN1: utenteLogin;
    private ARRAY_TRADUZIONI: string[] = [];

    //? flag da ricerca
    private FLAG_MACRO_RIGHE: boolean = false;
    private CODICE_TESTATA_MACRO: string = '';
    private FLAG_PREZZI: boolean = false;
    private CODICE_ARTICOLO: string = '';
    private CODICE_INTESTAZIONE_R: string = '';
    private LISTINO_SELEZIONATO_PREZZI: string = '';
    private BACK: boolean = false;
    private TESTATA_MACRO: macroTestateStruct;

    //? ricerca
    public RICERCA_RESULT: ricercaStruct = null;
    public VALUE_RICERCA: string = '';

    //? lingue articolo
    public LINGUE_ARTICOLO: RispostaGetLingueArticolo[] = [];

    private DEEP_TYPE = new BehaviorSubject<Nullable<string>>(null);

    private UTENTE_LOGIN = new BehaviorSubject<Nullable<utenteInPrint>>(null);
    private CATALOGO_ATTIVO = new BehaviorSubject<
        Nullable<utenteErrataConComponenti>
    >(null);
    private CATALOGO_ARRIVATO = new BehaviorSubject<Nullable<cataloghi>>(null);
    private RISPOSTA_NUOVOART = new BehaviorSubject<Nullable<string>>(null);
    private MACRO = new BehaviorSubject<Nullable<string>>(null);
    private CODICE_INTESTAZIONE = new BehaviorSubject<Nullable<string>>(null);
    private ERRORE_IMPORTAZIONE = new BehaviorSubject<Nullable<Messaggi[]>>(
        null
    );
    private FILE_NAME_IMPORTAZIONE = new BehaviorSubject<Nullable<string>>(
        null
    );
    private CODICE_RIGA_MACRO = new BehaviorSubject<Nullable<string>>(null);
    private LAST_RIGA_MACRO = new BehaviorSubject<Nullable<righeMacroStruct>>(
        null
    );
    // private TESTATA_MACRO = new BehaviorSubject<Nullable<macroTestateStruct>>(
    //   null
    // );
    private TESTATA_COMPOSIZIONE = new BehaviorSubject<
        Nullable<composizioniStruct>
    >(null);
    private CODICE_COMPO_T = new BehaviorSubject<Nullable<string>>(null);
    private CODICE_PADRE = new BehaviorSubject<number>(0);
    private STATUS = new BehaviorSubject<boolean>(false);
    private MODELLO = new BehaviorSubject<string>('');
    private VARIANTE = new BehaviorSubject<string>('');
    public notify = new BehaviorSubject<any>('');
    private INITVAR = new BehaviorSubject<string>('');
    private ARTICOLO = new BehaviorSubject<string>('');
    private ALIAS = new BehaviorSubject<string>('');
    private CODICE_TO_PASS = new BehaviorSubject<string>('');
    private REST_KEY = new BehaviorSubject<string>('');

    constructor(private cookieService: CookieService) {}

    setIS_MOBILE(isMobile: boolean) {
        this.IS_MOBILE = isMobile;
    }

    getIS_MOBILE(): boolean {
        return this.IS_MOBILE;
    }

    setARRAY_TRADUZIONI(array: string[]) {
        this.ARRAY_TRADUZIONI = array;
    }

    getARRAY_TRADUZIONI() {
        return this.ARRAY_TRADUZIONI;
    }

    setUTENTE_LOGIN1(utenteLogin: utenteLogin) {
        this.UTENTE_LOGIN1 = utenteLogin;
    }

    getUTENTE_LOGIN(): utenteLogin {
        return this.UTENTE_LOGIN1;
    }

    setFLAG_MACRO_RIGHE(flag: boolean) {
        console.log('>>> flag macro righe', flag);
        this.FLAG_MACRO_RIGHE = flag;
    }

    getFLAG_MACRO_RIGHE(): boolean {
        return this.FLAG_MACRO_RIGHE;
    }

    setCODICE_TESTATA_MACRO(codice: string) {
        this.CODICE_TESTATA_MACRO = codice;
    }

    getCODICE_TESTATA_MACRO(): string {
        return this.CODICE_TESTATA_MACRO;
    }

    setRICERCA_RESULT(result: any) {
        this.RICERCA_RESULT = result;
    }

    getRICERCA_RESULT(): any {
        return this.RICERCA_RESULT;
    }

    setVALUE_RICERCA(value: string) {
        console.log('ricerca value set', value);
        this.VALUE_RICERCA = value;
    }

    getVALUE_RICERCA(): string {
        return this.VALUE_RICERCA;
    }

    setFLAG_PREZZI(flag: boolean) {
        this.FLAG_PREZZI = flag;
        console.log('prezzi set', this.FLAG_PREZZI);
    }

    getFLAG_PREZZI(): boolean {
        console.log('prezzi get', this.FLAG_PREZZI);
        return this.FLAG_PREZZI;
    }

    setTESTATA_MACRO_SCROLL(codice: macroTestateStruct) {
        this.TESTATA_MACRO = codice;
    }

    getTESTATA_MACRO_SCROLL(): macroTestateStruct {
        return this.TESTATA_MACRO;
    }

    setCODICE_ARTICOLO(codice: string) {
        console.log('set codice articolo', codice);
        this.CODICE_ARTICOLO = codice;
    }

    getCODICE_ARTICOLO(): string {
        return this.CODICE_ARTICOLO;
    }

    setLINGUE_ARTICOLO(lingueArticolo: RispostaGetLingueArticolo[]) {
        this.LINGUE_ARTICOLO = lingueArticolo;
    }

    getLINGUE_ARTICOLO(): RispostaGetLingueArticolo[] {
        return this.LINGUE_ARTICOLO;
    }

    setCODICE_INTESTAZIONE_R(codice: string) {
        this.CODICE_INTESTAZIONE_R = codice;
    }

    getCODICE_INTESTAZIONE_R(): string {
        return this.CODICE_INTESTAZIONE_R;
    }

    setLISTINO_SELEZIONATO_PREZZI(listino: string) {
        this.LISTINO_SELEZIONATO_PREZZI = listino;
    }

    getLISTINO_SELEZIONATO_PREZZI(): string {
        return this.LISTINO_SELEZIONATO_PREZZI;
    }

    setBACK(flag: boolean) {
        this.BACK = flag;
    }

    getBACK(): boolean {
        return this.BACK;
    }

    get UTENTE_LOGIN$(): Observable<Nullable<utenteInPrint>> {
        return this.UTENTE_LOGIN.asObservable();
    }

    setCATALOGO_ARRIVATO(catalogo: cataloghi) {
        this.CATALOGO_ARRIVATO.next(catalogo);
    }

    get CATALOGO_ARRIVATO$(): Observable<Nullable<cataloghi>> {
        return this.CATALOGO_ARRIVATO.asObservable();
    }

    get CATALOGO_ATTIVO$(): Observable<Nullable<utenteErrataConComponenti>> {
        return this.CATALOGO_ATTIVO.asObservable();
    }

    get LAST_RIGA_MACRO$(): Observable<Nullable<righeMacroStruct>> {
        return this.LAST_RIGA_MACRO.asObservable();
    }

    setUTENTE_LOGIN(utenteLoginDaSettare: utenteInPrint) {
        // console.log('setUTENTE_LOGIN', utenteLoginDaSettare);
        this.UTENTE_LOGIN.next(utenteLoginDaSettare);
    }

    setCATALOGO_ATTIVO(catalogo: utenteErrataConComponenti) {
        this.CATALOGO_ATTIVO.next(catalogo);
    }

    setRISPOSTA_NUOVOART(risposta: string) {
        this.RISPOSTA_NUOVOART.next(risposta);
    }

    setMACRO(macro: string) {
        this.MACRO.next(macro);
    }

    setCODICE_TO_PASS(codice: string) {
        this.CODICE_TO_PASS.next(codice);
    }

    isAuth() {
        if (this.UTENTE_LOGIN1 != undefined) {
            return (
                this.UTENTE_LOGIN1 !== null && this.UTENTE_LOGIN1.UUID !== null
            );
        }
    }

    ricordami(utente: string, passwordHashed: string) {
        const cookieString = utente + '|' + passwordHashed;
        this.cookieService.set('userData', cookieString, { expires: 365 });
    }

    utenteLogin(): utenteInPrint {
        return this.UTENTE_LOGIN.value;
    }

    getIdCatalogoAttivo() {
        return this.CATALOGO_ATTIVO.value.UTENTE.ID_CATALOGO_ERRATA;
    }

    getRestKey() {
        return this.CATALOGO_ATTIVO.value.UTENTE.REST_KEY;
    }

    getCampiLingua() {
        //return this.pre
    }

    getCatalogoAttivo() {
        return this.CATALOGO_ATTIVO;
    }

    getNomeCatalogoAttivo() {
        return this.CATALOGO_ATTIVO.value.UTENTE.NOME_CATALOGO;
    }

    getDBNomeCatalogo() {
        return this.CATALOGO_ATTIVO.value.UTENTE.INI_DB;
    }

    getNOME_UTENTE_LOGIN() {
        return this.UTENTE_LOGIN1.NOME;
    }

    getCOGNOME_UTENTE_LOGIN() {
        return this.UTENTE_LOGIN1.COGNOME;
    }

    getUUID() {
        return this.UTENTE_LOGIN1.UUID;
    }

    getCMB_PROFILO() {
        return this.UTENTE_LOGIN1.CMB_PROFILO;
    }

    getRispostaNuovoArticolo() {
        return this.RISPOSTA_NUOVOART.value;
    }

    getMacro() {
        return this.MACRO.value;
    }

    setCODICE_INTESTAZIONE(cod_int: string) {
        this.CODICE_INTESTAZIONE.next(cod_int);
    }

    getCODICE_INTESTAZIONE() {
        return this.CODICE_INTESTAZIONE.value;
    }

    setERRORE_IMPORTAZIONE(msg: Messaggi[]) {
        this.ERRORE_IMPORTAZIONE.next(msg);
    }

    getERRORE_IMPORTAZIONE() {
        return this.ERRORE_IMPORTAZIONE.value;
    }

    setFILE_NAME_IMPORTAZIONE(file_import: string) {
        console.log('setto file name importazione', file_import);
        this.FILE_NAME_IMPORTAZIONE.next(file_import);
    }

    getFILE_NAME_IMPORTAZIONE() {
        console.log('file name importazione', this.FILE_NAME_IMPORTAZIONE);
        console.log(
            'file name importazione value',
            this.FILE_NAME_IMPORTAZIONE.value
        );
        return this.FILE_NAME_IMPORTAZIONE.value;
    }

    setCODICE_RIGA_MACRO(codice: string) {
        this.CODICE_RIGA_MACRO.next(codice);
    }

    getCODICE_RIGA_MACRO() {
        return this.CODICE_RIGA_MACRO.value;
    }

    setLAST_RIGA_MACRO(riga: righeMacroStruct) {
        this.LAST_RIGA_MACRO.next(riga);
    }

    // setTESTATA_MACRO(testata: macroTestateStruct) {
    //   this.TESTATA_MACRO.next(testata);
    // }

    // getTESTATA_MACRO() {
    //   return this.TESTATA_MACRO.value;
    // }

    setTESTATA_COMPOSIZIONE(testata: composizioniStruct) {
        this.TESTATA_COMPOSIZIONE.next(testata);
    }

    getTESTATA_COMPOSIZIONE() {
        return this.TESTATA_COMPOSIZIONE.value;
    }

    setCODICE_COMPO_T(codice_compo: string) {
        this.CODICE_COMPO_T.next(codice_compo);
    }

    getCODICE_COMPO_T() {
        return this.CODICE_COMPO_T.value;
    }

    getCODICE_TO_PASS() {
        return this.CODICE_TO_PASS.value;
    }

    //* DEEPSPACE
    setDEEP_TYPE(deeptype: string) {
        this.DEEP_TYPE.next(deeptype);
    }

    getDEEP_TYPE() {
        return this.DEEP_TYPE.value;
    }

    notifyObservable$ = this.notify.asObservable();

    public notifyOther(data: any) {
        if (data) {
            this.notify.next(data);
        }
    }

    setCOD_PADRE(cod_padre: number) {
        console.log('set codice padre', cod_padre);
        this.CODICE_PADRE.next(cod_padre);
    }

    getCOD_PADRE() {
        //console.log('common get padre', this.CODICE_PADRE.value)
        return this.CODICE_PADRE.value;
    }

    setCONTROLLER_ON(status: boolean) {
        this.STATUS.next(status);
    }

    getSTATUS() {
        return this.STATUS.value;
    }

    setLANDING_ZONE_ON(status: boolean) {
        //console.log('set landing zone on : ', status)
        this.STATUS.next(status);
    }

    getLANDING_ZONE_ON() {
        return this.STATUS.value;
    }

    setMODELLO(cod_mod: string) {
        this.MODELLO.next(cod_mod);
    }

    getMODELLO() {
        return this.MODELLO.value;
    }

    setVARIANTE(codvar: string) {
        this.VARIANTE.next(codvar);
    }

    getVARIANTE() {
        return this.VARIANTE.value;
    }

    setALIAS(alias: string) {
        this.ALIAS.next(alias);
    }

    getALIAS() {
        return this.ALIAS.value;
    }

    setINITVAR(initvar: string) {
        this.INITVAR.next(initvar);
    }

    getINITVAR() {
        return this.INITVAR.value;
    }

    setARTICOLO(articolo: string) {
        this.ARTICOLO.next(articolo);
    }

    getARTICOLO() {
        return this.ARTICOLO.value;
    }
}
