/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { ImportaService } from 'src/app/servizi/importa.service';
import { EsportazioneComponent } from '../esportazione/esportazione.component';
import { ImportaFileComponent } from '../importa-file/importa-file.component';
import { SceltaEsportazioneComponent } from '../scelta-esportazione/scelta-esportazione.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-scelta-importazione',
    templateUrl: './scelta-importazione.component.html',
    styleUrls: ['./scelta-importazione.component.scss'],
})
export class SceltaImportazioneComponent implements OnInit {
    modalDataResponse: any;
    flag = false;
    showImport: boolean = false;
    showExport: boolean = false;

    constructor(
        public translate: TranslateService,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private importazioneService: ImportaService
    ) {}

    ngOnInit() {}

    async close() {
        if (this.flag === true) {
            this.flag = false;
        } else {
            await this.modalCtrl.dismiss();
        }
    }

    async uploadFile() {
        const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: 'Attenzione',
            subHeader: 'Modo non ancora attivo',
            buttons: [
                {
                    text: 'Close',
                    role: 'close',
                },
            ],
        });
        await alert.present();
    }

    async upload(tipoImportazione: string) {
        console.log('importa file in ', tipoImportazione);
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: ImportaFileComponent,
            componentProps: {
                tipoImportazione: tipoImportazione,
            },
        });
        await modal.present();
        modal.onDidDismiss().then(() => {
            this.ngOnInit();
        });
    }

    async esporta() {
        const modal = await this.modalCtrl.create({
            component: SceltaEsportazioneComponent,
        });
        await modal.present();
        modal.onDidDismiss().then(() => {
            this.ngOnInit();
        });
    }

    async importa() {
        this.flag = true;
    }
}
