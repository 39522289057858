import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { GeneralHeaderComponent } from '../general-header/general-header.component';
import { RouterModule } from '@angular/router';
import { SearchPipe } from 'src/app/pipe/search.pipe';

@NgModule({
    declarations: [SearchComponent, GeneralHeaderComponent, SearchPipe],
    imports: [CommonModule, IonicModule, FormsModule, RouterModule],
    exports: [SearchComponent, GeneralHeaderComponent, SearchPipe],
})
export class SharedComponentsModule {}
