import { Component, OnInit } from '@angular/core';
import {
    AlertController,
    ModalController,
    ViewWillEnter,
} from '@ionic/angular';
import { ModelliService } from 'src/app/servizi/modelli.service';
import { artNeutriStruct } from 'src/app/strutture/deepspace/articoli.strutture';

@Component({
    selector: 'app-ricerca-articoli-modelli',
    templateUrl: './ricerca-articoli-modelli.component.html',
    styleUrls: ['./ricerca-articoli-modelli.component.scss'],
})
export class RicercaArticoliModelliComponent implements OnInit, ViewWillEnter {
    artSearch: string = '';
    articoli: artNeutriStruct[] = [];

    constructor(
        private ms: ModelliService,
        private alertCtrl: AlertController,
        private modalCtrl: ModalController
    ) {}

    ionViewWillEnter(): void {
        this.getArtNeutri();
    }

    ngOnInit() {}

    async getArtNeutri() {
        if (this.artSearch === '') {
        } else {
            console.log('this.artsearch', this.artSearch);
            this.articoli = await this.ms.getArtNeutriSearch(this.artSearch);
        }
    }

    async selectArticolo(a: artNeutriStruct) {
        this.modalCtrl.dismiss(a.COD);
    }
}
