<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>{{this.translate.instant('258')}} SANTALUCIA</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>
    <ion-row>
      <ion-col size="8"></ion-col>
      <ion-col>
        <ion-button mode='ios' (click)="openAssociazione()">
          <ion-icon name="add-outline"></ion-icon>
          {{this.translate.instant('250')}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <ion-row>
      <ion-col size="4">
        Colonna Santalucia
      </ion-col>
      <ion-col size="6">
        {{this.translate.instant('099')}} Pricelist
      </ion-col>
      <ion-col size="1">

      </ion-col>
    </ion-row>
    <ion-row *ngFor="let associa of associazioni">
      <ion-col size="4">
        {{associa.COD_PREFERENZA}}
      </ion-col>
      <ion-col size="6">
        {{associa.VALORE_PREFERENZA}}
      </ion-col>
      <ion-col size="1">
        <ion-icon name="trash-outline" (click)="delAssociazione(associa.COD_PREFERENZA)"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>