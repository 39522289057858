/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { printLog } from 'src/app/lib';
import { CommonFunctionService } from 'src/app/servizi/common-function.service';
import { ListiniService } from 'src/app/servizi/listini.service';
import { MacroService } from 'src/app/servizi/macro.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-simulatore-macro',
    templateUrl: './simulatore-macro.component.html',
    styleUrls: ['./simulatore-macro.component.scss'],
})
export class SimulatoreMacroComponent implements OnInit {
    cod_macro;
    listini: listiniStruct[] = [];
    listinoSelected: string;
    simulaResult = false;
    results;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private listiniService: ListiniService,
        private macroService: MacroService,
        private common: CommonFunctionService
    ) {}

    async ngOnInit() {
        this.listini = await this.listiniService.getListini();
    }

    // async getResultSimulazione(){
    //   //todo 0 : mi faccio arrivare tutti i listini del catalogo √
    //   //todo 1 : prendo il listino del select √
    //   //todo 2 : mi faccio arrivare il json della simulazione
    //   const response = await this.macroService.simulatore(this.cod_macro, this.listinoSelected);
    //   // console.log('response result simulatore', response);
    //   this.results = response;
    //   this.simulaResult = true;
    // }

    async close() {
        const closeModal = 'Modal Closed';
        await this.modalCtr.dismiss(closeModal);
    }

    async getListinoSelect(e) {
        this.listinoSelected = e.detail.value;
        // console.log('listino selezionato per simulazione', this.listinoSelected);
        // console.log('macro selezionata per simulazione', this.cod_macro);
        const responseSimulazione = await this.common.simulaMacro(
            this.cod_macro,
            this.listinoSelected
        );
        // const responseSimulazione = await this.macroService.simulatore(this.cod_macro, this.listinoSelected);
        // console.log('risposta simula', responseSimulazione);
        this.results = responseSimulazione;
        // console.log('results', this.results);
        this.simulaResult = true;
    }
}
