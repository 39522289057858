<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title> Modifica modello {{modello.CODMOD}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <form [formGroup]="fg">
        <ion-item>
            <ion-input label="des" labelPlacement="stacked" formControlName="DES" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="data1" labelPlacement="stacked" formControlName="DATA1" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="data2" labelPlacement="stacked" formControlName="DATA2" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="data3" labelPlacement="stacked" formControlName="DATA3" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="data4" labelPlacement="stacked" formControlName="DATA4" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="data5" labelPlacement="stacked" formControlName="DATA5" (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input label="initvar" labelPlacement="stacked" formControlName="INITVAR"
                (focusout)="edit()"></ion-input>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">Abilita</ion-label>
            <ion-toggle labelPlacement="stacked" aria-label="Abilita" formControlName="ABILITA"
                (ionChange)="onToggleChange($event)" [checked]="modello.ABILITA === '1'"></ion-toggle>
        </ion-item>
    </form>

</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button color="primary" mode="ios" (click)="modalCtrl.dismiss()">Salva</ion-button>
    </ion-toolbar>
</ion-footer>