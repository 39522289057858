/* eslint-disable */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalVariableService } from './global-variable.service';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { CommonFunctionService } from './common-function.service';
import { articoliStruct } from '../strutture/articoli.strutture';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export interface PrezzoArticolo {
    COD: string;
    COMB: string;
    DISTINTA: string;
    LISTINO: string;
    PREZZO: number;
    VALORE: number;
}

export interface RispostaGetLingueArticolo {
    0: {
        CAMPI_LINGUA: string[];
    };
    1: {
        QTA_LINGUA: number;
    };
    2: {
        DESCRIZIONI: Record<string, Record<string, string>>[];
    };
}

@Injectable({
    providedIn: 'root',
})
export class ArticoliService {
    header: string = '';
    message: string = '';

    catalogoAttivo = null;

    private destroy = new Subject();

    constructor(
        private commons: CommonFunctionService,
        private globalVars: GlobalVariableService,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {}

    async nuovoArticolo(articolo: string) {
        //if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseNuovoArticolo = await this.commons.nuovoArticolo(
            articolo
        );
        console.log('response nuovo articolo', responseNuovoArticolo);
        const risp = JSON.stringify(responseNuovoArticolo)
            .split(':')
            .pop()
            .replace('}', '')
            .slice(1, -1);
        if (risp === '200') {
            this.header = 'Avviso';
            this.message = 'Articolo creato correttamente';
        } else {
            this.header = 'Errore';
            this.message = this.translate.instant(risp);
        }
        const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: this.header,
            message: this.message,
            buttons: ['Ok'],
        });
        await alert.present();
        return responseNuovoArticolo;
        //}
    }

    async getDimensioniArticolo(codiceArticolo: any) {
        // if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseDimensioniArticolo =
            await this.commons.getDimensioniArticolo(codiceArticolo);
        // console.log('responseDimensioniArticolo', responseDimensioniArticolo);

        const articoloStruct = new articoliStruct();

        // valori che si possono modificare
        if (responseDimensioniArticolo[0]) {
            responseDimensioniArticolo[0].forEach((value) => {
                articoloStruct.setIsValueEditable(value, true);
            });
        }

        // valori articolo
        if (responseDimensioniArticolo[1]) {
            responseDimensioniArticolo[1].forEach((element) => {
                Object.keys(element).forEach((propName) => {
                    articoloStruct.setValue(propName, element[propName]);
                });
            });
        }
        return articoloStruct;
        //}
    }

    async getLingueArticolo(
        codiceArticolo: string,
        codiceCatalogoStandalone: string = null
    ) {
        const responseLingueArticolo = await this.commons.getLingueArticolo(
            codiceArticolo,
            codiceCatalogoStandalone
        );
        // console.log('responseLingueArticolo', responseLingueArticolo);

        return responseLingueArticolo;
    }

    async getLingueArtPerCompo(codiceArticolo: string) {
        const getlingue = await this.commons.getLingueArtPerCompo(
            codiceArticolo
        );
        return getlingue;
    }

    async getLingueIntestazione(
        codiceIntestazione: string,
        codiceCatalogoStandalone: string = null
    ) {
        // if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseLingueIntestazione =
            await this.commons.getLingueIntestazione(
                codiceIntestazione,
                codiceCatalogoStandalone
            );
        // console.log('responseLingueIntestazione', responseLingueIntestazione);

        return responseLingueIntestazione;
        // }
    }

    async getEreditarietaArticolo(codiceArticolo: string) {
        const responseEreditarietarticolo =
            await this.commons.getEreditarietaArticolo(codiceArticolo);

        return responseEreditarietarticolo;
    }

    async getStoriaArticolo(codiceArticolo: string) {
        //if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseStoriarticolo = await this.commons.getStoriaArticolo(
            codiceArticolo
        );
        // console.log('responseStoriarticolo', responseStoriarticolo);

        return responseStoriarticolo;
        //}
    }

    async getPrezziArticolo(codiceArticolo: any, codiceListino: string) {
        const responseGetPrezziArticolo = await this.commons.getPrezziArticolo(
            codiceArticolo,
            codiceListino
        );
        console.log('responseGetPrezziArticolo', responseGetPrezziArticolo);

        return responseGetPrezziArticolo;
    }

    async setDimensioneArticolo(
        codiceArticolo: string,
        campo: string,
        value: string
    ) {
        //if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseSetDimensioneArticolo =
            await this.commons.setDimensioneArticolo(
                codiceArticolo,
                campo,
                value
            );
        // console.log('responseSetDimensioneArticolo', responseSetDimensioneArticolo);

        return responseSetDimensioneArticolo;
        //}
    }

    async setEreditarietaArticolo(
        codiceArticolo: string,
        campo: string,
        value: string
    ) {
        //if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseSetDimensioneArticolo =
            await this.commons.setEreditarietaArticolo(
                codiceArticolo,
                campo,
                value
            );
        // console.log('responseSetDimensioneArticolo', responseSetDimensioneArticolo);

        return responseSetDimensioneArticolo;
        //}
    }

    async setLinguaArticolo(
        codiceArticolo: string,
        codiceDescrizione: string,
        valoreDescrizione: string,
        codiceCatalogoStandalone: string = null,
        uuidStandalone: string = null
    ) {
        const responseSetLingueArticolo = await this.commons.setLinguaArticolo(
            codiceArticolo,
            codiceDescrizione,
            valoreDescrizione,
            codiceCatalogoStandalone,
            uuidStandalone
        );
        console.log('responseSetLingueArticolo', responseSetLingueArticolo);

        return responseSetLingueArticolo;
    }

    async copiaArticolo(codiceArticolo: string, codEnd: string) {
        // if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseDuplicaArticolo = await this.commons.copiaArticolo(
            codiceArticolo,
            codEnd
        );
        // console.log('responseDuplicaArticolo', responseDuplicaArticolo);
        console.log('copia articolo!!!!');
        return responseDuplicaArticolo;
        // }
    }

    async setPrezzoArticolo(
        codiceArticolo: string,
        codiceListino: string,
        colonna: string,
        prezzo: any,
        listino: string,
        distinta: string
    ) {
        // if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
        const responseSetPrezzoArticolo = await this.commons.setPrezzoArticolo(
            codiceArticolo,
            codiceListino,
            colonna,
            prezzo,
            listino,
            distinta
        );
        // console.log('responseSetPrezzoArticolo', responseSetPrezzoArticolo);

        return responseSetPrezzoArticolo;
        //}
    }

    async getArtNeutriPlus(codart: string) {
        const get = await this.commons.getArtNeutriPlus(codart);
        return get;
    }

    async setArtNeutriPlus(codart: string, field: string, value: any) {
        await this.setArtNeutriPlus(codart, field, value);
    }
}
