<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title *ngIf="!edit">{{this.translate.instant('261')}}</ion-title>
    <ion-title *ngIf="edit">{{this.translate.instant('262')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="formNuovoArticolo" (ngSubmit)="setPrezzoArticolo()">
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('099')}}</ion-label>
          <ion-input labelPlacement="stacked" type="text" formControlName="colonna"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('102')}}</ion-label>
          <ion-input labelPlacement="stacked" type="text" formControlName="prezzo"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('101')}}</ion-label>
          <ion-textarea formControlName="listino"></ion-textarea>
        </ion-item>
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('114')}}</ion-label>
          <ion-textarea formControlName="distinta"></ion-textarea>
        </ion-item>
      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!formNuovoArticolo.valid" (click)="setPrezzoArticolo()"
      color="primary">{{this.translate.instant('031')}}</ion-button>
  </ion-toolbar>
</ion-footer>