<ion-header mode="ios" class="ion-no-border">
    <ion-toolbar>
        <ion-title>Aggiungi valore</ion-title>
        <ion-button mode='ios' slot="end" (click)="modalCtrl.dismiss()" fill="clear">
            <ion-icon slot="icon-only" name="close-outline" color="primary"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form [formGroup]="fg">
        <ion-item>
            <ion-select label="Scegli il campo" labelPlacement="stacked" interface="popover" formControlName="field">
                <ion-select-option *ngFor="let valore of values" [value]="valore">
                    {{valore}}
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-input label="Valore" labelPlacement="stacked" formControlName="value"></ion-input>
        </ion-item>
    </form>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
    <ion-toolbar>
        <ion-button mode="ios" (click)=" setPlus()">Salva</ion-button>
    </ion-toolbar>
</ion-footer>