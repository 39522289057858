import { Component, OnInit } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { righeListiniStruct } from 'src/app/strutture/listini calcolati distinta/righeListini.strutture';
import { testateListiniStruct } from 'src/app/strutture/listini calcolati distinta/testateListini.strutture';

@Component({
    selector: 'app-viewer-distinte',
    templateUrl: './viewer-distinte.component.html',
    styleUrls: ['./viewer-distinte.component.scss'],
})
export class ViewerDistinteComponent implements OnInit, ViewWillEnter {
    distinte: testateListiniStruct[] = [];
    righe: righeListiniStruct[] = [];

    viewRighe: boolean = false;

    constructor(
        public modalCtrl: ModalController,
        private ls: ListiniService
    ) {}
    ionViewWillEnter(): void {
        this.getT();
    }

    ngOnInit() {}

    async getT() {
        this.distinte = await this.ls.getTListinoDistinta();
    }
    async getR(codT: string) {
        this.righe = await this.ls.getRListinoDistinta(codT);
    }

    visualizzaRighe(codice_distinta: string) {
        this.getR(codice_distinta);
        this.viewRighe = true;
    }

    selezionaDistinta(codice_distinta: string) {
        console.log('ho selezionato la distinta', codice_distinta);
        this.modalCtrl.dismiss(codice_distinta);
    }
}
