import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ListiniService } from 'src/app/servizi/listini.service';
import { listiniStruct } from 'src/app/strutture/listini.strutture';
import { AleaControlloFileComponent } from '../alea-controllo-file/alea-controllo-file.component';

import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-alea-importazione',
    templateUrl: './alea-importazione.component.html',
    styleUrls: ['./alea-importazione.component.scss'],
})
export class AleaImportazioneComponent implements OnInit {
    fileToImport: File;
    showBtn: boolean;

    modalDataResponse: any;

    sceltaListino;
    listini: listiniStruct[] = [];
    listinoSelezionato: string;

    constructor(
        public translate: TranslateService,
        private modalCtr: ModalController,
        private listiniService: ListiniService
    ) {}

    async ngOnInit() {
        this.listini = await this.listiniService.getListini();
    }

    async close(message = 'Modal Closed') {
        await this.modalCtr.dismiss(message);
    }

    async importaFile($event: any) {
        this.fileToImport = $event.target.files[0];
        // console.log('file', this.fileToImport);
        this.showButton();
        this.sceltaListino = true;
    }

    async showButton() {
        if (this.fileToImport !== undefined) {
            console.log(this.listinoSelezionato);
            if (this.listinoSelezionato !== undefined) {
                console.log(this.listinoSelezionato);
                this.showBtn = true;
            }
        } else {
            this.showBtn = true;
        }
    }

    async selezionaListino($event: any) {
        this.listinoSelezionato = $event.detail.value.COD_LISTINO;
        // console.log('listino selezionato ', this.listinoSelezionato);
        this.showButton();
    }

    async prosegui() {
        const modal = await this.modalCtr.create({
            component: AleaControlloFileComponent,
            componentProps: {
                file: this.fileToImport,
                codListino: this.listinoSelezionato,
            },
        });

        modal.onDidDismiss().then((modalDataResponse) => {
            if (modalDataResponse !== null) {
                this.modalDataResponse = modalDataResponse.data;
                console.log('Modal Sent Data : ' + modalDataResponse.data);
            }
        });

        return await modal.present();
    }
}
