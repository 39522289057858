import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { printLog } from '../lib';
// import { printLog } from '../lib';
import { traduzioniStruct } from '../strutture/traduzioni.strutture';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
    providedIn: 'root',
})
export class TraduzioniService {
    catalogoAttivo = null;
    private destroy = new Subject();

    constructor(
        private globalVar: GlobalVariableService,
        private commons: CommonFunctionService
    ) {}

    async sendTradRequest(
        payloadTrad: traduzioniStruct,
        mailTo: string,
        tipoTrad: string
    ) {
        const responseTradRequest = await this.commons.traduzioni(
            payloadTrad,
            mailTo,
            tipoTrad
        );
        console.log('response richiesta traduzioni', responseTradRequest);
    }
}
