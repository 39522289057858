/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { importaStruct } from '../strutture/importa.strutture';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
    providedIn: 'root',
})
export class ImportaService {
    catalogoAttivo = null;
    private destroy = new Subject();

    errorToDisplay: string;

    constructor(
        private commons: CommonFunctionService,
        private globalVar: GlobalVariableService,
        private alertCtrl: AlertController
    ) {
        globalVar.CATALOGO_ATTIVO$.pipe(takeUntil(this.destroy)).subscribe(
            async (catalogoAttivo) => {
                if (catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
                    this.catalogoAttivo = catalogoAttivo;
                }
            }
        );
    }

    //* importazioni

    async importaArtPreflight(file: File) {
        const responseImportaArticoli =
            await this.commons.importArticoliPreflight(file);
        console.log(responseImportaArticoli['MESSAGGI'].length);
        if (responseImportaArticoli['MESSAGGI'].length === 0) {
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImportaArticoli['FILE_IMPORT']
            );
        } else {
            this.globalVar.setERRORE_IMPORTAZIONE(
                responseImportaArticoli['MESSAGGI']
            );
            responseImportaArticoli['MESSAGGI'].forEach((element) => {
                console.log(element['FLAG_IMPORT']);
                console.log(element['MESSAGGIO']);
                if (element['FLAG_IMPORT'] !== 'C') {
                    // console.log('non ci sono errori critici, posso proseguire');
                    this.globalVar.setFILE_NAME_IMPORTAZIONE(
                        responseImportaArticoli['FILE_IMPORT']
                    );
                } else {
                    // console.log('ci sono errori critici, blocco tutto');
                }
            });
        }
    }

    async importaPrezziPreflight(file: File, cod_listino: string) {
        if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
            const responseImportaPrezziPreflight =
                await this.commons.importaPrezziPreflight(file, cod_listino);
            // console.log('response importazione prezzi', responseImportaPrezziPreflight);
            console.log(responseImportaPrezziPreflight['MESSAGGI'].length);
            if (responseImportaPrezziPreflight['MESSAGGI'].length === 0) {
                // console.log('non ci sono errori, proseguo con l importazione');
                this.globalVar.setFILE_NAME_IMPORTAZIONE(
                    responseImportaPrezziPreflight['FILE_IMPORT']
                );
                // console.log('FILENAME set', responseImportaPrezziPreflight['FILENAME']);
            } else {
                this.globalVar.setFILE_NAME_IMPORTAZIONE(
                    responseImportaPrezziPreflight['FILE_IMPORT']
                );
                // console.log('FILENAME set', responseImportaPrezziPreflight['FILENAME']);
                this.globalVar.setERRORE_IMPORTAZIONE(
                    responseImportaPrezziPreflight['MESSAGGI']
                );
                responseImportaPrezziPreflight['MESSAGGI'].forEach(
                    (element) => {
                        console.log(element['FLAG_IMPORT']);
                        console.log(element['MESSAGGIO']);
                        if (element['FLAG_IMPORT'] !== 'C') {
                            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                                responseImportaPrezziPreflight['FILE_IMPORT']
                            );
                        } else {
                            // console.log('ci sono errori critici, blocco tutto');
                        }
                    }
                );
            }
        }
    }

    async importaIntestazioniPreflight(file: File) {
        if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
            const responseImportaIntestazionePreflight =
                await this.commons.importaIntestazioniPreflight(file);
            // console.log('response importazione intestazioni', responseImportaIntestazionePreflight);
            console.log(
                responseImportaIntestazionePreflight['MESSAGGI'].length
            );
            if (responseImportaIntestazionePreflight['MESSAGGI'].length === 0) {
                // console.log('non ci sono errori, proseguo con l importazione');
                this.globalVar.setFILE_NAME_IMPORTAZIONE(
                    responseImportaIntestazionePreflight['FILE_IMPORT']
                );
            } else {
                this.globalVar.setERRORE_IMPORTAZIONE(
                    responseImportaIntestazionePreflight['MESSAGGI']
                );
                responseImportaIntestazionePreflight['MESSAGGI'].forEach(
                    (element) => {
                        console.log(element['FLAG_IMPORT']);
                        console.log(element['MESSAGGIO']);
                        if (element['FLAG_IMPORT'] !== 'C') {
                            // console.log('non ci sono errori critici, posso proseguire');
                            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                                responseImportaIntestazionePreflight[
                                    'FILE_IMPORT'
                                ]
                            );
                        } else {
                            // console.log('ci sono errori critici, blocco tutto');
                        }
                    }
                );
            }
        }
    }

    async importaPadreFiglioPreflight(file: File) {
        const responseImportaPadreFiglioPreflight =
            await this.commons.importaPadreFiglioPreflight(file);
        console.log(responseImportaPadreFiglioPreflight);
        console.log(responseImportaPadreFiglioPreflight['MESSAGGI'].length);
        if (responseImportaPadreFiglioPreflight['MESSAGGI'].length === 0) {
            this.globalVar.setFILE_NAME_IMPORTAZIONE(
                responseImportaPadreFiglioPreflight['FILE_IMPORT']
            );
        } else {
            this.globalVar.setERRORE_IMPORTAZIONE(
                responseImportaPadreFiglioPreflight['MESSAGGI']
            );
            responseImportaPadreFiglioPreflight['MESSAGGI'].forEach(
                (element) => {
                    console.log(element['FLAG_IMPORT']);
                    console.log(element['MESSAGGIO']);
                    if (element['FLAG_IMPORT'] !== 'C') {
                        // console.log('non ci sono errori critici, posso proseguire');
                        this.globalVar.setFILE_NAME_IMPORTAZIONE(
                            responseImportaPadreFiglioPreflight['FILE_IMPORT']
                        );
                    } else {
                        // console.log('ci sono errori critici, blocco tutto');
                    }
                }
            );
        }
    }

    async importaComposizioniPreflight(file: File) {
        if (this.catalogoAttivo.UTENTE.ID_CATALOGO_ERRATA) {
            const resp = await this.commons.importaComposizioniPreflight(file);
            console.log('nome del file', resp['FILE_IMPORT']);
            console.log(resp['MESSAGGI'].length);
            if (resp['MESSAGGI'].length === 0) {
                // console.log('non ci sono errori, proseguo con l importazione');
                this.globalVar.setFILE_NAME_IMPORTAZIONE(resp['FILE_IMPORT']);
            } else {
                this.globalVar.setERRORE_IMPORTAZIONE(resp['MESSAGGI']);
                resp['MESSAGGI'].forEach((element) => {
                    console.log(element['FLAG_IMPORT']);
                    console.log(element['MESSAGGIO']);
                    if (element['FLAG_IMPORT'] !== 'C') {
                        // console.log('non ci sono errori critici, posso proseguire');
                        this.globalVar.setFILE_NAME_IMPORTAZIONE(
                            resp['FILE_IMPORT']
                        );
                    } else {
                        // console.log('ci sono errori critici, blocco tutto');
                    }
                });
            }
        }
    }

    //* esportazioni

    async esportaART(listino: string, modo: string) {
        const responseEsporta = await this.commons.esportaART(listino, modo);
        console.log(responseEsporta);
        return responseEsporta;
    }

    async esportaINT() {
        const responseEsportaINT = await this.commons.esportaINT();
        console.log('response esporta int', responseEsportaINT);
        const risp = JSON.stringify(responseEsportaINT)
            .split(':')
            .pop()
            .replace('}', '');
        if (risp === '"E409"') {
            console.log('Campo PAGE non alimentato, non posso proseguire');
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                message: 'Campo PAGE non alimentato, non posso proseguire',
                buttons: ['OK'],
            });
            await alert.present();
        } else {
            console.log('posso proseguire');
        }
    }

    async esportaCOMPO() {
        const resp = await this.commons.esportaCOMPO();
        console.log('response esporta int', resp);
        const risp = JSON.stringify(resp).split(':').pop().replace('}', '');
        if (risp === '"E409"') {
            console.log('Campo PAGE non alimentato, non posso proseguire');
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                message: 'Campo PAGE non alimentato, non posso proseguire',
                buttons: ['OK'],
            });
            await alert.present();
        } else {
            console.log('posso proseguire');
        }
    }

    async resetCampoPage(modo: string) {
        const responseResetCampoPage = await this.commons.resetCampoPage(modo);
        // console.log('response', responseResetCampoPage);
        const risp = JSON.stringify(responseResetCampoPage)
            .split(':')
            .pop()
            .replace('}', '');
        if (risp === '"200"') {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                message: 'Campo PAGE resettato',
                buttons: ['OK'],
            });
            await alert.present();
        }
    }

    async esportaWINNER(cod_listino: string) {
        const response = await this.commons.esportaWINNER(cod_listino);
        console.log('response esporta winner', response);
        const risp = JSON.stringify(response).split(':').pop().replace('}', '');
        if (risp === '"E409"') {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                message: 'Campo PAGE non alimentato, non posso proseguire',
                buttons: ['OK'],
            });
            await alert.present();
        }
    }
}
