import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search',
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], searchText: string, keys?: string[]): any[] {
        console.log('Items ricevuti:', items);
        console.log('Testo ricerca:', searchText);

        if (!items || !searchText) return items;

        searchText = searchText.toLowerCase();

        return items.filter((item) => {
            return Object.keys(item).some((key) => {
                if (keys && !keys.includes(key)) return false; // Se specifichi i campi da cercare, escludi gli altri
                const value = item[key];
                return (
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchText)
                );
            });
        });
    }
}
